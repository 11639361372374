import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Input,
  MenuItem,
  FormControlLabel,
  Switch,
  LinearProgress,
  Tooltip
} from '@material-ui/core';
import { Edit, Delete, ExpandMore, Add, CloudUpload, FormatBold, FormatItalic, FormatListBulleted } from '@material-ui/icons';
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { isDevelopment } from '../../../utils/environment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    '@media (max-width: 991px)': {
      padding: theme.spacing(2)
    }
  },
  courseCard: {
    marginBottom: theme.spacing(2),
    position: 'relative',
    background: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '0.625rem',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    transition: 'transform 0.2s ease',
    '&:hover': {
      transform: 'translateY(-4px)'
    }
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  },
  cardTitle: {
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    fontSize: '1.5rem',
    margin: 0
  },
  cardDescription: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.7)',
    marginTop: theme.spacing(1)
  },
  actionButtons: {
    display: 'flex',
    gap: '0.5rem'
  },
  iconButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'rgba(255, 0, 0, 0.1)'
    }
  },
  addButton: {
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.625rem',
    marginBottom: theme.spacing(3),
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    '&:hover': {
      opacity: 0.9
    }
  },
  dialog: {
    '& .MuiDialog-paper': {
      minWidth: '85vw',
      maxWidth: '90vw',
      maxHeight: '90vh',
      borderRadius: '0.625rem',
      '@media (max-width: 991px)': {
        minWidth: '95vw'
      }
    }
  },
  dialogTitle: {
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(2)
  },
  dialogContent: {
    padding: theme.spacing(2),
    overflowY: 'auto'
  },
  formField: {
    marginBottom: theme.spacing(2),
    '& .MuiInputLabel-root': {
      fontFamily: 'Roboto, sans-serif'
    },
    '& .MuiInputBase-root': {
      fontFamily: 'Roboto, sans-serif'
    }
  },
  chapterExpansion: {
    marginBottom: theme.spacing(2),
    borderRadius: '0.625rem',
    '&:before': {
      display: 'none'
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: '12px 0',
      width: '100%',
      '& .MuiTextField-root': {
        width: '100%'
      }
    }
  },
  chapterHeader: {
    background: 'rgba(0, 0, 0, 0.03)'
  },
  moduleSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700
  },
  uploadButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  uploadSuccess: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(2)
  },
  dialogActions: {
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  },
  actionButton: {
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    borderRadius: '0.625rem',
    padding: '0.5rem 1.5rem'
  },
  saveButton: {
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    '&:hover': {
      opacity: 0.9
    }
  },
  moduleContent: {
    width: '100%',
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2)
    }
  },
  exerciseSection: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: '0.625rem'
  },
  exerciseList: {
    marginTop: theme.spacing(1)
  },
  exerciseItem: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: 'white',
    borderRadius: '0.625rem'
  },
  optionList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  exerciseButton: {
    marginTop: theme.spacing(1)
  },
  correctAnswerSelect: {
    marginTop: theme.spacing(1)
  },
  whiteOutline: {
    borderColor: 'white'
  },
  dragHandle: {
    cursor: 'grab',
    color: 'white',
    marginRight: theme.spacing(1),
    '&:hover': {
      opacity: 0.8
    }
  },
  chapterList: {
    width: '100%',
    '& .MuiListItem-root': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '16px',
      '&:last-child': {
        borderBottom: 'none'
      }
    }
  },
  downloadButton: {
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    '&:hover': {
      opacity: 0.9
    }
  },
  contentDescription: {
    color: 'rgba(0, 0, 0, 0.7)',
    fontSize: '0.875rem',
    marginTop: '4px'
  },
  fileName: {
    color: '#666',
    fontSize: '0.875rem',
    marginTop: '4px',
    fontStyle: 'italic'
  },
  divider: {
    margin: '40px 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.1)',
    width: '100%'
  },
  sectionTitle: {
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    fontSize: '2rem',
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main
  },
  devToggle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  progressContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  progressBar: {
    borderRadius: '10px',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#f00',
      backgroundImage: 'linear-gradient(180deg, #f00 0%, #900 100%)'
    }
  },
  progressText: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    fontFamily: 'Roboto, sans-serif',
    color: theme.palette.text.secondary
  },
  uploadTitle: {
    marginBottom: '10px'
  },
  formattingToolbar: {
    display: 'flex',
    gap: '8px',
    marginBottom: '8px',
    '& button': {
      padding: '6px',
    },
  },
  formattingButton: {
    minWidth: '32px',
    height: '32px',
    '&.active': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
  activeFormatting: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}));

const insertFormatting = (text, selectionStart, selectionEnd, format) => {
  const selectedText = text.substring(selectionStart, selectionEnd);
  let newText = text;
  let newCursorPosition = selectionEnd;

  switch (format) {
    case 'bold':
      newText = text.substring(0, selectionStart) + `**${selectedText}**` + text.substring(selectionEnd);
      newCursorPosition += 4;
      break;
    case 'italic':
      newText = text.substring(0, selectionStart) + `*${selectedText}*` + text.substring(selectionEnd);
      newCursorPosition += 2;
      break;
    case 'bullet':
      // Add a newline before the bullet point if we're not at the start of a line
      const needsNewline = selectionStart > 0 && text[selectionStart - 1] !== '\n';
      const prefix = needsNewline ? '\n' : '';
      
      // Split selected text into lines and format each as a bullet point
      const lines = selectedText.split('\n');
      const bulletedLines = lines
        .map(line => `• ${line.trim()}`)
        .join('\n');
      
      newText = text.substring(0, selectionStart) + prefix + bulletedLines + text.substring(selectionEnd);
      newCursorPosition = selectionStart + prefix.length + bulletedLines.length;
      break;
    default:
      break;
  }

  return { newText, newCursorPosition };
};

// Update the renderFormattedText function
const renderFormattedText = (text) => {
  if (!text) return null;
  
  // Split the text into paragraphs
  const paragraphs = text.split('\n').filter(Boolean);
  
  const formatText = (text) => {
    // Process the text in segments to preserve formatting
    let segments = [];
    let currentIndex = 0;
    
    // Find all bold and italic patterns
    const patterns = [
      { regex: /\*\*(.*?)\*\*/g, style: { fontWeight: 'bold' } },
      { regex: /\*(.*?)\*/g, style: { fontStyle: 'italic' } }
    ];

    while (currentIndex < text.length) {
      let earliestMatch = null;
      let selectedPattern = null;

      // Find the earliest match among all patterns
      for (const pattern of patterns) {
        pattern.regex.lastIndex = currentIndex;
        const match = pattern.regex.exec(text);
        if (match && (!earliestMatch || match.index < earliestMatch.index)) {
          earliestMatch = match;
          selectedPattern = pattern;
        }
      }

      if (earliestMatch) {
        // Add text before the match
        if (earliestMatch.index > currentIndex) {
          segments.push(
            <span key={segments.length}>
              {text.substring(currentIndex, earliestMatch.index)}
            </span>
          );
        }
        
        // Add the formatted text
        segments.push(
          <span key={segments.length} style={selectedPattern.style}>
            {earliestMatch[1]}
          </span>
        );
        
        currentIndex = earliestMatch.index + earliestMatch[0].length;
      } else {
        // Add remaining text
        segments.push(
          <span key={segments.length}>
            {text.substring(currentIndex)}
          </span>
        );
        break;
      }
    }

    return segments;
  };

  return paragraphs.map((paragraph, paragraphIndex) => {
    // Check if this is a bullet point
    if (paragraph.trim().startsWith('•')) {
      return (
        <li 
          key={paragraphIndex} 
          style={{ 
            marginBottom: '8px', 
            textAlign: 'left',
            listStyle: 'none',
            display: 'flex',
            alignItems: 'flex-start'
          }}
        >
          <span style={{ marginRight: '8px' }}>•</span>
          <span>{formatText(paragraph.slice(1).trim())}</span>
        </li>
      );
    }

    // Regular paragraph with bold and italic formatting
    return (
      <p 
        key={paragraphIndex} 
        style={{ 
          marginBottom: '16px',
          textAlign: 'left',
          margin: '0 0 16px 0'
        }}
      >
        {formatText(paragraph)}
      </p>
    );
  });
};

export const CoursesPanel = () => {
  const classes = useStyles();
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingCourse, setEditingCourse] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    imageUrl: '',
    isDev: false,
    chapters: [{
      title: '',
      imageUrl: '',
      description: '',
      videoUrl: '',
      chapterHeader: '',
      modules: []
    }],
    downloadableContent: []
  });
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const isDevEnvironment = isDevelopment();

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const db = getFirestore();
      const coursesCollection = collection(db, 'courses');
      const coursesSnapshot = await getDocs(coursesCollection);
      const coursesList = coursesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCourses(coursesList);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const handleAddChapter = () => {
    setFormData(prev => ({
      ...prev,
      chapters: [...prev.chapters, {
        title: '',
        imageUrl: '',
        description: '',
        videoUrl: '',
        chapterHeader: '',
        modules: []
      }],
      downloadableContent: [...prev.downloadableContent, {
        title: '',
        description: '',
        fileUrl: ''
      }]
    }));
  };

  const handleChapterChange = (index, field, value) => {
    setFormData(prevFormData => {
      const updatedChapters = [...prevFormData.chapters];
      if (!updatedChapters[index]) {
        updatedChapters[index] = {
          title: '',
          imageUrl: '',
          description: '',
          videoUrl: '',
          chapterHeader: '',
          modules: []
        };
      }
      updatedChapters[index] = {
        ...updatedChapters[index],
        [field]: value
      };
      return {
        ...prevFormData,
        chapters: updatedChapters
      };
    });
  };

  const handleModuleChange = (chapterIndex, moduleIndex, field, value) => {
    const newChapters = [...formData.chapters];
    
    // Ensure the module exists
    if (!newChapters[chapterIndex].modules[moduleIndex]) {
      newChapters[chapterIndex].modules[moduleIndex] = {
        title: '',
        content: '',
        videoUrl: '',
        exercises: [],
        discussion: ''
      };
    }

    if (field === 'exercises') {
      newChapters[chapterIndex].modules[moduleIndex].exercises = value;
    } else {
      newChapters[chapterIndex].modules[moduleIndex][field] = value;
    }

    setFormData(prev => ({
      ...prev,
      chapters: newChapters
    }));
  };

  // First, add a helper function for showing error messages
  const showErrorMessage = (userMessage, error) => {
    // Show user-friendly message in a popup
    window.alert(`Upload Error: ${userMessage}`);
    // Log technical details to console
    console.error('Technical error details:', error);
  };

  const handleChapterVideoUpload = async (chapterIndex, event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      // Validate file type
      const validVideoTypes = ['video/mp4', 'video/webm', 'video/ogg'];
      if (!validVideoTypes.includes(file.type)) {
        showErrorMessage(
          'Please upload a valid video file (MP4, WebM, or OGG format).',
          `Invalid file type: ${file.type}`
        );
        return;
      }

      // Update size limit to 30GB
      const maxSize = 30 * 1024 * 1024 * 1024; // 30GB in bytes
      if (file.size > maxSize) {
        const fileSizeGB = (file.size / (1024 * 1024 * 1024)).toFixed(2);
        showErrorMessage(
          `Video file is too large (${fileSizeGB}GB). Maximum size is 30GB.`,
          `File size ${file.size} exceeds limit of ${maxSize}`
        );
        return;
      }

      setUploading(true);
      setUploadProgress(0);
      const storage = getStorage();
      const videoRef = ref(storage, `course-videos/${formData.title}/chapter-${chapterIndex + 1}/chapter-video/${file.name}`);
      
      // Create upload task with uploadBytesResumable
      const uploadTask = uploadBytesResumable(videoRef, file);

      // Monitor upload progress
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(Math.round(progress));
        },
        (error) => {
          setUploading(false);
          setUploadProgress(0);
          showErrorMessage('Upload failed', error);
        },
        async () => {
          try {
            const videoUrl = await getDownloadURL(videoRef);
            handleChapterChange(chapterIndex, 'videoUrl', videoUrl);
            setUploading(false);
            setUploadProgress(0);
          } catch (error) {
            showErrorMessage('Error getting download URL', error);
            setUploading(false);
            setUploadProgress(0);
          }
        }
      );
    } catch (error) {
      setUploading(false);
      setUploadProgress(0);
      // Handle specific error types
      if (error.code === 'storage/unauthorized') {
        showErrorMessage(
          'You do not have permission to upload videos. Please check your authentication.',
          error
        );
      } else if (error.code === 'storage/canceled') {
        showErrorMessage(
          'Video upload was cancelled.',
          error
        );
      } else if (error.code === 'storage/network-error') {
        showErrorMessage(
          'Network error occurred during upload. Please check your internet connection and try again.',
          error
        );
      } else {
        showErrorMessage(
          'An error occurred while uploading the video. Please try again.',
          error
        );
      }
    }
  };

  const handleModuleVideoUpload = async (chapterIndex, moduleIndex, event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      // Validate file type
      const validVideoTypes = ['video/mp4', 'video/webm', 'video/ogg'];
      if (!validVideoTypes.includes(file.type)) {
        showErrorMessage(
          'Please upload a valid video file (MP4, WebM, or OGG format).',
          `Invalid file type: ${file.type}`
        );
        return;
      }

      // Update size limit to 30GB
      const maxSize = 30 * 1024 * 1024 * 1024; // 30GB in bytes
      if (file.size > maxSize) {
        const fileSizeGB = (file.size / (1024 * 1024 * 1024)).toFixed(2);
        showErrorMessage(
          `Video file is too large (${fileSizeGB}GB). Maximum size is 30GB.`,
          `File size ${file.size} exceeds limit of ${maxSize}`
        );
        return;
      }

      setUploading(true);
      setUploadProgress(0);
      const storage = getStorage();
      const videoRef = ref(storage, `course-videos/${formData.title}/chapter-${chapterIndex + 1}/module-${moduleIndex + 1}/${file.name}`);
      
      // Create upload task with uploadBytesResumable
      const uploadTask = uploadBytesResumable(videoRef, file);

      // Monitor upload progress
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(Math.round(progress));
        },
        (error) => {
          setUploading(false);
          setUploadProgress(0);
          showErrorMessage('Upload failed', error);
        },
        async () => {
          try {
            const videoUrl = await getDownloadURL(videoRef);
            handleModuleChange(chapterIndex, moduleIndex, 'videoUrl', videoUrl);
            setUploading(false);
            setUploadProgress(0);
          } catch (error) {
            showErrorMessage('Error getting download URL', error);
            setUploading(false);
            setUploadProgress(0);
          }
        }
      );
    } catch (error) {
      setUploading(false);
      setUploadProgress(0);
      // Handle specific error types
      if (error.code === 'storage/unauthorized') {
        showErrorMessage(
          'You do not have permission to upload videos. Please check your authentication.',
          error
        );
      } else if (error.code === 'storage/canceled') {
        showErrorMessage(
          'Video upload was cancelled.',
          error
        );
      } else if (error.code === 'storage/network-error') {
        showErrorMessage(
          'Network error occurred during upload. Please check your internet connection and try again.',
          error
        );
      } else {
        showErrorMessage(
          'An error occurred while uploading the video. Please try again.',
          error
        );
      }
    }
  };

  const handleModuleThumbnailUpload = async (chapterIndex, moduleIndex, event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setUploading(true);
      setUploadProgress(0);
      const storage = getStorage();
      const thumbnailRef = ref(storage, `course-thumbnails/${formData.title}/chapter-${chapterIndex + 1}/module-${moduleIndex + 1}/${file.name}`);
      
      // Create upload task with uploadBytesResumable
      const uploadTask = uploadBytesResumable(thumbnailRef, file);

      // Monitor upload progress
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(Math.round(progress));
        },
        (error) => {
          setUploading(false);
          setUploadProgress(0);
          showErrorMessage('Upload failed', error);
        },
        async () => {
          try {
            const imageUrl = await getDownloadURL(thumbnailRef);
            handleModuleChange(chapterIndex, moduleIndex, 'imageUrl', imageUrl);
            setUploading(false);
            setUploadProgress(0);
          } catch (error) {
            showErrorMessage('Error getting download URL', error);
            setUploading(false);
            setUploadProgress(0);
          }
        }
      );
    } catch (error) {
      setUploading(false);
      setUploadProgress(0);
      showErrorMessage('Error uploading thumbnail', error);
    }
  };

  const handleDeleteModule = (chapterIndex, moduleIndex) => {
    const newChapters = [...formData.chapters];
    newChapters[chapterIndex].modules.splice(moduleIndex, 1);
    setFormData(prev => ({
      ...prev,
      chapters: newChapters
    }));
  };

  const handleAddExercise = (chapterIndex, moduleIndex) => {
    const newChapters = [...formData.chapters];
    const module = newChapters[chapterIndex].modules[moduleIndex];
    if (!module.exercises) {
      module.exercises = [];
    }
    module.exercises.push({
      question: '',
      options: ['', '', '', ''],
      correctAnswer: 0
    });
    setFormData(prev => ({
      ...prev,
      chapters: newChapters
    }));
  };

  const handleExerciseChange = (chapterIndex, moduleIndex, exerciseIndex, field, value) => {
    const newChapters = [...formData.chapters];
    const exercise = newChapters[chapterIndex].modules[moduleIndex].exercises[exerciseIndex];
    if (field === 'options') {
      exercise.options[value.index] = value.text;
    } else {
      exercise[field] = value;
    }
    setFormData(prev => ({
      ...prev,
      chapters: newChapters
    }));
  };

  const handleSave = async () => {
    try {
      const db = getFirestore();
      if (editingCourse) {
        await updateDoc(doc(db, 'courses', editingCourse.id), formData);
      } else {
        await addDoc(collection(db, 'courses'), formData);
      }
      setOpen(false);
      fetchCourses();
    } catch (error) {
      console.error('Error saving course:', error);
    }
  };

  const handleDeleteChapter = (chapterIndex) => {
    if (window.confirm('Are you sure you want to delete this chapter?')) {
      const newChapters = [...formData.chapters];
      newChapters.splice(chapterIndex, 1);
      setFormData(prev => ({
        ...prev,
        chapters: newChapters
      }));
    }
  };

  const handleVideoUrlChange = (chapterIndex, moduleIndex, value) => {
    if (moduleIndex !== undefined) {
      handleModuleChange(chapterIndex, moduleIndex, 'videoUrl', value);
    } else {
      handleChapterChange(chapterIndex, 'videoUrl', value);
    }
  };

  const handleChapterImageUpload = async (chapterIndex, event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setUploading(true);
      const storage = getStorage();
      const imageRef = ref(storage, `course-images/${formData.title}/chapter-${chapterIndex + 1}/${file.name}`);
      await uploadBytes(imageRef, file);
      const imageUrl = await getDownloadURL(imageRef);

      handleChapterChange(chapterIndex, 'imageUrl', imageUrl);
      setUploading(false);
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploading(false);
    }
  };

  const handleImageUrlChange = (chapterIndex, value) => {
    handleChapterChange(chapterIndex, 'imageUrl', value);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    setFormData(prevFormData => {
      const newChapters = Array.from(prevFormData.chapters);
      const [removed] = newChapters.splice(sourceIndex, 1);
      newChapters.splice(destinationIndex, 0, removed);

      return {
        ...prevFormData,
        chapters: newChapters
      };
    });
  };

  // Filter courses based on isDev flag
  const productionCourses = courses.filter(course => !course.isDev);
  const developmentCourses = courses.filter(course => course.isDev);

  const CoursesList = ({ courses, title }) => (
    <>
      {title && <Typography className={classes.sectionTitle}>{title}</Typography>}
      <Grid container spacing={3}>
        {courses.map((course) => (
          <Grid item xs={12} key={course.id}>
            <Card className={classes.courseCard}>
              <div className={classes.cardHeader}>
                <Typography variant="h5" className={classes.cardTitle}>
                  {course.title}
                </Typography>
                <div className={classes.actionButtons}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      setEditingCourse(course);
                      setFormData(course);
                      setOpen(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    className={classes.iconButton}
                    onClick={async () => {
                      if (window.confirm('Are you sure you want to delete this course?')) {
                        const db = getFirestore();
                        await deleteDoc(doc(db, 'courses', course.id));
                        fetchCourses();
                      }
                    }}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </div>
              <CardContent>
                <Typography className={classes.cardDescription}>
                  {course.description}
                </Typography>
                <ExpansionPanel className={classes.chapterExpansion}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    className={classes.chapterHeader}
                  >
                    <Typography>Chapters ({course.chapters?.length || 0})</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <List className={classes.chapterList}>
                      {course.chapters?.map((chapter, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            primary={chapter.title}
                            secondary={`${chapter.modules?.length || 0} modules`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel className={classes.chapterExpansion}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    className={classes.chapterHeader}
                  >
                    <Typography>Downloadable Content ({course.downloadableContent?.length || 0})</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <List className={classes.chapterList}>
                      {course.downloadableContent?.map((content, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            primary={content.title}
                            secondary={
                              <React.Fragment>
                                <Typography component="div" className={classes.contentDescription}>
                                  {content.description}
                                </Typography>
                                {content.fileName && (
                                  <Typography component="div" className={classes.fileName}>
                                    File: {content.fileName}
                                  </Typography>
                                )}
                              </React.Fragment>
                            }
                          />
                          {content.fileUrl && (
                            <ListItemSecondaryAction>
                              <Button
                                variant="contained"
                                size="small"
                                className={classes.downloadButton}
                                href={content.fileUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Download
                              </Button>
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );

  const UploadProgress = () => {
    if (!uploading) return null;

    return (
      <div className={classes.progressContainer}>
        <LinearProgress 
          variant="determinate" 
          value={uploadProgress} 
          className={classes.progressBar}
        />
        <Typography className={classes.progressText}>
          Uploading... {Math.round(uploadProgress)}%
        </Typography>
      </div>
    );
  };

  const handleKeyDown = (e, chapterIndex) => {
    // Ctrl/Cmd + B for bold
    if ((e.ctrlKey || e.metaKey) && e.key === 'b') {
      e.preventDefault();
      const textarea = e.target;
      const { selectionStart, selectionEnd } = textarea;
      const { newText, newCursorPosition } = insertFormatting(
        textarea.value,
        selectionStart,
        selectionEnd,
        'bold'
      );
      handleChapterChange(chapterIndex, 'description', newText);
      setTimeout(() => {
        textarea.focus();
        textarea.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    }
    // Ctrl/Cmd + I for italic
    if ((e.ctrlKey || e.metaKey) && e.key === 'i') {
      e.preventDefault();
      const textarea = e.target;
      const { selectionStart, selectionEnd } = textarea;
      const { newText, newCursorPosition } = insertFormatting(
        textarea.value,
        selectionStart,
        selectionEnd,
        'italic'
      );
      handleChapterChange(chapterIndex, 'description', newText);
      setTimeout(() => {
        textarea.focus();
        textarea.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    }
    // * + space for bullet point at start of line
    if (e.key === ' ' && e.target.value.split('\n').pop() === '*') {
      e.preventDefault();
      const textarea = e.target;
      const value = textarea.value;
      const newValue = value.slice(0, -1) + '• ';
      handleChapterChange(chapterIndex, 'description', newValue);
    }
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.addButton}
        startIcon={<Add />}
        onClick={() => {
          setEditingCourse(null);
          setFormData({
            title: '',
            description: '',
            imageUrl: '',
            isDev: false,
            chapters: [{
              title: '',
              imageUrl: '',
              description: '',
              videoUrl: '',
              chapterHeader: '',
              modules: []
            }],
            downloadableContent: []
          });
          setOpen(true);
        }}
      >
        Add New Course
      </Button>

      <CoursesList 
        courses={productionCourses} 
        title={isDevEnvironment ? "Production Courses" : undefined} 
      />

      {isDevEnvironment && (
        <>
          <div className={classes.divider} />
          <CoursesList courses={developmentCourses} title="Development Courses" />
        </>
      )}

      <Dialog open={open} onClose={() => setOpen(false)} className={classes.dialog}>
        <DialogTitle className={classes.dialogTitle}>
          {editingCourse ? 'Edit Course' : 'Add New Course'}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            label="Course Title"
            fullWidth
            className={classes.formField}
            value={formData.title}
            onChange={(e) => {
              const value = e.target.value;
              setFormData(prev => ({ ...prev, title: value }));
            }}
          />
          <TextField
            label="Course Description"
            fullWidth
            multiline
            rows={4}
            className={classes.formField}
            value={formData.description}
            onChange={(e) => {
              const value = e.target.value;
              setFormData(prev => ({ ...prev, description: value }));
            }}
          />
          {isDevEnvironment && (
            <FormControlLabel
              control={
                <Switch
                  checked={formData.isDev}
                  onChange={(e) => {
                    setFormData(prev => ({ ...prev, isDev: e.target.checked }));
                  }}
                  color="primary"
                />
              }
              label="Development Course"
              className={classes.devToggle}
            />
          )}
          <TextField
            label="Image URL"
            fullWidth
            className={classes.formField}
            value={formData.imageUrl}
            onChange={(e) => {
              const value = e.target.value;
              setFormData(prev => ({ ...prev, imageUrl: value }));
            }}
          />

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="chapters">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {formData.chapters.map((chapter, chapterIndex) => (
                    <Draggable
                      key={chapterIndex}
                      draggableId={`chapter-${chapterIndex}`}
                      index={chapterIndex}
                    >
                      {(provided, snapshot) => (
                        <ExpansionPanel
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          key={chapterIndex}
                          className={classes.chapterExpansion}
                          style={{
                            ...provided.draggableProps.style,
                            marginBottom: snapshot.isDragging ? '16px' : undefined
                          }}
                        >
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            className={classes.chapterHeader}
                            style={{
                              background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
                              color: 'white'
                            }}
                          >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                              <div {...provided.dragHandleProps} className={classes.dragHandle}>
                                ⋮⋮
                              </div>
                              <TextField
                                label={`Chapter ${chapterIndex + 1} Title`}
                                value={chapter.title}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  handleChapterChange(chapterIndex, 'title', value);
                                }}
                                onClick={(e) => e.stopPropagation()}
                                style={{ flexGrow: 1 }}
                                InputProps={{
                                  style: { color: 'white' },
                                  classes: { notchedOutline: classes.whiteOutline }
                                }}
                                InputLabelProps={{
                                  style: { color: 'white' }
                                }}
                              />
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteChapter(chapterIndex);
                                }}
                                style={{ color: 'white' }}
                              >
                                <Delete />
                              </IconButton>
                            </div>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <div style={{ width: '100%' }}>
                              <TextField
                                label="Chapter Header"
                                fullWidth
                                value={chapter.chapterHeader || `Chapter ${chapterIndex + 1}`}
                                onChange={(e) => handleChapterChange(chapterIndex, 'chapterHeader', e.target.value)}
                                className={classes.formField}
                                helperText="Custom chapter header (e.g. 'Bonus Chapter 1' or 'Chapter II')"
                              />
                              <div style={{ marginBottom: '16px' }}>
                                <div className={classes.formattingToolbar}>
                                  <Tooltip title="Bold (Ctrl/Cmd + B)">
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        const textarea = document.getElementById(`chapter-${chapterIndex}-description`);
                                        const { selectionStart, selectionEnd } = textarea;
                                        const { newText, newCursorPosition } = insertFormatting(
                                          chapter.description,
                                          selectionStart,
                                          selectionEnd,
                                          'bold'
                                        );
                                        handleChapterChange(chapterIndex, 'description', newText);
                                        setTimeout(() => {
                                          textarea.focus();
                                          textarea.setSelectionRange(newCursorPosition, newCursorPosition);
                                        }, 0);
                                      }}
                                    >
                                      <FormatBold />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Italic (Ctrl/Cmd + I)">
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        const textarea = document.getElementById(`chapter-${chapterIndex}-description`);
                                        const { selectionStart, selectionEnd } = textarea;
                                        const { newText, newCursorPosition } = insertFormatting(
                                          chapter.description,
                                          selectionStart,
                                          selectionEnd,
                                          'italic'
                                        );
                                        handleChapterChange(chapterIndex, 'description', newText);
                                        setTimeout(() => {
                                          textarea.focus();
                                          textarea.setSelectionRange(newCursorPosition, newCursorPosition);
                                        }, 0);
                                      }}
                                    >
                                      <FormatItalic />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Bullet Point (Type * + space)">
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        const textarea = document.getElementById(`chapter-${chapterIndex}-description`);
                                        const { selectionStart, selectionEnd } = textarea;
                                        const { newText, newCursorPosition } = insertFormatting(
                                          chapter.description,
                                          selectionStart,
                                          selectionEnd,
                                          'bullet'
                                        );
                                        handleChapterChange(chapterIndex, 'description', newText);
                                        setTimeout(() => {
                                          textarea.focus();
                                          textarea.setSelectionRange(newCursorPosition, newCursorPosition);
                                        }, 0);
                                      }}
                                    >
                                      <FormatListBulleted />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <TextField
                                  label="Chapter Description"
                                  id={`chapter-${chapterIndex}-description`}
                                  fullWidth
                                  multiline
                                  rows={4}
                                  value={chapter.description || ''}
                                  onChange={(e) => handleChapterChange(chapterIndex, 'description', e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e, chapterIndex)}
                                  helperText="Use Ctrl/Cmd + B for bold, Ctrl/Cmd + I for italic, or type * + space for bullet points"
                                />
                              </div>
                              {chapter.description && (
                                <div style={{ 
                                  marginTop: '8px',
                                  padding: '16px',
                                  backgroundColor: '#f5f5f5',
                                  borderRadius: '4px'
                                }}>
                                  <Typography variant="subtitle2" style={{ 
                                    marginBottom: '16px', 
                                    color: '#666',
                                    borderBottom: '1px solid #ddd',
                                    paddingBottom: '8px'
                                  }}>
                                    Preview
                                  </Typography>
                                  <div style={{ 
                                    fontFamily: 'Roboto, sans-serif',
                                    '& p': { marginBottom: '16px' },
                                    '& p:last-child': { marginBottom: 0 }
                                  }}>
                                    {renderFormattedText(chapter.description)}
                                  </div>
                                </div>
                              )}
                              <div className={classes.uploadButton}>
                                <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>Chapter Image</Typography>
                                <Input
                                  type="file"
                                  id={`chapter-image-upload-${chapterIndex}`}
                                  style={{ display: 'none' }}
                                  onChange={(e) => handleChapterImageUpload(chapterIndex, e)}
                                  accept="image/*"
                                />
                                <label htmlFor={`chapter-image-upload-${chapterIndex}`}>
                                  <Button
                                    variant="contained"
                                    component="span"
                                    startIcon={<CloudUpload />}
                                    disabled={uploading}
                                  >
                                    {uploading ? 'Uploading...' : 'Upload Chapter Image'}
                                  </Button>
                                </label>
                                <Typography variant="body2" style={{ marginTop: '10px' }}>
                                  Or enter image URL:
                                </Typography>
                                <TextField
                                  fullWidth
                                  placeholder="Enter image URL"
                                  value={chapter.imageUrl || ''}
                                  onChange={(e) => handleImageUrlChange(chapterIndex, e.target.value)}
                                  className={classes.formField}
                                />
                                {chapter.imageUrl && (
                                  <div style={{ marginTop: '10px' }}>
                                    <Typography variant="body2" className={classes.uploadSuccess}>
                                      {chapter.imageUrl.includes('firebasestorage.googleapis.com') ?
                                        'Image uploaded successfully' :
                                        'Image URL added successfully'
                                      }
                                    </Typography>
                                    <img
                                      src={chapter.imageUrl}
                                      alt="Chapter thumbnail"
                                      style={{
                                        maxWidth: '200px',
                                        marginTop: '10px',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                      }}
                                    />
                                  </div>
                                )}
                              </div>

                              <div className={classes.uploadButton}>
                                <Typography variant="subtitle1" className={classes.uploadTitle}>Chapter Video</Typography>
                                <Input
                                  type="file"
                                  id={`chapter-video-upload-${chapterIndex}`}
                                  style={{ display: 'none' }}
                                  onChange={(e) => handleChapterVideoUpload(chapterIndex, e)}
                                  accept="video/*"
                                />
                                <label htmlFor={`chapter-video-upload-${chapterIndex}`}>
                                  <Button
                                    variant="contained"
                                    component="span"
                                    startIcon={<CloudUpload />}
                                    disabled={uploading}
                                  >
                                    {uploading ? 'Uploading...' : 'Upload Chapter Video'}
                                  </Button>
                                </label>
                                <UploadProgress />
                                <Typography variant="body2" style={{ marginTop: '10px' }}>
                                  Or enter video URL:
                                </Typography>
                                <TextField
                                  fullWidth
                                  placeholder="Enter video URL (YouTube or direct video link)"
                                  value={chapter.videoUrl || ''}
                                  onChange={(e) => handleVideoUrlChange(chapterIndex, undefined, e.target.value)}
                                  className={classes.formField}
                                />
                                {chapter.videoUrl && (
                                  <Typography variant="body2" className={classes.uploadSuccess}>
                                    {chapter.videoUrl.includes('firebasestorage.googleapis.com') ?
                                      'Video uploaded successfully' :
                                      'Video URL added successfully'
                                    }
                                  </Typography>
                                )}
                              </div>

                              {chapter.modules.map((module, moduleIndex) => (
                                <div key={moduleIndex} className={classes.moduleContent}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                    <Typography variant="h6">Module {moduleIndex + 1}</Typography>
                                    <IconButton onClick={() => handleDeleteModule(chapterIndex, moduleIndex)}>
                                      <Delete />
                                    </IconButton>
                                  </div>
                                  <TextField
                                    className={classes.formField}
                                    fullWidth
                                    label={`Module ${moduleIndex + 1} Title`}
                                    value={module.title}
                                    onChange={(e) => handleModuleChange(chapterIndex, moduleIndex, 'title', e.target.value)}
                                  />
                                  <TextField
                                    className={classes.formField}
                                    fullWidth
                                    label="Content"
                                    value={module.content}
                                    onChange={(e) => handleModuleChange(chapterIndex, moduleIndex, 'content', e.target.value)}
                                    multiline
                                    rows={6}
                                    helperText="You can include text and image URLs. Use [img]URL[/img] to embed images within the content."
                                  />

                                  <div className={classes.uploadButton}>
                                    <Input
                                      type="file"
                                      id={`module-video-upload-${chapterIndex}-${moduleIndex}`}
                                      style={{ display: 'none' }}
                                      onChange={(e) => handleModuleVideoUpload(chapterIndex, moduleIndex, e)}
                                      accept="video/*"
                                    />
                                    <label htmlFor={`module-video-upload-${chapterIndex}-${moduleIndex}`}>
                                      <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<CloudUpload />}
                                        disabled={uploading}
                                      >
                                        {uploading ? 'Uploading...' : 'Upload Module Video'}
                                      </Button>
                                    </label>
                                    <UploadProgress />
                                    <Typography variant="body2" style={{ marginTop: '10px' }}>
                                      Or enter video URL:
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      placeholder="Enter video URL (YouTube or direct video link)"
                                      value={module.videoUrl || ''}
                                      onChange={(e) => handleVideoUrlChange(chapterIndex, moduleIndex, e.target.value)}
                                      className={classes.formField}
                                    />
                                    {module.videoUrl && (
                                      <Typography variant="body2" className={classes.uploadSuccess}>
                                        {module.videoUrl.includes('firebasestorage.googleapis.com') ?
                                          'Video uploaded successfully' :
                                          'Video URL added successfully'
                                        }
                                      </Typography>
                                    )}
                                  </div>

                                  <div className={classes.uploadButton} style={{ marginTop: '10px' }}>
                                    <Input
                                      type="file"
                                      id={`module-thumbnail-upload-${chapterIndex}-${moduleIndex}`}
                                      style={{ display: 'none' }}
                                      onChange={(e) => handleModuleThumbnailUpload(chapterIndex, moduleIndex, e)}
                                      accept="image/*"
                                    />
                                    <label htmlFor={`module-thumbnail-upload-${chapterIndex}-${moduleIndex}`}>
                                      <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<CloudUpload />}
                                        disabled={uploading}
                                      >
                                        {uploading ? 'Uploading...' : 'Upload Module Thumbnail'}
                                      </Button>
                                    </label>
                                    <UploadProgress />
                                    {module.imageUrl && (
                                      <div style={{ marginTop: '10px' }}>
                                        <Typography variant="body2" className={classes.uploadSuccess}>
                                          Thumbnail uploaded successfully
                                        </Typography>
                                        <img
                                          src={module.imageUrl}
                                          alt="Module thumbnail"
                                          style={{
                                            maxWidth: '200px',
                                            marginTop: '10px',
                                            borderRadius: '8px',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>

                                  <div className={classes.exerciseSection}>
                                    <Typography variant="subtitle1">Practice Exercises</Typography>
                                    {module.exercises?.map((exercise, exerciseIndex) => (
                                      <div key={exerciseIndex} className={classes.exerciseItem}>
                                        <TextField
                                          fullWidth
                                          label="Question"
                                          value={exercise.question}
                                          onChange={(e) => handleExerciseChange(chapterIndex, moduleIndex, exerciseIndex, 'question', e.target.value)}
                                        />
                                        <div className={classes.optionList}>
                                          {exercise.options.map((option, optionIndex) => (
                                            <TextField
                                              key={optionIndex}
                                              fullWidth
                                              label={`Option ${optionIndex + 1}`}
                                              value={option}
                                              onChange={(e) => handleExerciseChange(chapterIndex, moduleIndex, exerciseIndex, 'options', { index: optionIndex, text: e.target.value })}
                                            />
                                          ))}
                                        </div>
                                        <TextField
                                          select
                                          label="Correct Answer"
                                          value={exercise.correctAnswer}
                                          onChange={(e) => handleExerciseChange(chapterIndex, moduleIndex, exerciseIndex, 'correctAnswer', parseInt(e.target.value))}
                                          fullWidth
                                          className={classes.correctAnswerSelect}
                                        >
                                          {exercise.options.map((_, index) => (
                                            <MenuItem key={index} value={index}>
                                              Option {index + 1}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </div>
                                    ))}
                                    <Button
                                      variant="outlined"
                                      onClick={() => handleAddExercise(chapterIndex, moduleIndex)}
                                      className={classes.exerciseButton}
                                    >
                                      Add Exercise
                                    </Button>
                                  </div>
                                </div>
                              ))}
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  const newChapters = [...formData.chapters];
                                  newChapters[chapterIndex].modules.push({
                                    title: '',
                                    content: '',
                                    exercises: [],
                                    discussion: ''
                                  });
                                  setFormData({ ...formData, chapters: newChapters });
                                }}
                              >
                                Add Module
                              </Button>
                            </div>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddChapter}
            startIcon={<Add />}
            className={classes.formField}
          >
            Add Chapter
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              const newFormData = { ...formData };
              if (!newFormData.downloadableContent) {
                newFormData.downloadableContent = [];
              }
              newFormData.downloadableContent.push({
                title: '',
                description: '',
                fileUrl: ''
              });
              setFormData(newFormData);
            }}
            startIcon={<Add />}
            className={classes.formField}
            style={{ marginLeft: '10px' }}
          >
            Add Downloadable Content
          </Button>

          {formData.downloadableContent?.map((content, index) => (
            <ExpansionPanel key={index} className={classes.chapterExpansion}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                className={classes.chapterHeader}
                style={{
                  background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
                  color: 'white'
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <TextField
                    label={`Downloadable Content ${index + 1} Title`}
                    value={content.title}
                    onChange={(e) => {
                      const newDownloadableContent = [...formData.downloadableContent];
                      newDownloadableContent[index].title = e.target.value;
                      setFormData({ ...formData, downloadableContent: newDownloadableContent });
                    }}
                    onClick={(e) => e.stopPropagation()}
                    style={{ flexGrow: 1 }}
                    InputProps={{
                      style: { color: 'white' },
                      classes: { notchedOutline: classes.whiteOutline }
                    }}
                    InputLabelProps={{
                      style: { color: 'white' }
                    }}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      const newDownloadableContent = formData.downloadableContent.filter((_, i) => i !== index);
                      setFormData({ ...formData, downloadableContent: newDownloadableContent });
                    }}
                    style={{ color: 'white' }}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.moduleContent} style={{ width: '100%' }}>
                  <TextField
                    label="Description"
                    fullWidth
                    multiline
                    rows={4}
                    value={content.description}
                    onChange={(e) => {
                      const newDownloadableContent = [...formData.downloadableContent];
                      newDownloadableContent[index].description = e.target.value;
                      setFormData({ ...formData, downloadableContent: newDownloadableContent });
                    }}
                    className={classes.formField}
                  />

                  <div className={classes.uploadButton}>
                    <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>Downloadable File</Typography>
                    <Input
                      type="file"
                      id={`downloadable-content-${index}`}
                      style={{ display: 'none' }}
                      accept=".pdf,.epub,.mobi,.doc,.docx,.txt"
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        if (!file) return;

                        // Check file size (limit to 50MB)
                        if (file.size > 50 * 1024 * 1024) {
                          alert('File size must be less than 50MB');
                          return;
                        }

                        try {
                          setUploading(true);
                          const storage = getStorage();
                          const safeFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
                          const fileRef = ref(storage, `downloadable-content/${formData.title}/${Date.now()}_${safeFileName}`);

                          // Show upload progress
                          const uploadTask = uploadBytes(fileRef, file);

                          await uploadTask;
                          const fileUrl = await getDownloadURL(fileRef);

                          const newDownloadableContent = [...formData.downloadableContent];
                          newDownloadableContent[index].fileUrl = fileUrl;
                          newDownloadableContent[index].fileName = file.name; // Store original filename
                          setFormData({ ...formData, downloadableContent: newDownloadableContent });
                          setUploading(false);
                        } catch (error) {
                          console.error('Error uploading file:', error);
                          alert('Error uploading file: ' + error.message);
                          setUploading(false);
                        }
                      }}
                    />
                    <label htmlFor={`downloadable-content-${index}`}>
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUpload />}
                        disabled={uploading}
                      >
                        {uploading ? 'Uploading...' : 'Upload File'}
                      </Button>
                    </label>
                    <Typography variant="body2" style={{ marginTop: '10px', color: 'rgba(0, 0, 0, 0.6)' }}>
                      Supported formats: PDF, EPUB, MOBI, DOC, DOCX, TXT (Max 50MB)
                    </Typography>
                    <Typography variant="body2" style={{ marginTop: '20px' }}>
                      Or enter file URL:
                    </Typography>
            
                    <TextField
                      fullWidth
                      placeholder="Enter file URL"
                      value={content.fileUrl || ''}
                      onChange={(e) => {
                        const newDownloadableContent = [...formData.downloadableContent];
                        newDownloadableContent[index].fileUrl = e.target.value;
                        setFormData({ ...formData, downloadableContent: newDownloadableContent });
                      }}
                      className={classes.formField}
                    />
                    {content.fileUrl && (
                      <Typography variant="body2" className={classes.uploadSuccess}>
                        File URL added successfully
                      </Typography>
                    )}
                


                    <div className={classes.uploadButton} style={{ marginTop: '20px' }}>
                      <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>Content Image</Typography>
                      <Input
                        type="file"
                        id={`downloadable-content-image-${index}`}
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          if (!file) return;

                          try {
                            setUploading(true);
                            const storage = getStorage();
                            const safeFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
                            const imageRef = ref(storage, `downloadable-content-images/${formData.title}/${Date.now()}_${safeFileName}`);

                            await uploadBytes(imageRef, file);
                            const imageUrl = await getDownloadURL(imageRef);

                            const newDownloadableContent = [...formData.downloadableContent];
                            newDownloadableContent[index].imageUrl = imageUrl;
                            setFormData({ ...formData, downloadableContent: newDownloadableContent });
                            setUploading(false);
                          } catch (error) {
                            console.error('Error uploading image:', error);
                            alert('Error uploading image: ' + error.message);
                            setUploading(false);
                          }
                        }}
                      />
                      <label htmlFor={`downloadable-content-image-${index}`}>
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<CloudUpload />}
                          disabled={uploading}
                        >
                          {uploading ? 'Uploading...' : 'Upload Image'}
                        </Button>
                      </label>
                      <Typography variant="body2" style={{ marginTop: '10px' }}>
                        Or enter image URL:
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter image URL"
                        value={content.imageUrl || ''}
                        onChange={(e) => {
                          const newDownloadableContent = [...formData.downloadableContent];
                          newDownloadableContent[index].imageUrl = e.target.value;
                          setFormData({ ...formData, downloadableContent: newDownloadableContent });
                        }}
                        className={classes.formField}
                      />
                      {content.imageUrl && (
                        <div style={{ marginTop: '10px' }}>
                          <Typography variant="body2" className={classes.uploadSuccess}>
                            Image added successfully
                          </Typography>
                          <img
                            src={content.imageUrl}
                            alt="Content preview"
                            style={{
                              maxWidth: '200px',
                              marginTop: '10px',
                              borderRadius: '8px',
                              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                            }}
                          />
                        </div>
                      )}
                    </div>


                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={() => setOpen(false)} className={classes.actionButton}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className={`${classes.actionButton} ${classes.saveButton}`}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CoursesPanel; 