import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export function useProductAccess(productId) {
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAccess = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        setHasAccess(false);
        setLoading(false);
        return;
      }

      try {
        const db = getFirestore();
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        
        // Check if user has the product in their purchases
        const hasProduct = userData?.purchases?.includes(productId);
        setHasAccess(hasProduct || false);
      } catch (error) {
        console.error('Error checking product access:', error);
        setHasAccess(false);
      }

      setLoading(false);
    };

    checkAccess();
  }, [productId]);

  return { hasAccess, loading };
} 