import React from 'react';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui icons
import CardMembership from '@material-ui/icons/CardMembership';
import CardGiftCard from '@material-ui/icons/CardGiftcard';
import AttachMoney from '@material-ui/icons/AttachMoney';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import featuresStyle from 'assets/jss/material-kit-pro-react/views/pricingSections/featuresStyle.js';
import useStylesVideo from './SectionPricing';

const useStyles = makeStyles(featuresStyle);

// Create additional styles for video heights
const useVideoHeightStyles = makeStyles(theme => ({
	tallVideo: {
		[theme.breakpoints.up('md')]: {
			height: '720px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '100%', // Will be controlled by the container's aspect ratio
		}
	},
	standardVideo: {
		[theme.breakpoints.up('md')]: {
			height: '650px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '250px', // Will be controlled by the container's aspect ratio
		}
	}
}));

export default function SectionFeatures() {
	const classesVideos = useStylesVideo();
	const classes = useStyles();
	const videoHeightClasses = useVideoHeightStyles();
	
	return (
		<div className={classes.featuresSection}>
			<div className={classes.textCenter}>
				<h3 className={classes.title}>
					Take a look at a real coaching session I had with a client, so you can see
					what our coaching sessions will look like, including in-field day game
					footage of my client talking to girls!
				</h3>
			</div>
			<GridContainer>
				<GridItem md={12} sm={12}>
					<Card plain pricing>
						<CardBody pricing>
							<div className={classesVideos.iframeContainer}>
								<iframe
									width='100%'
									className={videoHeightClasses.standardVideo}
									src='https://www.youtube.com/embed/fw9ndEx8sHw'
									title='YouTube video player'
									frameborder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
									allowfullscreen
								></iframe>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem md={12} sm={12}>
					<Card plain pricing>
						<CardBody pricing>
							<div className={classesVideos.iframeContainer}>
								<iframe
									width='100%'
									className={videoHeightClasses.standardVideo}
									src='https://www.youtube.com/embed/eZCIrUrvbks'
									title='YouTube video player'
									frameborder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
									allowfullscreen
								></iframe>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<hr />
				<GridItem md={12} sm={12}>
					<Card plain pricing>
						<CardBody pricing>
							<div className={classesVideos.iframeContainer}>
								<iframe
									width='100%'
									className={videoHeightClasses.standardVideo}
									src='https://www.youtube.com/embed/b2HeQNuwzH0'
									title='YouTube video player'
									frameborder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
									allowfullscreen
								></iframe>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem md={12} sm={12}>
					<Card plain pricing>
						<CardBody pricing>
							<div className={classesVideos.iframeContainer}>
								<iframe
									width='100%'
									className={videoHeightClasses.standardVideo}
									src='https://www.youtube.com/embed/a8_OeLEz5a4'
									title='Day Game is Hard At First, But Revolutionizes Your Dating Life (Testimonial)'
									frameborder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
									referrerPolicy='strict-origin-when-cross-origin'
									allowfullscreen
								></iframe>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<hr />
				<GridItem md={12} sm={12}>
					<Card plain pricing>
						<CardBody pricing>
							<div className={classesVideos.iframeContainer}>
								<iframe
									width='100%'
									className={videoHeightClasses.standardVideo}
									src='https://www.youtube.com/embed/3cm3K5R9dfY'
									title='Why Good Looking Guys Can Also Struggle with Girls'
									frameborder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
									referrerPolicy='strict-origin-when-cross-origin'
									allowfullscreen
								></iframe>
							</div>
						</CardBody>
					</Card>
				</GridItem>

				<hr />
				<GridItem md={12} sm={12}>
					<Card plain pricing>
						<CardBody pricing>
							<div className={classesVideos.iframeContainer}>
								<iframe
									width='100%'
									className={videoHeightClasses.standardVideo}
									src='https://www.youtube.com/embed/XzR-YPA1ca0'
									title='How to Approach Women in Public'
									frameborder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
									referrerPolicy='strict-origin-when-cross-origin'
									allowfullscreen
								></iframe>
							</div>
						</CardBody>
					</Card>
				</GridItem>

				<hr />
				<GridItem md={12} sm={12}>
					<Card plain pricing>
						<CardBody pricing>
							<div className={classesVideos.iframeContainer}>
								<iframe
									width='100%'
									className={videoHeightClasses.standardVideo}
									src='https://www.youtube.com/embed/DPpiFPWu8og'
									title='Coaching Session with Client'
									frameborder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
									referrerPolicy='strict-origin-when-cross-origin'
									allowfullscreen
								></iframe>
							</div>
						</CardBody>
					</Card>
				</GridItem>

				<hr />
				{/* <GridItem md={4} sm={4} className={classes.mlAuto}>
					<InfoArea
						title='How do I schedule my coaching calls?'
						description='After buying a coaching package, youll get instructions on scheduling calls. Use the provided scheduling tool to pick available times. Its best to schedule your calls in advance for a better coaching experience. Contact us if you need help.'
						icon={CardMembership}
						iconColor='info'
					/>
				</GridItem>
				<GridItem md={4} sm={4} className={classes.mrAuto}>
					<InfoArea
						title='What kind of results can I expect from coaching?'
						description='Clients can expect to gain greater self-awareness, develop more effective communication and interpersonal skills, and achieve their personal goals more efficiently.'
						icon={CardGiftCard}
						iconColor='success'
					/>
				</GridItem> */}
			</GridContainer>
			{/* <GridContainer>
				<GridItem md={4} sm={4} className={classes.mlAuto}>
					<InfoArea
						title='Which payment methods do you take?'
						description='Pay securely on our website using your card. We accept major credit and debit cards, like Visa, Mastercard, Amex, and Discover. Contact us if you have payment concerns.'
						icon={AttachMoney}
						iconColor='success'
					/>
				</GridItem>
				<GridItem md={4} sm={4} className={classes.mrAuto}>
					<InfoArea
						title='Any other questions we can answer?'
						description='Please feel free to leave a message, and I will be more than happy to assist you.'
						icon={QuestionAnswer}
						iconColor='rose'
					/>
				</GridItem>
			</GridContainer> */}
		</div>
	);
}
