import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import NavPills from 'components/NavPills/NavPills.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';
import Rodal from 'rodal';
import toast, { Toaster } from 'react-hot-toast';
import pricingStyle from 'assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle.js';
import typographyStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/typographyStyle.js';
import { collection, getDocs } from 'firebase/firestore';
import { db } from 'firebase.js';

const useStyles = makeStyles(pricingStyle);
const useStylesTypography = makeStyles(typographyStyle);

const useStylesVideo = makeStyles((theme) => ({
	iframeContainer: {
		position: 'relative',
		width: '100%',
		height: '0',
		paddingBottom: '56.25%', // 16:9 aspect ratio for desktop
		'& iframe': {
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: '0',
			left: '0',
		},
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '75%', // Taller aspect ratio for mobile to prevent compression
			margin: '10px 0', // Add some margin for better spacing on mobile
		}
	},
}));

export default function SectionPricing() {
	const classesVideos = useStylesVideo();
	const classes = useStyles();
	const typographyClasses = useStylesTypography();
	const [modalVisible, setModalVisible] = React.useState(false);
	const [stripeElements, setStripeElements] = React.useState(null);
	const [stripe, setStripe] = React.useState(null);
	// const loadingToastIdRef = React.useRef(null);

	const history = useHistory();

	const showModal = () => {
		setModalVisible(true);
		// setStripeElements(null); // Reset stripeElements state
		// setStripe(null); // Reset stripe state
		toast.success('Payment Initialized! Please wait...');
	};

	const hideModal = () => {
		setModalVisible(false);
		toast.dismiss();
		setStripeElements(null);
		setStripe(null);
		toast.error('Payment cancelled.');
	};

	// const showLoadingToast = (message) => {
	// 	if (!loadingToastIdRef.current) {
	// 		loadingToastIdRef.current = toast.loading(message);
	// 	} else {
	// 		toast.update(loadingToastIdRef.current, { render: message });
	// 	}
	// };

	// const hideLoadingToast = () => {
	// 	if (loadingToastIdRef.current) {
	// 		toast.dismiss(loadingToastIdRef.current);
	// 		loadingToastIdRef.current = null;
	// 	}
	// };

	const handleKeyDown = React.useCallback((event) => {
		if (event.keyCode === 27) {
			// ESC key
			hideModal();
		}
	}, []);

	React.useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown]);

	const buyTest = async (productId) => {
		const auth = getAuth();
		const user = auth.currentUser;

		if (!user) {
			history.push('/login-page');
			return;
		}

		// showLoadingToast('Payment Initialized! Please wait...');

		getIdToken(user).then(async (idToken) => {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/initPayment`,
				{
					productId: productId,
				},
				{
					headers: {
						Authorization: `Bearer ${idToken}`,
					},
				}
			);

			showModal();

			const stripe = window.Stripe(
				process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY
			);
			const elements = stripe.elements({
				appearance: { theme: 'stripe' },
				clientSecret: res.data.clientSecret,
			});
			const paymentElement = elements.create('payment');
			paymentElement.mount('#payment-element');

			setStripe(stripe);
			setStripeElements(elements);
		});
	};
	const getProducts = async () => {
		const querySnapshot = await getDocs(collection(db, 'products'));
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
		});
	};

	const pay = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		const auth = getAuth();
		const user = auth.currentUser;

		// showLoadingToast('Processing payment...');

		const { error } = await stripe.confirmPayment({
			elements: stripeElements,
			confirmParams: {
				payment_method_data: {
					billing_details: {
						name: user.name,
						email: user.email,
					},
				},
			},
			redirect: 'if_required',
		});

		// hideLoadingToast();

		if (error) {
			if (error.type === 'card_error' || error.type === 'validation_error') {
				toast.error(error.message);
			} else {
				toast.error('error');
			}
		} else {
			hideModal();
			toast.success(
				'Payment successful, please check your email/account for confirmation.'
			);
		}
	};

	return (
		<div className={classes.pricingSection}>
			<Toaster position='bottom-center' />
			<Rodal
				visible={modalVisible}
				closeMaskOnClick={false}
				onClose={() => hideModal()}
				borderRadius={10}
			>
				<div className={typographyClasses.typo}>
					<h3>Pay With Card</h3>
				</div>
				<div id='payment-element'></div>
				<div id='card-errors' className='mt-3 text-danger' role='alert'></div>

				<div
					style={{
						display: 'flex',
						marginTop: '10px',
						justifyContent: 'space-between',
					}}
				>
					<Button type='button' round onClick={() => hideModal()}>
						Close
					</Button>

					<Button type='button' color='rose' round onClick={pay}>
						Pay
					</Button>
				</div>
			</Rodal>

			<GridContainer>
				
				<hr />
				<GridItem
					md={6}
					sm={6}
					className={classNames(
						classes.mrAuto,
						classes.mlAuto,

						classes.textCenter
					)}
				>
					<NavPills
						alignCenter
						color='rose'
						tabs={[
							{
								tabButton: 'book a call today',
							},
						]}
					/>
				</GridItem>
			</GridContainer>
			<GridContainer>
				<GridItem md={4} sm={4}>
					<Card plain pricing>
						<CardBody pricing>
							<h6 className={classNames(classes.cardCategory, classes.textInfo)}>
								Standard
							</h6>
							<h1 className={classes.cardTitle}>{/* <small>$</small>997 */}</h1>
							<ul>
								<li>
									<b>1</b> Session
								</li>
								<li>
									<b>Expert Advice Tailored to You</b>
								</li>
								<li>
									<b>Fix Your Dating Problems Fast</b>
								</li>
								<li>
									<b> A Gameplan For Long-Term Success</b>
								</li>
							</ul>
							{/* <Button
								color="rose"
								round
								onClick={() => buyTest('cx5EQwsaxJIysdbDhGKb')}
							>
								Get Started
							</Button> */}
						</CardBody>
					</Card>
				</GridItem>
				<GridItem md={4} sm={4}>
					<Card raised pricing color='rose'>
						<CardBody pricing>
							<h6 className={classes.cardCategory}>Platinum</h6>
							<h1 className={classes.cardTitleWhite}>{/* <small>$</small>5999 */}</h1>
							<ul>
								<li>
									<b>8 Sessions</b> Of One-To-One Coaching
								</li>
								<li>
									<b>A 2 Month Plan</b>
								</li>
								<li>
									<b>Become The Best Version of Yourself</b>
								</li>
								<li>
									<b>I'm With You Every Step of the Way</b>
								</li>
							</ul>
							{/* <Button
								color="white"
								round
								onClick={() => buyTest('CbVTdkejEOpBry0mArJS')}
							>
								Get Started
							</Button> */}
						</CardBody>
					</Card>
				</GridItem>
				<GridItem md={4} sm={4}>
					<Card plain pricing>
						<CardBody pricing>
							<h6 className={classNames(classes.cardCategory, classes.textInfo)}>
								Premium
							</h6>
							<h1 className={classes.cardTitle}>{/* <small>$</small>3499 */}</h1>
							<ul>
								<li>
									<b>4</b> Sessions
								</li>
								<li>
									<b>A One Month Plan</b>
								</li>
								<li>
									<b>Begin The Transformation Process</b>
								</li>
								<li>
									<b>Fast Results</b>
								</li>
							</ul>
							{/* <Button
								color="rose"
								round
								onClick={() => buyTest('fqD2NgMJDqHvgQr6LneU')}
							>
								Get Started
							</Button> */}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</div>
	);
}
