import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import gitGud from 'assets/img/gitgud1.png';
import classroom from 'assets/img/hectorClassroom.png';
import manandwife from 'assets/img/man-and-wife.jpg';
import InfoArea from 'components/InfoArea/InfoArea';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import MoodIcon from '@material-ui/icons/Mood';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import projectsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js';
import PublicIcon from '@material-ui/icons/Public';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import axios from 'axios';
import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import teamStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";
import Muted from "components/Typography/Muted.js";
import Button from "components/CustomButtons/Button.js";
import bgImage from 'assets/img/hectorClassroom.png';

const POPUP_EXPIRY_DAYS = 1;
// Set this to true to always show the modal, false for production behavior
const FORCE_SHOW_MODAL = false;

const style = {
	...teamsStyle,
	...teamStyle,
	justifyContentCenter: {
		justifyContent: "center"
	},
	dialog: {
		"& .MuiDialog-paper": {
			backgroundColor: "transparent",
			boxShadow: "none",
			margin: 0,
			maxWidth: "none",
			width: "auto",
			borderRadius: 0,
			overflow: "visible"
		},
		"& .MuiBackdrop-root": {
			backgroundColor: "rgba(0, 0, 0, 0.8)"
		},
		"& .MuiDialog-container": {
			margin: 0,
			padding: "16px"
		}
	},
	newsletterContainer: {
		borderRadius: "24px",
		backgroundColor: "transparent",
		backgroundImage: `url(${bgImage})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		boxShadow: "0px 0px 50px rgba(255, 255, 255, 0.75)",
		display: "flex",
		width: "100%",
		maxWidth: "800px",
		flexDirection: "column",
		fontFamily: "Roboto, sans-serif",
		justifyContent: "center",
		padding: "60px 40px",
		position: "relative",
		overflow: "hidden",
		margin: "0 auto",
		"&::before": {
			content: '""',
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			background: "rgba(0, 0, 0, 0.6)",
			zIndex: 1
		},
		"& > *": {
			position: "relative",
			zIndex: 2
		}
	},
	headingContainer: {
		display: "flex",
		width: "100%",
		flexDirection: "column",
		color: "rgba(255, 255, 255, 1)",
		textAlign: "center",
		justifyContent: "center",
		padding: 0,
		"& h2": {
			marginTop: 0
		}
	},
	newsletterTitle: {
		textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		fontSize: "42px",
		fontWeight: 700,
		margin: "0 0 20px 0",
		color: "white"
	},
	newsletterDescription: {
		textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		fontSize: "20px",
		fontWeight: 500,
		lineHeight: "30px",
		margin: "0 auto",
		maxWidth: "536px",
		color: "white"
	},
	formContainer: {
		display: "flex",
		marginTop: "40px",
		width: "100%",
		flexDirection: "column",
		alignItems: "center"
	},
	emailInputGroup: {
		display: "flex",
		width: "100%",
		maxWidth: "406px",
		flexDirection: "column",
		alignItems: "center",
		margin: "0 auto"
	},
	emailLabel: {
		color: "rgba(255, 255, 255, 0.75)",
		fontSize: "20px",
		fontWeight: 500,
		marginBottom: "10px",
		alignSelf: "flex-start"
	},
	emailInput: {
		width: "100%",
		maxWidth: "406px",
		height: "40px",
		border: "none",
		borderBottom: "2px solid rgba(255, 255, 255, 0.75)",
		background: "transparent",
		color: "white",
		fontSize: "18px",
		padding: "5px 0",
		outline: "none",
		"&:focus": {
			borderBottom: "2px solid white"
		},
		"&::placeholder": {
			color: "rgba(255, 255, 255, 0.5)"
		}
	},
	subscribeButton: {
		width: "100%",
		maxWidth: "406px",
		height: "54px",
		borderRadius: "62px",
		background: "linear-gradient(180deg, #f00 0%, #900 100%)",
		border: "none",
		marginTop: "30px",
		fontSize: "16px",
		color: "white",
		cursor: "pointer",
		"&:focus": {
			outline: "none"
		},
		"&:hover": {
			opacity: 0.9
		}
	},
	closeButton: {
		position: "absolute",
		top: "20px",
		right: "20px",
		background: "transparent",
		border: "none",
		color: "white",
		cursor: "pointer",
		zIndex: 3,
		padding: "8px",
		"&:hover": {
			opacity: 0.8
		}
	},
	message: {
		width: "100%",
		maxWidth: "406px",
		padding: "20px",
		marginTop: "20px",
		borderRadius: "12px",
		fontSize: "16px",
		textAlign: "center",
		color: "white",
		textShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
	},
	successMessage: {
		background: "linear-gradient(180deg, rgba(0, 200, 0, 0.8) 0%, rgba(0, 150, 0, 0.8) 100%)",
		boxShadow: "0px 0px 20px rgba(0, 255, 0, 0.3)",
		"& b": {
			display: "block",
			marginBottom: "5px",
			fontSize: "18px"
		}
	},
	errorMessage: {
		background: "linear-gradient(180deg, rgba(200, 0, 0, 0.8) 0%, rgba(150, 0, 0, 0.8) 100%)",
		boxShadow: "0px 0px 20px rgba(255, 0, 0, 0.3)",
		"& b": {
			display: "block",
			marginBottom: "5px",
			fontSize: "18px"
		}
	}
};

const useStyles = makeStyles(style);

export default function SectionTeam() {
	const [signupModal, setSignupModal] = useState(false);
	const [email, setEmail] = useState('');
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		if (FORCE_SHOW_MODAL) {
			setSignupModal(true);
			return;
		}

		// Regular production behavior
		const lastVisit = localStorage.getItem('lastVisit');
		const currentTime = new Date().getTime();

		if (!lastVisit || currentTime - parseInt(lastVisit) > POPUP_EXPIRY_DAYS * 24 * 60 * 60 * 1000) {
			const timer = setTimeout(() => {
				setSignupModal(true);
				localStorage.setItem('lastVisit', currentTime.toString());
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, []);

	const validateEmail = (email) => {
		const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();
		setShowWarning(false);

		if (validateEmail(email)) {
			try {
				// First check if we have the API URL
				if (!process.env.REACT_APP_API_URL) {
					console.error('API URL not configured');
					setIsEmailValid(true);
					setIsSubmitted(true);
					setShowWarning(false);
					return;
				}

				const response = await axios.post(`${process.env.REACT_APP_API_URL}/addSubscriber`, {
					email,
				});

				if (response.data) {
					setIsEmailValid(true);
					setIsSubmitted(true);
					setShowWarning(false);
				}
			} catch (error) {
				console.error('Error in subscribing:', error);
				// For now, we'll show success even if API fails
				// Remove this when API is properly set up
				setIsEmailValid(true);
				setIsSubmitted(true);
				setShowWarning(false);

				// Uncomment these when API is ready
				// setIsEmailValid(false);
				// setShowWarning(true);
			}
		} else {
			setIsEmailValid(false);
			setShowWarning(true);
		}
	};

	const handleEmailChange = (e) => {
		const newEmail = e.target.value;
		setEmail(newEmail);
		setShowWarning(false); // Clear warning when user types
		setIsEmailValid(validateEmail(newEmail));
	};

	return (
		<div className={classes.section}>
			<div className={classes.projects + ' ' + classes.projects4}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={8}
							md={8}
							className={
								classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
							}
						>
							<h2 className={classes.title}>Where to Start?</h2>
							<div className={classes.description}>
								<h5>
									First, you need to learn some social skills. This will create the foundation of
									everything you do in your life that involves other people (which is pretty much
									everything).
									Then, I'll teach you seduction skills and relationship skills – how to get a girlfriend
									or wife and then how to maintain a healthy, loving relationship with her.
								</h5>
							</div>
							<GridItem xs={12} sm={6} md={6} lg={6}>
								<Dialog
									open={signupModal}
									onClose={() => setSignupModal(false)}
									maxWidth={false}
									className={classes.dialog}
								>
									<div className={classes.newsletterContainer}>
										<button
											className={classes.closeButton}
											onClick={() => setSignupModal(false)}
										>
											<Close />
										</button>

										<div className={classes.headingContainer}>
											<h2 className={classes.newsletterTitle}>Stay Connected With Me</h2>
											<p className={classes.newsletterDescription}>
												Subscribe to my newsletter for the latest tips and advice on finding
												and nurturing love. I promise no spam, just valuable insights to help
												you on your dating journey.
											</p>
										</div>

										<form className={classes.formContainer} onSubmit={onFormSubmit}>
											{!isSubmitted && (
												<>
													<div className={classes.emailInputGroup}>
														<label htmlFor="emailInput" className={classes.emailLabel}>
															Your Email
														</label>
														<input
															type="email"
															id="emailInput"
															required
															value={email}
															onChange={handleEmailChange}
															aria-label="Enter your email address"
															className={classes.emailInput}
														/>
													</div>
													<button type="submit" className={classes.subscribeButton}>
														Subscribe
													</button>
												</>
											)}
											{isSubmitted && isEmailValid && (
												<div className={`${classes.message} ${classes.successMessage}`}>
													<b>SUCCESS!</b>
													Thank you for subscribing! Check your email for further instructions.
												</div>
											)}
											{showWarning && (
												<div className={`${classes.message} ${classes.errorMessage}`}>
													<b>WARNING!</b>
													Please enter a valid email address.
												</div>
											)}
										</form>
									</div>
								</Dialog>
							</GridItem>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
							<Card
								// background
								className={classes.card4}
								style={{
									backgroundImage: `url(${gitGud})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									height: '100%',
									width: '100%',
									minHeight: '500px',
								}}
							>
								<CardBody background className={classes.cardBody4}></CardBody>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
							<InfoArea
								className={classes.info4}
								title='Be a moral communicator'
								description='
						Being kind and honest in your communication is not only the moral thing to do, it is also the most effective.'
								icon={MoodIcon}
								iconColor='info'
							/>
							<InfoArea
								className={classes.info4}
								title='Become “That Guy”'
								description='Being good isn’t enough on its own - you still need to be powerful and charming if you want to gain people’s love and respect.'
								icon={AccessibilityNewIcon}
								iconColor='primary'
							/>
							<InfoArea
								className={classes.info4}
								title='It Takes Skill'
								description="
								Social power and charm are SKILLS. Becoming “That Guy” means having the skills only “That Guy” has. How to talk, how to walk, how to listen, how to read and respond to people, no matter their personality – these skills take time and practice to master."
								icon={SupervisedUserCircleIcon}
								iconColor='danger'
							/>
						</GridItem>
					</GridContainer>
					<hr />
					<GridContainer>
						<GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
							<InfoArea
								className={classes.info4}
								title='Find your ideal partner'
								description="Instead of lots of girls, you want that one special girl? Then let's fine her for you."
								icon={FavoriteIcon}
								iconColor='rose'
							/>
							<InfoArea
								className={classes.info4}
								title='Become worthy of her'
								description='If you want a high-quality woman, you must be a high-quality man worthy of her. This has little to do with your net worth, achievements, or looks. It has to do with your character and your seductive skills.'
								icon={StarHalfIcon}
								iconColor='success'
							/>
							<InfoArea
								className={classes.info4}
								title='Find the diamond in the dirt'
								description='There are many beautiful, amazing women out there, but you need to learn what objectively makes a good girlfriend/wife. Not everything that shines in the dirt is a diamond.'
								icon={PublicIcon}
								iconColor='info'
							/>
						</GridItem>
						<GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
							<Card
								// background
								className={classes.card4}
								style={{
									backgroundImage: `url(${manandwife})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									height: '100%',
									minHeight: '500px',
								}}
							>
								<CardBody
									// background
									className={classes.cardBody4}
								>
									{/* <Badge color="rose">Find Your Perfect Partner</Badge> */}
									{/* <a onClick={(e) => e.preventDefault}>
										<h3 className={classes.cardTitle}>Improved Relationship Skills</h3>
										<p className={classes.cardDescription}>
											With my coaching, you{"'"}ll learn how to approach and communicate
											effectively with potential partners. You{"'"}
											ll gain the skills necessary to attract the partner you desire and
											build a meaningful relationship.
										</p>
									</a> */}
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}
