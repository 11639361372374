import React, { useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { getFirestore, getDoc, doc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Redirect } from 'react-router-dom';

import 'assets/scss/material-kit-pro-react.scss?v=1.9.0';
import AuthContext, { AuthProvider } from 'contexts/AuthContext';

import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';

import './index.css';
import 'rodal/lib/rodal.css';

import BlogPostPage from 'views/BlogPostPage/BlogPostPage.js';
import ComponentsPage from 'views/ComponentsPage/ComponentsPage.js';
import DashboardPage from 'views/ProductPage/DashboardPage.js';
import LandingPage from 'views/LandingPage/LandingPage.js';
import LoginPage from 'views/LoginPage/LoginPage.js';
import PricingPage from 'views/PricingPage/PricingPage.js';
import ProfilePage from 'views/ProfilePage/ProfilePage.js';
import ProductPage from 'views/ProductPage/ProductPage.js';
import ProductPage2 from 'views/ProductPage/ProductPage2';
import ProductPage3 from 'views/ProductPage/ProductPage3';
import ProductPage4 from 'views/ProductPage/ProductPage4';
import SectionsPage from 'views/SectionsPage/SectionsPage.js';
import SignUpPage from 'views/SignUpPage/SignUpPage.js';
import ErrorPage from 'views/ProfilePage/ErrorPage.js';
import SuccessPage from 'views/ProfilePage/SuccessPage';
import SettingsPage from 'views/ProfilePage/SettingsPage';
import CoursesPage from 'views/CoursesPage/CoursesPage.js';
import CourseLesson from 'views/CourseLessonPage/CourseLesson.js';
import CourseOverview from 'views/CourseLessonPage/components/CourseOverview.js';
import toast, { Toaster } from 'react-hot-toast';
import PrivateRoute from 'contexts/PrivateRoute';
import AnonRoute from 'contexts/AnonRoute';
import AdminPage from "views/AdminPage/AdminPage.js";
import CourseRoute from 'contexts/CourseRoute';
import SupportPage from 'views/SupportPage/SupportPage.js';

var hist = createBrowserHistory({
	basename: process.env.PUBLIC_URL || '/',
	forceRefresh: false
});

const notify = (text) => toast(text);

const AuthInitializer = ({ children }) => {
	const [authInitialized, setAuthInitialized] = useState(false);
	const auth = getAuth();

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setAuthInitialized(true);
		});

		return () => unsubscribe();
	}, [auth]);

	if (!authInitialized) {
		return (
			<div style={{ 
				display: 'flex', 
				justifyContent: 'center', 
				alignItems: 'center', 
				height: '100vh',
				backgroundColor: '#f5f5f5'
			}}>
				<div>Initializing...</div>
			</div>
		);
	}

	return children;
};

const AdminRoute = ({ component: Component, ...rest }) => {
	const { user, userRoles, loading } = React.useContext(AuthContext);

	if (loading) {
		return (
			<div style={{ 
				display: 'flex', 
				justifyContent: 'center', 
				alignItems: 'center', 
				height: '100vh',
				backgroundColor: '#f5f5f5'
			}}>
				<div>Loading...</div>
			</div>
		);
	}

	return (
		<Route
			{...rest}
			render={props =>
				user && userRoles?.isAdmin ? (
					<Component {...props} />
				) : (
					<Redirect to={{ 
						pathname: "/", 
						state: { from: props.location }
					}} />
				)
			}
		/>
	);
};

const App = () => {
	return (
		<AuthInitializer>
			<>
				<Toaster position='bottom-center' />
				<Router history={hist}>
					<Switch>
						<Route exact path='/blog-post' component={BlogPostPage} />
						<Route exact path='/components' component={ComponentsPage} />
						<Route exact path='/sections' component={SectionsPage} />
						<AnonRoute exact notify={notify} path='/login-page' component={LoginPage} />
						<AnonRoute exact notify={notify} path='/signup-page' component={SignUpPage} />
						<PrivateRoute exact path='/profile-page' component={ProfilePage} />
						<PrivateRoute exact path='/settings' component={SettingsPage} />
						<PrivateRoute exact path='/error' component={ErrorPage} />
						<PrivateRoute exact path='/success' component={SuccessPage} />
						<Route exact path='/what-she-really-means' component={ProductPage} />
						<Route exact path='/meet-girls-everywhere' component={ProductPage2} />
						<Route exact path='/all-courses' component={ProductPage4} />
						<PrivateRoute exact path='/dashboard' component={DashboardPage} />
						<Route exact path='/coaching' component={PricingPage} />
						<Route exact path='/support' component={SupportPage} />
						<CourseRoute exact path='/courses' component={CoursesPage} />
						<CourseRoute exact path='/course/:courseId/lesson' component={CourseLesson} />
						<CourseRoute exact path='/course/:courseId/overview' component={CourseOverview} />
						<Route exact path='/landing-page' component={LandingPage} />
						<AdminRoute exact path='/admin' component={AdminPage} />
						<Route exact path='/' component={LandingPage} />
						<Route path='*' component={LandingPage} />
					</Switch>
				</Router>
			</>
		</AuthInitializer>
	);
};

ReactDOM.render(
	<AuthProvider>
		<App />
	</AuthProvider>,
	document.getElementById('root')
);
