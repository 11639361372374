import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import defaultAvatar from "../../assets/img/placeholder.jpg";

const useStyles = makeStyles((theme) => ({
  signInPrompt: {
    textAlign: 'center',
    padding: '20px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
    letterSpacing: '1.4px',
    fontFamily: 'Roboto Slab, sans-serif'
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '10px',
    fontFamily: 'Roboto Slab, sans-serif',
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    }
  },
  messageArea: {
    borderRadius: '10px',
    backgroundColor: 'rgba(223, 223, 224, 0)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    minHeight: '68px',
    gap: '10px',
    flexGrow: 1,
    padding: '10px',
    alignItems: 'center'
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    height: '100%'
  },
  userAvatar: {
    aspectRatio: '1',
    width: '35px',
    borderRadius: '100px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  userDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  username: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '14px',
    letterSpacing: '1.4px',
    fontFamily: 'Roboto Slab, sans-serif'
  },
  userType: {
    fontSize: '10px',
    letterSpacing: '1px',
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Roboto Slab, sans-serif'
  },
  textInput: {
    flex: 1,
    '& .MuiInputBase-root': {
      borderRadius: '25px',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      minHeight: '48px',
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.75)',
      letterSpacing: '1.4px',
      padding: '0 20px',
      border: 'none',
      fontFamily: 'Roboto Slab, sans-serif',
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.75)',
        fontFamily: 'Roboto Slab, sans-serif'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  sendButton: {
    borderRadius: '25px',
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 700,
    letterSpacing: '1.6px',
    padding: '12px 30px',
    border: 'none',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto Slab, sans-serif',
    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none'
    },
    '@media (max-width: 991px)': {
      whiteSpace: 'initial',
      padding: '12px 20px'
    }
  }
}));

export function MessageInput({ onSendMessage }) {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!user) return;

      const db = getFirestore();
      const userRef = doc(db, 'users', user.uid);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        setUserProfile(userSnap.data());
      }
    };

    fetchUserProfile();
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!message.trim() || !user) return;

    onSendMessage({
      username: user.displayName || user.email,
      userType: user.role || "Member",
      avatar: user.profilePicture || defaultAvatar,
      content: {
        message: message.trim()
      }
    });

    setMessage("");
  };

  if (!user) {
    return (
      <Typography className={classes.signInPrompt}>
        Please sign in to participate in the discussion
      </Typography>
    );
  }

  return (
    <form className={classes.inputContainer} onSubmit={handleSubmit}>
      <div className={classes.messageArea}>
        <div className={classes.userInfo}>
          <img 
            className={classes.userAvatar}
            src={userProfile?.profilePicture || defaultAvatar}
            alt={`${user.displayName || user.email}'s avatar`} 
          />
          <div className={classes.userDetails}>
            <Typography className={classes.username}>{user.displayName || user.email}</Typography>
            <Typography className={classes.userType}>{userProfile?.role || "Member"}</Typography>
          </div>
        </div>
        <TextField
          className={classes.textInput}
          variant="outlined"
          placeholder="Type your message here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
        />
      </div>
      <Button 
        type="submit" 
        className={classes.sendButton}
        disabled={!message.trim()}
      >
        Send
      </Button>
    </form>
  );
} 