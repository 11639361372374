import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Favorite from '@material-ui/icons/Favorite';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Footer from 'components/Footer/Footer.js';
import Button from 'components/CustomButtons/Button.js';
import Accordion from 'components/Accordion/Accordion.js';
import productStyle from 'assets/jss/material-kit-pro-react/views/productStyle.js';
import logo from 'assets/img/logo.png';
import mge from 'assets/img/mge.png';
import product1 from 'assets/img/examples/product1.jpg';
import product2 from 'assets/img/examples/product2.jpg';
import product4 from 'assets/img/examples/product4.jpg';
import toast, { Toaster } from 'react-hot-toast';
import { getAuth, getIdToken } from 'firebase/auth';
import { useRef } from 'react';
import axios from 'axios';
import typographyStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/typographyStyle.js';
import Rodal from 'rodal';

const useStyles = makeStyles(productStyle);
const useStylesTypography = makeStyles(typographyStyle);

export default function ProductPage2() {
	const classes = useStyles();
	const typographyClasses = useStylesTypography();
	const [modalVisible, setModalVisible] = React.useState(false);
	const [stripeElements, setStripeElements] = React.useState(null);
	const [stripe, setStripe] = React.useState(null);
	// const loadingToastIdRef = React.useRef(null);

	const history = useHistory();

	const showModal = () => {
		setModalVisible(true);
		// setStripeElements(null); // Reset stripeElements state
		// setStripe(null); // Reset stripe state
		toast.success('Payment Initialized! Please wait...');
	};

	const hideModal = () => {
		setModalVisible(false);
		toast.dismiss();
		setStripeElements(null);
		setStripe(null);
		toast.error('Payment cancelled.');
	};

	// const showLoadingToast = (message) => {
	// 	if (!loadingToastIdRef.current) {
	// 		loadingToastIdRef.current = toast.loading(message);
	// 	} else {
	// 		toast.update(loadingToastIdRef.current, { render: message });
	// 	}
	// };

	// const hideLoadingToast = () => {
	// 	if (loadingToastIdRef.current) {
	// 		toast.dismiss(loadingToastIdRef.current);
	// 		loadingToastIdRef.current = null;
	// 	}
	// };

	const handleKeyDown = React.useCallback((event) => {
		if (event.keyCode === 27) {
			// ESC key
			hideModal();
		}
	}, []);

	React.useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown]);

	const buyTest = async (productId) => {
		const auth = getAuth();
		const user = auth.currentUser;

		if (!user) {
			history.push('/login-page');
			return;
		}

		// showLoadingToast('Payment Initialized! Please wait...');

		getIdToken(user).then(async (idToken) => {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/initPayment`,
				{
					productId: productId,
				},
				{
					headers: {
						Authorization: `Bearer ${idToken}`,
					},
				}
			);

			showModal();

			const stripe = window.Stripe(
				process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY
			);
			const elements = stripe.elements({
				appearance: { theme: 'stripe' },
				clientSecret: res.data.clientSecret,
			});
			const paymentElement = elements.create('payment');
			paymentElement.mount('#payment-element');

			setStripe(stripe);
			setStripeElements(elements);
		});
	};

	const pay = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		const auth = getAuth();
		const user = auth.currentUser;

		// showLoadingToast('Processing payment...');

		const { error } = await stripe.confirmPayment({
			elements: stripeElements,
			confirmParams: {
				payment_method_data: {
					billing_details: {
						name: user.name,
						email: user.email,
					},
				},
			},
			redirect: 'if_required',
		});

		// hideLoadingToast();

		if (error) {
			if (error.type === 'card_error' || error.type === 'validation_error') {
				toast.error(error.message);
			} else {
				toast.error('Error processing payment.');
			}
		} else {
			hideModal();
			toast.success(
				'Payment successful, please check your email/account for confirmation.'
			);
		}
	};
	const images = [
		{
			original: mge,
			thumbnail: mge,
		},
		{
			original: product4,
			thumbnail: product4,
		},
		{
			original: product1,
			thumbnail: product1,
		},
		{
			original: product2,
			thumbnail: product2,
		},
	];
	return (
		<div className={classes.productPage} height="100%">
			<Toaster position="bottom-center" />
			<Rodal
				visible={modalVisible}
				closeMaskOnClick={false}
				onClose={() => hideModal()}
				borderRadius={10}
			>
				<div className={typographyClasses.typo}>
					<h3>Pay With Card</h3>
				</div>
				<div id="payment-element"></div>
				<div id="card-errors" className="mt-3 text-danger" role="alert"></div>

				<div
					style={{
						display: 'flex',
						marginTop: '10px',
						justifyContent: 'space-between',
					}}
				>
					<Button type="button" round onClick={() => hideModal()}>
						Close
					</Button>

					<Button type="button" color="rose" round onClick={pay}>
						Pay
					</Button>
				</div>
			</Rodal>
			<Header
				image={require('assets/img/logo.png')}
				brand={
					<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem' }} />
				}
				links={<HeaderLinks dropdownHoverColor="rose" />}
				fixed
				color="transparent"
				changeColorOnScroll={{
					height: 100,
				}}
			/>
			<Parallax
				image={require('assets/img/bg6.jpg')}
				filter="rose"
				className={classes.pageHeader}
			></Parallax>
			<div
				className={classNames(
					classes.section,
					classes.sectionGray,
					classes.sectionGrayExtended
				)}
			>
				<div className={classes.container}>
					<div
						className={classNames(classes.main, classes.mainRaised, classes.content)}
					>
						<GridContainer>
							<GridItem md={6} sm={6}>
								<img
									alt="Product"
									src={images[0].original}
									style={{
										width: '100%',
										justifyContent: 'center',
										alignContent: 'center',
									}}
								/>
							</GridItem>
							<GridItem md={6} sm={6}>
								<h2 className={classes.title}>Meet Girls Everywhere</h2>
								<h3 className={classes.mainPrice}>$297</h3>
								<Accordion
									active={0}
									activeColor="rose"
									collapses={[
										{
											title: 'How to Find Your Dream Girl Anytime, Anywhere',
											content: (
												<p>
													Are you tired of relying on bars, clubs, and dating apps to meet
													women? Do you want to learn how to approach your dream girl during
													the day, no matter where you are? With &quot;Meet Girls
													Everywhere&quot; you&apos;ll discover the secrets to meeting
													beautiful, high-value women anytime, anywhere.
												</p>
											),
										},
										{
											title: 'Become a Natural at Approaching Women During the Day',
											content: (
												<p>
													This course is designed to teach you how to confidently approach
													women during the day, without feeling awkward or nervous.
													You&apos;ll learn how to start conversations, build rapport, and
													create attraction, all in a natural and authentic way.
												</p>
											),
										},
										{
											title: 'Find Your Dream Girl in Unexpected Places',
											content: (
												<p>
													The best part about meeting women during the day is that you never
													know where you might find your dream girl. Whether you&apos;re at
													the grocery store, the park, or the coffee shop, there are
													beautiful, high-value women waiting to be approached. This course
													will show you how to spot these opportunities and make the most of
													them.
												</p>
											),
										},
									]}
								/>
								<GridContainer className={classes.pullRight}>
									<Button
										round
										color="rose"
										onClick={() => buyTest('c6RF045j7f9zAiQvXV6n')}
									>
										Buy Now <ShoppingCart />
									</Button>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
			<Footer
				content={
					<div className={classes.footer}>
						<div className={classes.container}>
							<div className={classes.left}>
								<List className={classes.list}>
									{/* Removed About and Philosophy links */}
								</List>
							</div>
							<div className={classes.right}>
								&copy; {1900 + new Date().getYear()} , made with{' '}
								<Favorite className={classes.icon} /> by{' '}
								<a
									href="https://www.dsignable.com"
									target="_blank"
									className={classes.aClasses}
									rel="noopener noreferrer"
								>
									Dsignable
								</a>{' '}
								for a better web.
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
}
