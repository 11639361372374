import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, updateProfile, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore, getDoc, doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { getUserDoc } from 'api/firebaseApi';
import PropTypes from 'prop-types';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [userRoles, setUserRoles] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const auth = getAuth();
		const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
			if (firebaseUser) {
				setUser(firebaseUser);
				// Fetch user roles and permissions
				try {
					const db = getFirestore();
					const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
					if (userDoc.exists()) {
						const userData = userDoc.data();
						setUserRoles({
							isAdmin: userData.userType === 'admin',
							productsOwned: userData.productsOwned || [],
							// Add any other role-related data here
						});
					}
				} catch (error) {
					console.error('Error fetching user roles:', error);
				}
			} else {
				setUser(null);
				setUserRoles(null);
			}
			setLoading(false);
		});

		return () => unsubscribe();
	}, []);

	const signInUser = async (email, password) => {
		try {
			await signInWithEmailAndPassword(auth, email, password);

			return true;
		} catch (error) {
			return { error: error.message };
		}
	};

	const updateProfileData = async ({
		displayName,
		displayDescription,
		photoURL,
	}) => {
		try {
			await updateProfile(auth.currentUser, {
				displayName,
				displayDescription,
				photoURL,
			});

			return true;
		} catch (error) {
			return { error: error.message };
		}
	};

	const signOutUser = async () => {
		try {
			await signOut(auth);
			setUser({});
			setUserRoles(null);
			return true;
		} catch (error) {
			return false;
		}
	};

	const getUserData = async () => {
		const userDocRef = doc(db, 'users', user.uid);
		const userDocSnap = await getDoc(userDocRef);
		setUser((prevUser) => ({ ...prevUser, ...userDocSnap.data() }));
	};

	const googleProvider = new GoogleAuthProvider();

	const signInWithGoogle = async () => {
		try {
			const result = await signInWithPopup(auth, googleProvider);
			const user = result.user;

			const userDocRef = doc(db, 'users', user.uid);
			const userDocSnap = await getDoc(userDocRef);

			if (!userDocSnap.exists()) {
				await setDoc(
					userDocRef,
					{
						uid: user.uid,
						email: user.email,
						displayName: user.displayName,
						createdAt: new Date(),
						productsOwned: [],
						userType: 'user',
						firstName: user.displayName?.split(' ')[0] || '',
						lastName: user.displayName?.split(' ').slice(1).join(' ') || ''
					},
					{ merge: true }
				);
			}
		} catch (error) {
			console.error('Error signing in with Google: ', error);
		}
	};

	const value = {
		user,
		userRoles,
		loading,
		signInUser,
		signOutUser,
		updateProfileData,
		signInWithGoogle,
		getUserData,
	};

	return (
		<AuthContext.Provider value={value}>
			{!loading ? children : (
				<div style={{ 
					display: 'flex', 
					justifyContent: 'center', 
					alignItems: 'center', 
					height: '100vh',
					backgroundColor: '#f5f5f5'
				}}>
					<div>Loading...</div>
				</div>
			)}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
