import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle.js";
import logo from "assets/img/logo.png";
import logoW from "assets/img/logoW.png";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  headerHidden: {
    transform: 'translateY(-100%)',
    transition: 'transform 200ms ease'
  },
  headerVisible: {
    transform: 'translateY(0)',
    transition: 'transform 200ms ease'
  }
}));

export default function Header(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [currentLogo, setCurrentLogo] = useState(logoW);
  const classes = useStyles();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    const currentScrollY = window.pageYOffset;
    const scrollDelta = currentScrollY - lastScrollY;
    
    // Color change logic
    const { color, changeColorOnScroll } = props;
    if (changeColorOnScroll) {
      if (currentScrollY > changeColorOnScroll.height) {
        document.body
          .getElementsByTagName("header")[0]
          .classList.remove(classes[color]);
        document.body
          .getElementsByTagName("header")[0]
          .classList.add(classes[changeColorOnScroll.color]);
        setCurrentLogo(logo); // Switch to regular logo when background is white
      } else {
        document.body
          .getElementsByTagName("header")[0]
          .classList.add(classes[color]);
        document.body
          .getElementsByTagName("header")[0]
          .classList.remove(classes[changeColorOnScroll.color]);
        setCurrentLogo(logoW); // Switch to white logo when background is transparent
      }
    }

    // Improved visibility logic that works well for both slow and fast scrolling
    // Always show header at the top of the page
    if (currentScrollY < 20) {
      setIsVisible(true);
    } 
    // For scrolling down - hide sooner with lower threshold
    else if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setIsVisible(false);
    } 
    // For scrolling up - any upward movement shows the header
    else if (currentScrollY < lastScrollY) {
      setIsVisible(true);
    }
    
    setLastScrollY(currentScrollY);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { color, links, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
    [classes.headerHidden]: !isVisible,
    [classes.headerVisible]: isVisible,
  });

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <Button className={classes.title}>
          <Link to="/">
            <img src={currentLogo} alt="logo" style={{ width: 'auto', height: '4rem' }} />
          </Link>
        </Button>
        <Hidden smDown implementation="css" className={classes.hidden}>
          <div className={classes.collapse}>{links}</div>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.object,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]),
  }),
};
