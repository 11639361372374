import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import { UserPost } from "./UserPost";
import { MessageInput } from "./MessageInput";
import { useEffect, useState } from "react";
import { getFirestore, collection, query, orderBy, onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';
import placeholderImage from "../../assets/img/placeholder.jpg";

const useStyles = makeStyles((theme) => ({
  boardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto Slab, sans-serif',
    padding: '0 1px',
    height: '100%'
  },
  boardContainer: {
    borderRadius: '25px',
    background: 'linear-gradient(180deg, #fff 0%, #dadada 100%)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(239, 239, 239, 0.25) inset',
    display: 'flex',
    minHeight: '722px',
    width: '100%',
    flexDirection: 'column',
    padding: '25px 11px',
    fontFamily: 'Roboto Slab, sans-serif',
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    }
  },
  boardHeader: {
    alignSelf: 'center',
    display: 'flex',
    minHeight: '84px',
    width: '100%',
    maxWidth: '996px',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px 0',
    fontFamily: 'Roboto Slab, sans-serif',
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    }
  },
  boardTitle: {
    fontSize: '30px',
    letterSpacing: '3px',
    width: '100%',
    textAlign: 'center',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 1)',
    fontFamily: 'Roboto Slab, sans-serif',
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    }
  },
  statsContainer: {
    display: 'flex',
    marginTop: '10px',
    width: '267px',
    maxWidth: '100%',
    alignItems: 'start',
    gap: '10px',
    fontSize: '16px',
    letterSpacing: '1.6px',
    fontFamily: 'Roboto Slab, sans-serif'
  },
  commentCount: {
    width: '267px',
    minWidth: '240px',
    color: 'rgba(0, 0, 0, 1)',
    fontWeight: 400,
    fontFamily: 'Roboto Slab, sans-serif'
  },
  headerDivider: {
    aspectRatio: '200',
    objectFit: 'contain',
    objectPosition: 'center',
    width: '400px',
    marginTop: '10px',
    maxWidth: '100%'
  },
  postsContainer: {
    borderRadius: '15px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    marginTop: '25px',
    flexGrow: 1,
    width: '100%',
    flexDirection: 'column',
    padding: '20px 20px 12px',
    position: 'relative',
    minHeight: '470px',
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    }
  },
  messageInputWrapper: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: '10px 0',
    marginTop: 'auto'
  },
  postsList: {
    flexGrow: 1,
    overflowY: 'auto',
    marginBottom: '20px'
  }
}));

function DiscussionBoard({ courseId, chapterId, moduleId }) {
  const classes = useStyles();
  const [posts, setPosts] = useState([]);
  const [totalComments, setTotalComments] = useState(0);

  // Generate fallback IDs if they're not provided
  const safeChapterId = chapterId || 'default-chapter';
  const safeModuleId = moduleId || 'default-module';
  const safeCourseId = courseId || 'default-course';

  useEffect(() => {
    if (!courseId) {
      console.warn('CourseId is missing in DiscussionBoard');
      return;
    }

    const db = getFirestore();
    const discussionsRef = collection(db, 'discussions');
    const q = query(
      discussionsRef,
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const filteredPosts = snapshot.docs
        .map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            // If avatar starts with "/static/media", use the imported placeholder
            avatar: data.avatar?.startsWith("/static/media") ? placeholderImage : data.avatar || placeholderImage,
            username: data.username || "Anonymous",
            // Get message either from content.message or message field
            message: data.content?.message || data.message || "",
            userType: data.userType || "Member",
            timestamp: data.timestamp
          };
        })
        .filter(post => 
          post.courseId === courseId && 
          post.chapterId === safeChapterId && 
          post.moduleId === safeModuleId
        );
      
      setPosts(filteredPosts);
      setTotalComments(filteredPosts.length);
    });

    return () => unsubscribe();
  }, [courseId, safeChapterId, safeModuleId]);

  const handleSendMessage = async (message) => {
    if (!courseId) {
      console.error('Missing courseId for discussion post');
      return;
    }

    const db = getFirestore();
    await addDoc(collection(db, 'discussions'), {
      courseId: safeCourseId,
      chapterId: safeChapterId,
      moduleId: safeModuleId,
      content: { message: message.message },
      message: message.message,
      avatar: message.avatar || placeholderImage,
      username: message.username || "Anonymous",
      userType: message.userType || "Member",
      timestamp: serverTimestamp()
    });
  };

  return (
    <div className={classes.boardWrapper}>
      <Paper className={classes.boardContainer}>
        <div className={classes.boardHeader}>
          <Typography variant="h1" className={classes.boardTitle}>Discussion Board</Typography>
          <div className={classes.statsContainer}>
            <div className={classes.commentCount}>Total Comments: {totalComments}</div>
          </div>
          <img 
            className={classes.headerDivider}
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/e6ab85e93ad9451db00e45c8874777d7/d676b49292b7e3bb3250d863a0fdeb62950b63b9a45e7b5e846d0943dfb9e956?apiKey=e6ab85e93ad9451db00e45c8874777d7&" 
            alt=""
          />
        </div>
        <div className={classes.postsContainer}>
          <div className={classes.postsList}>
            {posts.map((post) => (
              <UserPost key={post.id} {...post} />
            ))}
          </div>
          <div className={classes.messageInputWrapper}>
            <MessageInput onSendMessage={handleSendMessage} />
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default DiscussionBoard; 