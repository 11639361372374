import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import logo from 'assets/img/logo.png';
import GitGud from 'assets/img/gitgud1.png';
import hectorCourse from 'assets/img/hector_course.png';
import { getAuth, getIdToken } from 'firebase/auth';
import { getFirestore, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import axios from 'axios';
import toast from 'react-hot-toast';
import Rodal from 'rodal';
import { Button } from '@material-ui/core';

const COURSE_PRICE = {
  amount: 29700, // $297.00 in cents
  currency: 'usd',
  displayPrice: '- $297'
};

const testimonials = [
  {
    quote: "Hector's a talented coach who cares about his students and shares insightful commentary. Highly recommended!",
    author: "@joshgruber08",
    level: "Level 1 Member",
    rating: 5
  },
  {
    quote: "The insights and strategies I've learned have completely transformed my dating life. Thank you Hector!",
    author: "@michael_r",
    level: "Level 2 Member",
    rating: 5
  },
  {
    quote: "Finally, dating advice that actually works in the real world. This course is worth every penny.",
    author: "@david_smith",
    level: "Level 1 Member",
    rating: 5
  }
];

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    backgroundColor: 'rgba(175, 175, 175, 1)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh',
    
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100vh',
    width: '100%',
    padding: '7.5rem 0 0 100px',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      padding: '40px 20px 0'
    }
    
  },
  backgroundImage: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    zIndex: 0
  },
  overlay: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1,
    backdropFilter: 'blur(4px)',
  },
  mainContent: {
    position: 'relative',
    marginTop: '0px',
    zIndex: 2,
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      marginTop: '20px'
    }
  },
  contentGrid: {
    gap: '20px',
    display: 'flex',
    padding: '0 20px',
    '@media (max-width: 991px)': {
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 0
    }
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 'normal',
    width: '44%',
    marginLeft: 0,
    '@media (max-width: 991px)': {
      width: '100%'
    }
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 'normal',
    width: '56%',
    marginLeft: '20px',
    marginTop: '40px',
    '@media (max-width: 991px)': {
      width: '100%',
      marginLeft: 0,
      marginTop: '20px'
    }
  },
  courseImage: {
    aspectRatio: '1',
    objectFit: 'contain',
    objectPosition: 'center',
    width: '130%',
    filter: 'drop-shadow(0 4px 10px rgba(0, 0, 0, 0.5))',
    flexGrow: 1,
    marginLeft: '-10%',
    marginTop: '-3.5rem',
    paddingRight: '10%',
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    }
  },
  heroSection: {
    position: 'relative',
    display: 'flex',
    minHeight: 'auto',
    width: '100%',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'start',
    marginTop: '4.5vh',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      marginTop: '40px'
    }
  },
  title: {
    color: '#fff',
    fontSize: 'clamp(2.5rem, 3vw, 8rem)',
    fontWeight: 700,
    lineHeight: 1.2,
    fontFamily: 'Roboto Slab, sans-serif',
    '@media (max-width: 991px)': {
      fontSize: '2.5rem',
      lineHeight: 1.35
    }
  },
  redTitle: {
    background: 'linear-gradient(180deg, #FF0000 0%, #990000 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'inline-block'
  },
  description: {
    color: '#fff',
    marginTop: '1.25rem',
    fontSize: 'clamp(1.125rem, 1.25vw, 1.5rem)',
    lineHeight: 1.6,
    fontWeight: 300,
    fontFamily: 'Helvetica, sans-serif',
    '@media (max-width: 991px)': {
      fontSize: '1.125rem'
    }
  },
  buyButton: {
    alignSelf: 'stretch',
    borderRadius: '0.625rem',
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    marginTop: '1.25rem',
    padding: '0.9375rem 0.5rem',
    width: 'min(27.8125rem, 100%)',
    color: '#fff',
    textAlign: 'center',
    fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
    fontWeight: 700,
    fontFamily: 'Roboto, sans-serif',
    border: 'none',
    cursor: 'pointer',
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 0.9
    }
  },
  testimonialCard: {
    display: 'flex',
    marginTop: '30px',
    width: '100%',
    maxWidth: '551px',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      marginTop: '30px'
    }
  },
  testimonialQuote: {
    color: '#fff',
    letterSpacing: '0.1125em',
    fontSize: 'clamp(1.25rem, 2vw, 1.5rem)',
    lineHeight: 1.4,
    fontWeight: 700,
    fontFamily: 'Roboto Slab, sans-serif',
    '@media (max-width: 991px)': {
      fontSize: '1rem'
    }
  },
  userInfo: {
    alignSelf: 'start',
    display: 'flex',
    marginTop: '10px',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'center'
  },
  username: {
    color: '#fff',
    fontSize: 'clamp(0.875rem, 1.5vw, 1rem)',
    letterSpacing: '0.1em',
    fontFamily: 'Roboto Slab, sans-serif'
  },
  membershipLevel: {
    fontSize: 'clamp(0.625rem, 1vw, 0.75rem)',
    letterSpacing: '0.1em',
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Roboto Slab, sans-serif'
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  starIcon: {
    color: '#FFD700',
    fontSize: '16px'
  }
}));

export default function ProductPage4() {
  const classes = useStyles();
  const history = useHistory();
  const [stripe, setStripe] = useState(null);
  const [stripeElements, setStripeElements] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Auto-cycle testimonials every 5 seconds
    const timer = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const updateUserProducts = async (userId) => {
    const db = getFirestore();
    const userRef = doc(db, 'users', userId);
    try {
      await updateDoc(userRef, {
        productsOwned: arrayUnion('all-courses')
      });
    } catch (error) {
      console.error('Error updating user products:', error);
      throw error;
    }
  };

  const handleBuyClick = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      history.push('/login-page');
      return;
    }

    setLoading(true);
    try {
      // Development mode bypass
      if (process.env.NODE_ENV === 'development') {
        const db = getFirestore();
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, {
          productsOwned: arrayUnion('all-courses'),
          newPurchase: true
        });
        toast.success('Development mode: Purchase simulated successfully!');
        history.push('/courses');
        return;
      }

      const idToken = await getIdToken(user);
      
      // Changed to match ProductPage2's implementation
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/initPayment`,
        {
          productId: 'c6RF045j7f9zAiQvXV6n', // Using the same product ID as ProductPage2
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          }
        }
      );

      if (!res.data || !res.data.clientSecret) {
        throw new Error('Invalid response from payment server');
      }

      setModalVisible(true);
      
      const stripeInstance = window.Stripe(process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY);
      const elements = stripeInstance.elements({
        appearance: { theme: 'stripe' },
        clientSecret: res.data.clientSecret,
      });
      const paymentElement = elements.create('payment');
      paymentElement.mount('#payment-element');
      
      setStripe(stripeInstance);
      setStripeElements(elements);

    } catch (error) {
      console.error('Error initializing payment:', error);
      toast.error(error.message || 'Error initializing payment. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!stripe || !stripeElements) {
      toast.error('Payment system not initialized. Please try again.');
      return;
    }

    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      toast.error('Please log in to complete your purchase.');
      history.push('/login-page');
      return;
    }

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements: stripeElements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: user.name || user.email,
              email: user.email,
            },
          },
          return_url: `${window.location.origin}/courses`,
        },
        redirect: 'if_required',
      });

      if (error) {
        if (error.type === 'card_error' || error.type === 'validation_error') {
          toast.error(error.message);
        } else {
          toast.error('Error processing payment. Please try again.');
        }
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        try {
          const db = getFirestore();
          const userRef = doc(db, 'users', user.uid);
          await updateDoc(userRef, {
            productsOwned: arrayUnion('all-courses'),
            newPurchase: true
          });
          setModalVisible(false);
          toast.success('Payment successful! Redirecting to courses...');
          history.push('/courses');
        } catch (updateError) {
          console.error('Error updating user products:', updateError);
          toast.error('Payment successful but error updating access. Please contact support.');
        }
      } else {
        toast.error('Payment not completed. Please try again.');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      toast.error('Error processing payment. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.pageWrapper}>
      <Header
        brand={<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem' }} />}
        links={<HeaderLinks dropdownHoverColor="rose" />}
        fixed
        color="transparent"
      />
      <div className={classes.contentContainer}>
        <img src={GitGud} alt="background" className={classes.backgroundImage} />
        <div className={classes.overlay} />
        <main className={classes.mainContent}>
          <div className={classes.contentGrid}>
            <div className={classes.leftColumn}>
              <div className={classes.heroSection}>
                <h1 className={classes.title}>
                  <span className={classes.redTitle}>Meet Girls Everywhere</span><br/> and Transform Your<br/>Dating Life
                </h1>
                <p className={classes.description}>
                  Hey there! My name is Hector Castillo. I'm a social skills and relationship 
                  coach. I'm here to help you become the best version of yourself and also find your 
                  dream girl. And if you really pay attention to what I have to say, you'll soon 
                  discover that the skills I teach will change your entire life.
                </p>
                <button onClick={handleBuyClick} className={classes.buyButton}>
                  Buy Course {COURSE_PRICE.displayPrice}
                </button>
              </div>
              <div className={classes.testimonialCard}>
                <p className={classes.testimonialQuote}>
                  {testimonials[currentTestimonial].quote}
                </p>
                <div className={classes.userInfo}>
                  <div>
                    <div className={classes.username}>{testimonials[currentTestimonial].author}</div>
                    <div className={classes.membershipLevel}>{testimonials[currentTestimonial].level}</div>
                  </div>
                  <div className={classes.ratingContainer}>
                    {[...Array(testimonials[currentTestimonial].rating)].map((_, i) => (
                      <span key={i} className={classes.starIcon}>★</span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rightColumn}>
              <img src={hectorCourse} alt="Course Preview" className={classes.courseImage} />
            </div>
          </div>
        </main>
      </div>
      <Rodal visible={modalVisible} onClose={() => !loading && setModalVisible(false)} height={400}>
        <div>
          <h3>Pay With Card</h3>
          <div id="payment-element"></div>
          <div id="card-errors" className="mt-3 text-danger" role="alert"></div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <Button 
              onClick={() => setModalVisible(false)} 
              round 
              disabled={loading}
            >
              Cancel
            </Button>
            <Button 
              onClick={handlePaymentSubmit} 
              color="rose" 
              round
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Pay Now'}
            </Button>
          </div>
        </div>
      </Rodal>
    </div>
  );
} 