import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from "./AuthContext";

function CourseRoute({ component: Component, ...rest }) {
  const { user, userRoles, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        backgroundColor: '#f5f5f5'
      }}>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        user && userRoles?.productsOwned?.includes('all-courses') ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: "/all-courses",
            state: { from: props.location }
          }} />
        )
      }
    />
  );
}

export default CourseRoute; 