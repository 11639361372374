import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment
} from '@material-ui/core';
import { getFirestore, collection, getDocs, getDoc, doc, updateDoc, setDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { format, isValid } from 'date-fns';
import { toast } from 'react-hot-toast';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    marginTop: '20px'
  },
  table: {
    minWidth: 650,
    '@media (max-width: 768px)': {
      minWidth: 'unset',
    }
  },
  tableContainer: {
    borderRadius: '10px',
    backgroundColor: 'rgba(217, 217, 217, 0.5)',
    maxHeight: '600px',
    '@media (max-width: 768px)': {
      maxHeight: '400px',
      overflowX: 'auto'
    },
    '& .MuiTableHead-root': {
      position: 'sticky',
      top: 0,
      zIndex: 1
    }
  },
  tableHeader: {
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    '& th': {
      color: 'white !important',
      fontWeight: 'bold',
      fontSize: '16px',
      backgroundColor: 'transparent !important',
      '@media (max-width: 768px)': {
        fontSize: '14px',
        padding: '8px 4px'
      }
    }
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)'
    },
    '& td': {
      color: 'white',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      '@media (max-width: 768px)': {
        fontSize: '12px',
        padding: '8px 4px'
      }
    }
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px'
  },
  accessButton: {
    margin: '0 5px',
    padding: '8px 16px',
    borderRadius: '20px',
    color: 'white',
    textTransform: 'none',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&.grant': {
      backgroundColor: '#2ecc71',
      '&:hover': {
        backgroundColor: 'rgba(46, 204, 113, 0.8)'
      },
      '&:disabled': {
        backgroundColor: 'rgba(46, 204, 113, 0.5)',
        color: 'rgba(255, 255, 255, 0.7)'
      }
    },
    '&.revoke': {
      background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
      '&:hover': {
        background: 'linear-gradient(180deg, #d00 0%, #800 100%)'
      },
      '&:disabled': {
        opacity: 0.5,
        color: 'rgba(255, 255, 255, 0.7)'
      }
    }
  },
  tableHead: {
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    '& th': {
      color: 'white',
      fontWeight: 'bold'
    }
  },
  actionButton: {
    margin: '0 5px',
    '@media (max-width: 768px)': {
      margin: '2px',
      padding: '4px 8px',
      minWidth: 'unset'
    }
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      gap: '10px',
      '& > *': {
        width: '100%'
      }
    }
  },
  searchInput: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '5px',
    padding: '10px',
    color: 'white',
    border: 'none',
    marginRight: '10px',
    width: '300px',
    '@media (max-width: 768px)': {
      width: '100%',
      marginRight: '0'
    }
  },
  filterSelect: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '5px',
    padding: '10px',
    color: 'white',
    border: 'none',
    marginRight: '10px',
    '@media (max-width: 768px)': {
      width: '100%',
      marginRight: '0'
    }
  },
  hiddenOnMobile: {
    '@media (max-width: 768px)': {
      display: 'none'
    }
  },
  adminBadge: {
    backgroundColor: '#2ecc71',
    color: 'white',
    padding: '2px 8px',
    borderRadius: '5px',
    marginRight: '5px'
  },
  addButton: {
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    marginBottom: '20px',
    '&:hover': {
      background: 'linear-gradient(180deg, #f00 0%, #800 100%)'
    }
  },
  dialog: {
    '& .MuiDialog-paper': {
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      borderRadius: '10px',
      padding: '20px'
    }
  },
  dialogTitle: {
    textAlign: 'center',
    '& h2': {
      color: '#333'
    }
  },
  formControl: {
    marginBottom: '20px',
    width: '100%'
  },
  searchField: {
    width: '300px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.3)',
        borderRadius: '10px',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.5)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.7)',
      },
      '& input::placeholder': {
        color: 'rgba(255, 255, 255, 0.7)',
      },
      '& .MuiInputAdornment-root': {
        color: 'rgba(255, 255, 255, 0.7)',
      }
    },
  },
}));

export function UsersPanel() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
    displayName: '',
    firstName: '',
    lastName: '',
    userType: 'user'
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const db = getFirestore();
      const usersCollection = collection(db, 'users');
      const userSnapshot = await getDocs(usersCollection);
      const userList = userSnapshot.docs.map(doc => {
        const data = doc.data();
        let createdAtDate = null;
        
        // Handle different date formats
        if (data.createdAt) {
          if (data.createdAt.toDate) { // Firestore Timestamp
            createdAtDate = data.createdAt.toDate();
          } else if (data.createdAt instanceof Date) { // JavaScript Date
            createdAtDate = data.createdAt;
          } else if (typeof data.createdAt === 'string') { // String date
            createdAtDate = new Date(data.createdAt);
          }
        }

        return {
          id: doc.id,
          ...data,
          createdAt: createdAtDate,
          hasCourseAccess: data.userType === 'admin' || 
                          (data.productsOwned || []).includes('all-courses')
        };
      });
      setUsers(userList);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  const handleCourseAccess = async (userId, grant = true) => {
    setActionLoading(prev => ({ ...prev, [`${userId}-access`]: true }));
    try {
      const db = getFirestore();
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();
      
      let productsOwned = [...(userData.productsOwned || [])];
      
      if (grant) {
        if (!productsOwned.includes('all-courses')) {
          productsOwned.push('all-courses');
        }
      } else {
        productsOwned = productsOwned.filter(product => product !== 'all-courses');
      }
      
      await updateDoc(userRef, {
        productsOwned
      });
      
      // Update local state
      setUsers(prevUsers => 
        prevUsers.map(user => 
          user.id === userId 
            ? { 
                ...user, 
                productsOwned,
                hasCourseAccess: user.userType === 'admin' || productsOwned.includes('all-courses')
              }
            : user
        )
      );
    } catch (error) {
      console.error('Error updating course access:', error);
      toast.error('Failed to update course access');
    } finally {
      setActionLoading(prev => ({ ...prev, [`${userId}-access`]: false }));
    }
  };

  const handleAddUser = async () => {
    try {
      const auth = getAuth();
      
      // Create the user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newUser.email,
        newUser.password
      );

      // Update the user's display name
      await updateProfile(userCredential.user, {
        displayName: newUser.displayName || `${newUser.firstName} ${newUser.lastName}`.trim()
      });

      // Create the user document in Firestore
      const db = getFirestore();
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        uid: userCredential.user.uid,
        email: newUser.email,
        displayName: newUser.displayName || `${newUser.firstName} ${newUser.lastName}`.trim(),
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        createdAt: new Date(),
        userType: newUser.userType,
        productsOwned: []
      });

      // Refresh the users list
      await fetchUsers();
      
      // Close the dialog and reset form
      setOpenAddDialog(false);
      setNewUser({
        email: '',
        password: '',
        displayName: '',
        firstName: '',
        lastName: '',
        userType: 'user'
      });

      toast.success('User added successfully');
    } catch (error) {
      console.error('Error adding user:', error);
      toast.error(error.message || 'Failed to add user');
    }
  };

  const filteredUsers = users.filter(user => {
    const searchLower = searchTerm.toLowerCase();
    return (
      user.email.toLowerCase().includes(searchLower) ||
      (user.displayName || '').toLowerCase().includes(searchLower) ||
      (user.firstName || '').toLowerCase().includes(searchLower) ||
      (user.lastName || '').toLowerCase().includes(searchLower) ||
      (user.userType || '').toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <TextField
          className={classes.searchField}
          placeholder="Search users..."
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          className={classes.addButton}
          onClick={() => setOpenAddDialog(true)}
        >
          Add New User
        </Button>
      </div>

      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table} stickyHeader>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Join Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Course Access</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user.id} className={classes.tableRow}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.displayName || `${user.firstName} ${user.lastName}`.trim() || 'N/A'}</TableCell>
                  <TableCell>
                    {user.createdAt && isValid(user.createdAt) 
                      ? format(user.createdAt, 'MMM dd, yyyy')
                      : 'N/A'}
                  </TableCell>
                  <TableCell>{user.userType || 'user'}</TableCell>
                  <TableCell>
                    {actionLoading[`${user.id}-access`] ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        <Button
                          className={`${classes.accessButton} grant`}
                          onClick={() => handleCourseAccess(user.id, true)}
                          disabled={user.hasCourseAccess}
                        >
                          Grant Access
                        </Button>
                        <Button
                          className={`${classes.accessButton} revoke`}
                          onClick={() => handleCourseAccess(user.id, false)}
                          disabled={!user.hasCourseAccess || user.userType === 'admin'}
                        >
                          Revoke Access
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        className={classes.dialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.formControl}
            label="Email"
            type="email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            fullWidth
            required
          />
          <TextField
            className={classes.formControl}
            label="Password"
            type="password"
            value={newUser.password}
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            fullWidth
            required
          />
          <TextField
            className={classes.formControl}
            label="First Name"
            value={newUser.firstName}
            onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
            fullWidth
          />
          <TextField
            className={classes.formControl}
            label="Last Name"
            value={newUser.lastName}
            onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
            fullWidth
          />
          <FormControl className={classes.formControl}>
            <InputLabel>User Type</InputLabel>
            <Select
              value={newUser.userType}
              onChange={(e) => setNewUser({ ...newUser, userType: e.target.value })}
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleAddUser} 
            color="primary" 
            variant="contained"
            disabled={!newUser.email || !newUser.password}
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
} 