/*eslint-disable*/
import { useContext } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Group from '@material-ui/icons/Group';
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
import Check from '@material-ui/icons/Check';
import Favorite from '@material-ui/icons/Favorite';
import logo from 'assets/img/logo.png';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import AuthContext from 'contexts/AuthContext';
import axios from 'axios';
import signupPageStyle from 'assets/jss/material-kit-pro-react/views/signupPageStyle.js';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';

const useStyles = makeStyles(signupPageStyle);

export default function SignUpPage({ notify }) {
	const { signInWithGoogle } = useContext(AuthContext);
	const [checked, setChecked] = React.useState([1]);
	const [userForm, setUserForm] = React.useState({
		email: '',
		password: '',
		username: '',
		firstName: '',
		lastName: '',
		displayName: ''
	});

	const handleToggle = (value) => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];
		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked);
	};

	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});

	const classes = useStyles();

	const handleChange = (event) => {
		const { name, value } = event.target;

		setUserForm((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSignUp = async (event) => {
		event.preventDefault();

		if (!userForm.email || !userForm.password || !userForm.username) {
			notify('Please fill in all required fields');
			return;
		}

		try {
			// First create the user in Firebase Auth
			const auth = getAuth();
			const userCredential = await createUserWithEmailAndPassword(
				auth,
				userForm.email,
				userForm.password
			);

			// Update the user's display name
			await updateProfile(userCredential.user, {
				displayName: userForm.username
			});

			// Then send the additional data to your Cloud Function
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/signUpUser`,
				{ 
					email: userForm.email,
					displayName: userForm.username,
					firstName: userForm.firstName,
					lastName: userForm.lastName,
					uid: userCredential.user.uid,
					userType: 'user'
				}
			);

			if (res?.data?.error) {
				notify(res.data.error);
				return;
			}

			notify('Signed up successfully!');
			// No need to manually sign in since createUserWithEmailAndPassword already signs in the user
		} catch (error) {
			console.error('Sign up error:', error);
			notify(error.message || 'An error occurred during sign up');
		}
	};

	return (
		<div>
			<Header
				absolute
				color="transparent"
				image={require('assets/img/logo.png')}
				brand={
					<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem' }} />
				}
			/>
			<div
				className={classes.pageHeader}
				style={{
					// backgroundImage: 'url(' + image + ')',
					backgroundSize: 'cover',
					backgroundPosition: 'top center',
				}}
			>
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem xs={12} sm={10} md={10}>
							<Card className={classes.cardSignup}>
								<h2 className={classes.cardTitle}>Register</h2>
								<CardBody>
									<GridContainer justify="center">
										<GridItem xs={12} sm={5} md={5}>
											<InfoArea
												className={classes.infoArea}
												title="The Science of Dating: Strategies for Finding the Perfect Match"
												description="I'm going to help teach you how to get what you want in your dating life. "
												icon={EqualizerIcon}
												iconColor="rose"
											/>
											<InfoArea
												className={classes.infoArea}
												title="Unlocking Your Dating Potential: Tips for Success"
												description="Do you want more dates and girls?"
												icon={LockOpenIcon}
												iconColor="primary"
											/>
											<InfoArea
												className={classes.infoArea}
												title="Finding Love: How to Get the Girl of Your Dreams"
												description="or do you want that one special girl to settle down with"
												icon={Group}
												iconColor="info"
											/>
										</GridItem>
										<GridItem xs={12} sm={5} md={5}>
											<div className={classes.textCenter}>
												<div className={classes.socialLine}>
													<Button
														justIcon
														color="google"
														className={classes.iconButtons}
														onClick={() => signInWithGoogle()}
													>
														<i className="fab fa-google-plus-g" />
													</Button>
												</div>
												<h4 className={classes.socialTitle}>or be classical</h4>
											</div>
											<form className={classes.form}>
												<CustomInput
													formControlProps={{
														fullWidth: true,
														className: classes.customFormControlClasses,
													}}
													inputProps={{
														startAdornment: (
															<InputAdornment
																position="start"
																className={classes.inputAdornment}
															>
																<Face className={classes.inputAdornmentIcon} />
															</InputAdornment>
														),
														placeholder: 'Username',
														name: 'username',
														value: userForm.username,
														onChange: handleChange,
													}}
												/>
												<CustomInput
													formControlProps={{
														fullWidth: true,
														className: classes.customFormControlClasses,
													}}
													inputProps={{
														startAdornment: (
															<InputAdornment
																position="start"
																className={classes.inputAdornment}
															>
																<Face className={classes.inputAdornmentIcon} />
															</InputAdornment>
														),
														placeholder: 'First Name',
														name: 'firstName',
														value: userForm.firstName,
														onChange: handleChange,
													}}
												/>
												<CustomInput
													formControlProps={{
														fullWidth: true,
														className: classes.customFormControlClasses,
													}}
													inputProps={{
														startAdornment: (
															<InputAdornment
																position="start"
																className={classes.inputAdornment}
															>
																<Face className={classes.inputAdornmentIcon} />
															</InputAdornment>
														),
														placeholder: 'Last Name',
														name: 'lastName',
														value: userForm.lastName,
														onChange: handleChange,
													}}
												/>
												<CustomInput
													formControlProps={{
														fullWidth: true,
														className: classes.customFormControlClasses,
													}}
													inputProps={{
														startAdornment: (
															<InputAdornment
																position="start"
																className={classes.inputAdornment}
															>
																<Email className={classes.inputAdornmentIcon} />
															</InputAdornment>
														),
														placeholder: 'Email...',
														name: 'email',
														type: 'email',
														value: userForm.email,
														onChange: handleChange,
													}}
												/>
												<CustomInput
													formControlProps={{
														fullWidth: true,
														className: classes.customFormControlClasses,
													}}
													inputProps={{
														startAdornment: (
															<InputAdornment
																position="start"
																className={classes.inputAdornment}
															>
																<Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
															</InputAdornment>
														),
														placeholder: 'Password...',
														name: 'password',
														type: 'password',
														value: userForm.password,
														onChange: handleChange,
													}}
												/>
												<FormControlLabel
													classes={{
														label: classes.label,
													}}
													control={
														<Checkbox
															tabIndex={-1}
															onClick={() => handleToggle(1)}
															checkedIcon={<Check className={classes.checkedIcon} />}
															icon={<Check className={classes.uncheckedIcon} />}
															classes={{
																checked: classes.checked,
																root: classes.checkRoot,
															}}
															checked={checked.indexOf(1) !== -1 ? true : false}
														/>
													}
													label={
														<span>
															I agree to the <a href="#pablo">terms and conditions</a>.
														</span>
													}
												/>
												<div className={classes.textCenter}>
													<Button round color="primary" onClick={handleSignUp}>
														Get started
													</Button>
												</div>
											</form>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
				<Footer
					content={
						<div>
							<div className={classes.left}></div>
							<div className={classes.right}>
								&copy; {1900 + new Date().getYear()} , made with{' '}
								<Favorite className={classes.icon} /> by{' '}
								<a
									href="https://www.dsignable.com"
									target="_blank"
									rel="noopener noreferrer"
								>
									Dsignable
								</a>{' '}
								for a better web.
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
}
