import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GetApp, PictureAsPdf, Book, Description } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.02)'
    }
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '20px'
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0) 100%)',
    padding: '2rem',
    borderRadius: '0 0 20px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem'
  },
  title: {
    color: 'white',
    fontSize: '2rem',
    fontWeight: 700,
    margin: 0,
    fontFamily: 'Roboto Slab, sans-serif',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
    '@media (max-width: 768px)': {
      fontSize: '1.5rem'
    }
  },
  description: {
    color: 'rgba(255,255,255,0.9)',
    fontSize: '1.1rem',
    margin: '0.5rem 0',
    maxWidth: '80%',
    lineHeight: 1.4,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '4.5em',
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      height: '4.2em'
    }
  },
  downloadButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    padding: '0.75rem 1.5rem',
    borderRadius: '25px',
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    fontSize: '1rem',
    transition: 'opacity 0.2s',
    textDecoration: 'none',
    marginTop: '1rem',
    '&:hover': {
      opacity: 0.9,
      color: 'white',
      textDecoration: 'none'
    }
  },
  fileIcon: {
    fontSize: '1.5rem'
  }
}));

export const DownloadableCard = ({ content, isFocused }) => {
  const classes = useStyles();

  const getFileIcon = () => {
    const fileType = content.fileName ? content.fileName.split('.').pop().toLowerCase() : '';
    if (['pdf'].includes(fileType)) {
      return <PictureAsPdf className={classes.fileIcon} />;
    } else if (['epub', 'mobi'].includes(fileType)) {
      return <Book className={classes.fileIcon} />;
    } else if (['doc', 'docx', 'txt'].includes(fileType)) {
      return <Description className={classes.fileIcon} />;
    }
    return <GetApp className={classes.fileIcon} />;
  };

  return (
    <div className={classes.card}>
      <img 
        src={content.imageUrl || 'https://via.placeholder.com/800x450'} 
        alt={content.title} 
        className={classes.image}
      />
      <div className={classes.overlay}>
        {isFocused && (
          <>
            <h3 className={classes.title}>{content.title}</h3>
            {content.description && (
              <p className={classes.description}>{content.description}</p>
            )}
            <a 
              href={content.fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.downloadButton}
              download={content.fileName || true}
            >
              {getFileIcon()}
              Download {content.fileName || 'File'}
            </a>
          </>
        )}
      </div>
    </div>
  );
}; 