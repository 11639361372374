import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { Button, Container } from "@material-ui/core";
import logo from 'assets/img/logo.png';
import { UsersPanel } from "./components/UsersPanel";
import { CoursesPanel } from "./components/CoursesPanel";
import { SiteManagementPanel } from "./components/SiteManagementPanel";

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    backgroundColor: 'rgba(175, 175, 175, 1)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: '100vh',
    width: '100%',
    padding: '80px 0 40px',
    '@media (max-width: 991px)': {
      padding: '60px 0 20px'
    }
  },
  backgroundImage: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  adminPanel: {
    position: 'relative',
    display: 'flex',
    marginTop: '40px',
    flexDirection: 'column',
    fontFamily: 'Roboto Slab, sans-serif',
    textAlign: 'center',
    padding: '20px',
    maxWidth: '1400px',
    margin: '0 auto',
    width: '100%'
  },
  navigation: {
    display: 'flex',
    minHeight: '65px',
    width: '100%',
    alignItems: 'center',
    gap: '20px',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '20px',
    '@media (max-width: 991px)': {
      gap: '10px'
    }
  },
  navButton: {
    borderRadius: '10px',
    backgroundColor: 'rgba(217, 217, 217, 0.5)',
    minWidth: '240px',
    minHeight: '43px',
    color: 'white',
    fontSize: '16px',
    letterSpacing: '1.6px',
    '&:hover': {
      backgroundColor: 'rgba(217, 217, 217, 0.7)'
    }
  },
  activeNavButton: {
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    '&:hover': {
      background: 'linear-gradient(180deg, #f00 0%, #800 100%)'
    }
  }
}));

function AdminPage() {
  const classes = useStyles();
  const [activePanel, setActivePanel] = useState('users');

  const renderPanel = () => {
    switch (activePanel) {
      case 'users':
        return <UsersPanel />;
      case 'courses':
        return <CoursesPanel />;
      case 'site':
        return <SiteManagementPanel />;
      default:
        return <UsersPanel />;
    }
  };

  return (
    <div className={classes.pageWrapper}>
      <Header
        color="transparent"
        brand={<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem' }} />}
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: 'white',
        }}
      />
      <div className={classes.contentContainer}>
        <img
          className={classes.backgroundImage}
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d4f0624a8ef4791795b99e857631fd1d9c1ed3c7b65014f3c39f6c431b46374d?placeholderIfAbsent=true&apiKey=e6ab85e93ad9451db00e45c8874777d7"
          alt=""
        />
        
        <Container className={classes.adminPanel}>
          <div className={classes.navigation}>
            <Button
              className={`${classes.navButton} ${activePanel === 'users' ? classes.activeNavButton : ''}`}
              onClick={() => setActivePanel('users')}
            >
              USERS
            </Button>
            <Button
              className={`${classes.navButton} ${activePanel === 'courses' ? classes.activeNavButton : ''}`}
              onClick={() => setActivePanel('courses')}
            >
              COURSES
            </Button>
            <Button
              className={`${classes.navButton} ${activePanel === 'site' ? classes.activeNavButton : ''}`}
              onClick={() => setActivePanel('site')}
            >
              SITE MANAGEMENT
            </Button>
          </div>
          
          {renderPanel()}
        </Container>
      </div>
    </div>
  );
}

export default AdminPage; 