import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const useStyles = makeStyles({
  headerWrapper: {
    display: 'flex',
    minWidth: '240px',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff',
    fontWeight: 400,
    textAlign: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    width: '1033px',
    padding: '10px',
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    }
  },
  chapterTitle: {
    fontSize: 'clamp(2rem, 2.5vw, 3rem)',
    letterSpacing: '3px',
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    }
  },
  headerStats: {
    display: 'flex',
    marginTop: '10px',
    maxWidth: '100%',
    width: '1018px',
    alignItems: 'center',
    gap: '10px',
    fontSize: '16px',
    letterSpacing: '1.6px',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  statItem: {
    alignSelf: 'stretch',
    width: '267px',
    margin: 'auto 0'
  },
  starIcon: {
    width: '16px',
    height: '16px',
    alignSelf: 'stretch',
    margin: 'auto 0',
    color: '#fff'
  }
});

export function ChapterHeader({ chapter }) {
  const classes = useStyles();
  
  return (
    <header className={classes.headerWrapper}>
      <Typography variant="h1" className={classes.chapterTitle}>
        {chapter?.title || "Chapter Title"}
      </Typography>
    </header>
  );
} 