/*eslint-disable*/
import React, { useContext } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Favorite from '@material-ui/icons/Favorite';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Footer from 'components/Footer/Footer.js';
import SectionPricing from 'views/PricingPage/Sections/SectionPricing.js';
import SectionFeatures from 'views/PricingPage/Sections/SectionFeatures.js';
import pricingStyle from 'assets/jss/material-kit-pro-react/views/pricingStyle.js';
import logo from 'assets/img/logo.png';
import SectionWork from 'views/LandingPage/Sections/SectionWork';
import { useHistory } from 'react-router-dom';
import AuthContext from 'contexts/AuthContext';
import { useProductAccess } from 'hooks/useProductAccess';

const useStyles = makeStyles(theme => ({
	...pricingStyle,
	container: {
		color: "white",
		...pricingStyle.container,
		zIndex: "2"
	},
	viewCoursesButton: {
		alignSelf: 'stretch',
		border: 'none',
		borderRadius: '10px',
		background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'center',
		width: '80%',
		padding: '15px 8px',
		font: '700 16px Roboto, sans-serif',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		marginTop: '20px',
		position: 'relative',
		'&:hover': {
			opacity: 0.9,
			background: 'linear-gradient(180deg, #d00 0%, #800 100%)'
		},
		'&:focus': {
			outline: '2px solid #fff',
			outlineOffset: '2px'
		}
	},
	parallaxContainer: {
		'& > div': {
			pointerEvents: 'none'
		},
		'& > div > div': {
			pointerEvents: 'auto'
		}
	}
}));

export default function PricingPage() {
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const { hasAccess } = useProductAccess('all-courses');
	const classes = useStyles();

	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});

	const handleViewCoursesClick = (e) => {
		e.preventDefault();
		if (!user) {
			history.push('/all-courses');
		} else if (hasAccess) {
			history.push('/courses');
		} else {
			history.push('/all-courses');
		}
	};

	return (
		<div>
			<Header
				image={require('assets/img/logo.png')}
				brand={
					<img src={logo} alt='logo' style={{ width: 'auto', height: '4rem' }} />
				}
				links={<HeaderLinks dropdownHoverColor='info' />}
				fixed
				color='transparent'
				changeColorOnScroll={{
					height: 300,
				}}
			/>

			<Parallax
				image={require('assets/img/hectorClassroom.png')}
				filter='dark'
				className={classes.parallaxContainer}
				style={{ pointerEvents: 'none' }}
			>
				<div className={classes.container}>
					<GridContainer>
						<GridItem
							md={8}
							sm={8}
							className={classNames(
								classes.mlAuto,
								classes.mrAuto,
								classes.textCenter
							)}
						>
							<h1 className={classes.title}>Work with me 1-on-1</h1>
							<h4>
								The fastest way to reaching your dating and relationship goals is through 1-on-1
								coaching. Video courses and books are great, but you can't beat having a lesson
								tailed just for you, your personality, and your needs. And I've helped men from
								all walks of life – from billion-dollar fund managers to programmers and even a
								Navy SEAL – so I'll have no issues crafting the perfect plan for you.
							</h4>
							{/* <button
								className={classes.viewCoursesButton}
								onClick={handleViewCoursesClick}
								type="button"
							>
								View Courses
							</button> */}
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.container}>
					{/* <SectionPricing /> */}
					<hr />
					<SectionWork />
					<hr />
					<SectionFeatures />
				</div>
			</div>
			<Footer
				content={
					<div>
						<div className={classes.left}>
							<List className={classes.list}>
								{/* Removed About and Philosophy links */}
							</List>
						</div>
						<div className={classes.right}>
							&copy; {1900 + new Date().getYear()} , made with{' '}
							<Favorite className={classes.icon} /> by{' '}
							<a
								href='https://www.dsignable.com'
								target='_blank'
								rel='noopener noreferrer'
							>
								Dsignable
							</a>{' '}
							for a better web.
						</div>
					</div>
				}
			/>
		</div>
	);
}
