import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { ChapterHeader } from "./components/ChapterHeader";
import { ModuleContent } from "./components/ModuleContent";
import logo from 'assets/img/logo.png';
import { getFirestore, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import AuthContext from 'contexts/AuthContext';
import Rodal from 'rodal';
import { Button, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import classroom from "assets/img/hectorClassroom.png";
import { getAuth } from "firebase/auth";

const useStyles = makeStyles({
  pageWrapper: {
    backgroundColor: 'rgba(175, 175, 175, 1)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    fontFamily: 'Roboto Slab, sans-serif',
    '& *': {
      fontFamily: 'Roboto Slab, sans-serif',
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: '832px',
    width: '100%',
    padding: '120px 0 25px',
    zIndex: 2,
    alignItems: 'center',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      padding: '140px 0 25px'
    },
    '@media (max-width: 768px)': {
      padding: '130px 0 20px',
      minHeight: 'auto'
    }
  },
  backgroundImage: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    zIndex: 0,
    filter: 'blur(2px)'
  },
  overlay: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1
  },
  chapterContainer: {
    display: 'flex',
    alignItems: 'start',
    gap: '50px 48px',
    fontFamily: 'Roboto Slab, sans-serif',
    justifyContent: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    zIndex: 3,
    width: '100%',
    maxWidth: '1013px'
  },
  chapterImage: {
    aspectRatio: '16/9',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    maxWidth: '1013px',
    borderRadius: '15px',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
    minWidth: '240px',
    flexGrow: 1,
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    }
  },
  moduleContent: {
    position: 'relative',
    zIndex: 3,
    width: '100%',
    maxWidth: '1013px',
    display: 'flex',
    justifyContent: 'center'
  },
  navigationWrapper: {
    display: 'flex',
    minWidth: '240px',
    alignItems: 'center',
    gap: '40px 100px',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexGrow: 1,
    width: '100%',
    maxWidth: '1013px',
    padding: '0 20px',
    position: 'relative',
    zIndex: 3,
    marginTop: '20px',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      padding: '0 20px'
    }
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    '@media (max-width: 768px)': {
      height: '50vh'
    }
  },
  welcomeModal: {
    padding: '20px',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      padding: '15px'
    }
  },
  welcomeTitle: {
    fontSize: '24px',
    marginBottom: '20px',
    fontWeight: 'bold',
    '@media (max-width: 768px)': {
      fontSize: '20px',
      marginBottom: '15px'
    }
  },
  welcomeText: {
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '20px',
    '@media (max-width: 768px)': {
      fontSize: '14px',
      marginBottom: '15px'
    }
  },
  welcomeButton: {
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '20px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 600,
    '@media (max-width: 768px)': {
      padding: '8px 16px',
      fontSize: '14px'
    }
  },
  viewAllButton: {
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    padding: '12px 24px',
    borderRadius: '25px',
    border: 'none',
    cursor: 'pointer',
    marginTop: '40px',
    alignSelf: 'center',
    fontSize: '16px',
    fontWeight: 600,
    position: 'relative',
    zIndex: 3,
    fontFamily: 'Roboto Slab, sans-serif',
    '&:hover': {
      background: 'linear-gradient(180deg, #d00 0%, #700 100%)',
    }
  },
  chapterVideo: {
    aspectRatio: '16/9',
    width: '100%',
    maxWidth: '1013px',
    borderRadius: '15px',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: 'black',
    minWidth: '240px',
    flexGrow: 1,
    '& iframe': {
      width: '100%',
      height: '100%',
      borderRadius: '15px'
    },
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    },
    '@media (max-width: 767px)': {
      aspectRatio: '4/3',
      minHeight: '240px'
    }
  },
  tableOfContentsButton: {
    position: 'fixed',
    bottom: '2rem',
    left: '2rem',
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    padding: '12px 24px',
    borderRadius: '25px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Roboto Slab, sans-serif',
    zIndex: 9999,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      background: 'linear-gradient(180deg, #d00 0%, #700 100%)',
      transform: 'scale(1.05)'
    },
    '@media (max-width: 768px)': {
      bottom: '1.5rem',
      left: '1.5rem',
      padding: '10px 20px',
      fontSize: '14px'
    }
  },
  completionDialog: {
    '& .MuiDialog-paper': {
      borderRadius: '15px',
      padding: '20px',
      maxWidth: '500px',
    },
  },
  dialogTitle: {
    color: '#f00',
    fontFamily: 'Roboto Slab, sans-serif',
    textAlign: 'center',
  },
  dialogContent: {
    fontFamily: 'Roboto Slab, sans-serif',
    padding: '20px',
    textAlign: 'center',
  },
  dialogActions: {
    padding: '20px',
    justifyContent: 'center',
    gap: '10px',
  },
  actionButton: {
    borderRadius: '25px',
    padding: '10px 30px',
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    '&.proceed': {
      background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
      color: 'white',
    },
  },
});

export default function CourseLesson() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [showWelcome, setShowWelcome] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
  const [currentModule, setCurrentModule] = useState(null);
  const history = useHistory();
  const { courseId } = useParams();
  const location = useLocation();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const chapterIndex = parseInt(params.get('chapter')) || 0;
    setCurrentChapterIndex(chapterIndex);
  }, [location]);

  useEffect(() => {
    const checkNewPurchase = async () => {
      if (!user) return;

      const db = getFirestore();
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists() && userDoc.data().newPurchase === true) {
        setShowWelcome(true);
        await updateDoc(userRef, {
          newPurchase: false
        });
      }
    };

    checkNewPurchase();
  }, [user]);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const db = getFirestore();
        const courseRef = doc(db, 'courses', courseId);
        const courseSnap = await getDoc(courseRef);
        
        if (courseSnap.exists()) {
          const courseData = { id: courseSnap.id, ...courseSnap.data() };
          setCourse(courseData);
          if (courseData.chapters?.length > 0) {
            setSelectedChapter(courseData.chapters[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching course:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [courseId]);

  const handleModuleNavigation = (direction) => {
    const currentChapter = course.chapters[currentChapterIndex];
    if (direction === 'next') {
      if (currentModuleIndex < currentChapter.modules.length - 1) {
        setCurrentModuleIndex(prev => prev + 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (currentChapterIndex < course.chapters.length - 1) {
        // Directly navigate to next chapter
        history.push(`/course/${courseId}/lesson?chapter=${currentChapterIndex + 1}`);
      }
    } else {
      if (currentModuleIndex > 0) {
        setCurrentModuleIndex(prev => prev - 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (currentChapterIndex > 0) {
        const previousChapter = course.chapters[currentChapterIndex - 1];
        setCurrentChapterIndex(prev => prev - 1);
        setCurrentModuleIndex(previousChapter.modules.length - 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    if (course && currentChapterIndex >= 0 && currentModuleIndex >= 0) {
      const chapter = course.chapters[currentChapterIndex];
      if (chapter && chapter.modules && chapter.modules[currentModuleIndex]) {
        // Force a re-render when module changes
        setCurrentModule(chapter.modules[currentModuleIndex]);
      }
    }
  }, [currentChapterIndex, currentModuleIndex, course]);

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  if (!course) {
    return (
      <div className={classes.content}>
        <Typography variant="h5">Course not found</Typography>
      </div>
    );
  }

  const currentChapter = course.chapters[currentChapterIndex];
  const isLastModuleInChapter = currentModule && currentModuleIndex === currentChapter.modules.length - 1;

  return (
    <div className={classes.pageWrapper}>
      <Header
        color="transparent"
        brand={<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem', zIndex: 9999 }} />}
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: 'white',
        }}
      />
      <div className={classes.contentContainer}>
        <img
          className={classes.backgroundImage}
          src={currentChapter?.imageUrl || classroom}
          alt="background"
        />
        <div className={classes.overlay} />
        
        <div className={classes.chapterContainer}>
          <ChapterHeader chapter={currentChapter} />
          
          {currentChapter?.videoUrl ? (
            currentChapter.videoUrl.includes('youtube.com') || currentChapter.videoUrl.includes('youtu.be') ? (
              <div className={classes.chapterVideo}>
                <iframe
                  src={currentChapter.videoUrl.replace('watch?v=', 'embed/')}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : (
              <video
                className={classes.chapterVideo}
                controls
                src={currentChapter.videoUrl}
                poster={currentChapter?.imageUrl}
              >
                Your browser does not support the video tag.
              </video>
            )
          ) : (
            <img
              className={classes.chapterImage}
              src={currentChapter?.imageUrl || classroom}
              alt="Chapter overview illustration"
            />
          )}
          
          <div className={classes.moduleContent}>
            <ModuleContent 
              chapter={currentChapter}
              module={currentModule}
              onNavigate={handleModuleNavigation}
              courseId={courseId}
            />
          </div>
        </div>
      </div>

      <Button
        className={classes.tableOfContentsButton}
        onClick={() => history.push(`/course/${courseId}/overview`)}
      >
        Table of Contents
      </Button>

      <Rodal visible={showWelcome} onClose={() => setShowWelcome(false)} height={400}>
        <div className={classes.welcomeModal}>
          <h2 className={classes.welcomeTitle}>Welcome to {course.title}!</h2>
          <p className={classes.welcomeText}>
            Thank you for purchasing the course! You now have full access to all modules 
            and content. Get ready to transform your dating life with proven strategies 
            and insights.
          </p>
          <p className={classes.welcomeText}>
            Start with Chapter I and work your way through each module. Take your time 
            to practice and implement what you learn.
          </p>
          <Button 
            onClick={() => setShowWelcome(false)}
            className={classes.welcomeButton}
          >
            Let's Begin!
          </Button>
        </div>
      </Rodal>
    </div>
  );
} 