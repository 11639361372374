import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.02)'
    }
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '20px'
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0) 100%)',
    padding: '2rem',
    borderRadius: '0 0 20px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem'
  },
  title: {
    color: 'white',
    fontSize: '2rem',
    fontWeight: 700,
    margin: 0,
    fontFamily: 'Roboto Slab, sans-serif',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
    '@media (max-width: 768px)': {
      fontSize: '1.5rem'
    }
  },
  description: {
    color: 'rgba(255,255,255,0.9)',
    fontSize: '1.1rem',
    margin: '0.5rem 0',
    maxWidth: '80%',
    lineHeight: 1.4,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '4.5em',
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      height: '4.2em'
    }
  },
  moduleCount: {
    color: 'rgba(255,255,255,0.8)',
    fontSize: '1rem',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem'
    }
  },
  icon: {
    width: '1.2rem',
    height: '1.2rem',
    marginRight: '0.3rem'
  }
}));

export const ChapterCard = ({ title, image, description, moduleCount, isFocused, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.card} onClick={onClick}>
      <img 
        src={image || 'https://via.placeholder.com/800x450'} 
        alt={title} 
        className={classes.image}
      />
      <div className={classes.overlay}>
        {isFocused && (
          <>
            {/* <h3 className={classes.title}>{title}</h3> */}
            {/* {description && (
              <p className={classes.description}>{description}</p>
            )} */}
            {/* <p className={classes.moduleCount}>
              <span role="img" aria-label="module" className={classes.icon}>📚</span>
              {moduleCount} {moduleCount === 1 ? 'Module' : 'Modules'}
            </p> */}
          </>
        )}
      </div>
    </div>
  );
}; 