import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  Grid,
  Card,
  CardContent,
  Divider,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import { getFirestore, doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    marginTop: '20px'
  },
  section: {
    backgroundColor: 'rgba(217, 217, 217, 0.5)',
    borderRadius: '10px',
    padding: '20px',
    marginBottom: '20px'
  },
  sectionTitle: {
    color: 'white',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '20px'
  },
  formField: {
    marginBottom: '16px',
    '& .MuiInputBase-root': {
      color: 'white'
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.23)'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.5)'
      }
    }
  },
  saveButton: {
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    marginTop: '20px',
    '&:hover': {
      background: 'linear-gradient(180deg, #f00 0%, #800 100%)'
    }
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    margin: '20px 0'
  },
  switchLabel: {
    color: 'white'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px'
  },
  statsCard: {
    backgroundColor: 'rgba(217, 217, 217, 0.3)',
    color: 'white',
    height: '100%'
  },
  statsValue: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '8px'
  },
  statsLabel: {
    fontSize: '1rem',
    color: 'rgba(255, 255, 255, 0.7)'
  }
}));

export function SiteManagementPanel() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [siteSettings, setSiteSettings] = useState({
    maintenanceMode: false,
    welcomeMessage: '',
    contactEmail: '',
    socialLinks: {
      facebook: '',
      twitter: '',
      instagram: ''
    }
  });
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    totalRevenue: 0,
    courseCompletions: 0
  });
  const [emailTestData, setEmailTestData] = useState({
    destinationEmail: '',
    emailType: 'coursePurchase'
  });
  const [emailTestStatus, setEmailTestStatus] = useState({ loading: false, error: null, success: false });

  useEffect(() => {
    // Connect to functions emulator
    try {
      const functions = getFunctions();
      if (process.env.NODE_ENV === 'development') {
        connectFunctionsEmulator(functions, 'localhost', 5001);
        console.log('Connected to Firebase Functions emulator');
      }
    } catch (error) {
      console.error('Error connecting to Functions emulator:', error);
    }
    
    fetchSiteSettings();
    fetchSiteStats();
  }, []);

  const fetchSiteSettings = async () => {
    try {
      const db = getFirestore();
      const settingsDoc = await getDoc(doc(db, 'settings', 'site'));
      if (settingsDoc.exists()) {
        setSiteSettings(settingsDoc.data());
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching site settings:', error);
      setLoading(false);
    }
  };

  const fetchSiteStats = async () => {
    try {
      const db = getFirestore();
      
      // Get total users
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const totalUsers = usersSnapshot.size;
      
      // Get active users (users who have logged in within the last 30 days)
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const activeUsers = usersSnapshot.docs.filter(doc => 
        doc.data().lastLoginAt?.toDate() > thirtyDaysAgo
      ).length;

      // Calculate total revenue and course completions
      let totalRevenue = 0;
      let courseCompletions = 0;
      usersSnapshot.docs.forEach(doc => {
        const userData = doc.data();
        if (userData.payments) {
          totalRevenue += userData.payments.reduce((sum, payment) => sum + payment.amount, 0);
        }
        if (userData.completedCourses) {
          courseCompletions += userData.completedCourses.length;
        }
      });

      setStats({
        totalUsers,
        activeUsers,
        totalRevenue,
        courseCompletions
      });
    } catch (error) {
      console.error('Error fetching site stats:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setSiteSettings(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setSiteSettings(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setSiteSettings(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleSaveSettings = async () => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'settings', 'site'), siteSettings);
      alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Error saving settings. Please try again.');
    }
  };

  const handleEmailTestInputChange = (e) => {
    setEmailTestData({
      ...emailTestData,
      [e.target.name]: e.target.value
    });
  };

  const handleTestEmail = async () => {
    setEmailTestStatus({ loading: true, error: null, success: false });
    try {
        console.log('Sending test email with data:', emailTestData);
        
        // Get the current user's ID token
        const auth = getAuth();
        if (!auth.currentUser) {
            throw new Error('You must be logged in to send test emails');
        }
        const idToken = await auth.currentUser.getIdToken();
        
        // Determine the base URL based on environment
        const baseUrl = process.env.NODE_ENV === 'development'
            ? 'http://localhost:5001/project-hector-6eaf1/us-central1'
            : 'https://us-central1-project-hector-6eaf1.cloudfunctions.net';
        
        console.log('Making request to:', `${baseUrl}/testEmail`);
        
        const response = await fetch(`${baseUrl}/testEmail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify(emailTestData)
        });

        const data = await response.json();
        console.log('Response:', data);
        
        if (response.ok) {
            setEmailTestStatus({ loading: false, error: null, success: true });
        } else {
            throw new Error(data.error || 'Failed to send test email');
        }
    } catch (error) {
        console.error('Error sending test email:', error);
        setEmailTestStatus({ 
            loading: false, 
            error: error.message || 'Failed to send test email', 
            success: false 
        });
    }
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.statsCard}>
            <CardContent>
              <Typography className={classes.statsValue}>
                {stats.totalUsers}
              </Typography>
              <Typography className={classes.statsLabel}>
                Total Users
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.statsCard}>
            <CardContent>
              <Typography className={classes.statsValue}>
                {stats.activeUsers}
              </Typography>
              <Typography className={classes.statsLabel}>
                Active Users
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.statsCard}>
            <CardContent>
              <Typography className={classes.statsValue}>
                ${stats.totalRevenue.toLocaleString()}
              </Typography>
              <Typography className={classes.statsLabel}>
                Total Revenue
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.statsCard}>
            <CardContent>
              <Typography className={classes.statsValue}>
                {stats.courseCompletions}
              </Typography>
              <Typography className={classes.statsLabel}>
                Course Completions
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <div className={classes.section}>
        <Typography className={classes.sectionTitle}>
          Site Settings
        </Typography>
        
        <FormControlLabel
          control={
            <Switch
              checked={siteSettings.maintenanceMode}
              onChange={handleSwitchChange}
              name="maintenanceMode"
              color="primary"
            />
          }
          label="Maintenance Mode"
          className={classes.switchLabel}
        />

        <TextField
          className={classes.formField}
          label="Welcome Message"
          name="welcomeMessage"
          value={siteSettings.welcomeMessage}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          margin="normal"
        />

        <TextField
          className={classes.formField}
          label="Contact Email"
          name="contactEmail"
          value={siteSettings.contactEmail}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          margin="normal"
        />
      </div>

      <div className={classes.section}>
        <Typography className={classes.sectionTitle}>
          Social Media Links
        </Typography>
        
        <TextField
          className={classes.formField}
          label="Facebook URL"
          name="socialLinks.facebook"
          value={siteSettings.socialLinks.facebook}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          margin="normal"
        />

        <TextField
          className={classes.formField}
          label="Twitter URL"
          name="socialLinks.twitter"
          value={siteSettings.socialLinks.twitter}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          margin="normal"
        />

        <TextField
          className={classes.formField}
          label="Instagram URL"
          name="socialLinks.instagram"
          value={siteSettings.socialLinks.instagram}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          margin="normal"
        />
      </div>

      <div className={classes.section}>
        <Typography className={classes.sectionTitle}>
          Email Testing
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              className={classes.formField}
              fullWidth
              label="Destination Email"
              name="destinationEmail"
              value={emailTestData.destinationEmail}
              onChange={handleEmailTestInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              className={classes.formField}
              fullWidth
              select
              label="Email Type"
              name="emailType"
              value={emailTestData.emailType}
              onChange={handleEmailTestInputChange}
              variant="outlined"
              SelectProps={{
                native: true
              }}
            >
              <option value="coursePurchase">Course Purchase Email</option>
              <option value="paymentSuccess">Coaching Payment Success</option>
              <option value="coachingForm">Coaching Form Submission</option>
              <option value="contactForm">Support Form Submission</option>
            </TextField>
          </Grid>
        </Grid>
        <Button
          className={classes.saveButton}
          onClick={handleTestEmail}
          disabled={emailTestStatus.loading || !emailTestData.destinationEmail}
        >
          {emailTestStatus.loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Send Test Email'
          )}
        </Button>
        {emailTestStatus.error && (
          <Typography style={{ color: 'red', marginTop: '10px' }}>
            Error: {emailTestStatus.error}
          </Typography>
        )}
        {emailTestStatus.success && (
          <Typography style={{ color: 'green', marginTop: '10px' }}>
            Test email sent successfully!
          </Typography>
        )}
      </div>

      <Button
        variant="contained"
        className={classes.saveButton}
        onClick={handleSaveSettings}
        fullWidth
      >
        Save Settings
      </Button>
    </Paper>
  );
} 