import React, { useContext, useState } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Camera from '@material-ui/icons/Camera';
import People from '@material-ui/icons/People';
// core components
import Header from 'components/Header/Header.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import NavPills from 'components/NavPills/NavPills.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Muted from 'components/Typography/Muted.js';
import Parallax from 'components/Parallax/Parallax.js';
import Clearfix from 'components/Clearfix/Clearfix.js';
import Checkbox from '@material-ui/core/Checkbox';

import logo from 'assets/img/logo.png';
import defaultAvatar from 'assets/img/placeholder.jpg';
import Table from 'components/Table/Table.js';
import Check from '@material-ui/icons/Check';
import AuthContext from 'contexts/AuthContext';
import Footer from 'components/Footer/Footer.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/profilePageStyle.js';
import { getFile } from 'api/firebaseApi';
import Favorite from '@material-ui/icons/Favorite';
import wsrm from 'assets/img/wsrm.jpg';
import mge from 'assets/img/mge.png';
import ldlc from 'assets/img/ldlc.png';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { styled } from '@material-ui/core/styles';
import { IconButton, CircularProgress } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import Button from 'components/CustomButtons/Button.js';

const useStyles = makeStyles(profilePageStyle);

const ImageUploadWrapper = styled('div')({
	position: 'relative',
	width: '160px',
	height: '160px',
	margin: '-90px auto 0',
	borderRadius: '50%',
	overflow: 'hidden',
	boxShadow: '0 16px 38px -12px rgba(0, 0, 0, 0.56)',
});

const ImageContainer = styled('div')({
	position: 'relative',
	width: '100%',
	height: '100%',
	marginTop: '90px',
	cursor: 'pointer',
	'&:hover .upload-overlay': {
		opacity: 1
	}
});

const ProfileImage = styled('img')({
	width: '100%',
	height: '100%',
	objectFit: 'cover',
	borderRadius: '50%'
});

const UploadOverlay = styled('div')({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(0, 0, 0, 0.5)',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	opacity: 0,
	margin: '-90px auto 0',
	transition: 'opacity 0.3s ease',
	borderRadius: '50%'
});

export default function ProfilePage() {
	const { user, getUserData } = useContext(AuthContext);
	const [uploading, setUploading] = useState(false);
	const [profileImage, setProfileImage] = useState(defaultAvatar);

	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		getUserData();
	}, []);

	React.useEffect(() => {
		if (user?.photoURL) {
			setProfileImage(user.photoURL);
		}
	}, [user]);

	const handleImageUpload = async (event) => {
		const file = event.target.files[0];
		if (!file) return;

		try {
			setUploading(true);
			const storage = getStorage();
			const imageRef = ref(storage, `user-photos/${user.uid}/${file.name}`);
			await uploadBytes(imageRef, file);
			const imageUrl = await getDownloadURL(imageRef);
			
			// Update user document in Firestore
			const db = getFirestore();
			const userRef = doc(db, 'users', user.uid);
			await updateDoc(userRef, {
				photoURL: imageUrl
			});

			setProfileImage(imageUrl);
			await getUserData(); // Refresh user data
		} catch (error) {
			console.error('Error uploading image:', error);
		} finally {
			setUploading(false);
		}
	};

	const handlePasswordReset = async () => {
		try {
			const auth = getAuth();
			await sendPasswordResetEmail(auth, user.email);
			console.log('Password reset email sent to ', user.email);
			// You can add a notification here if you have a notification system
			alert('Password reset email sent! Please check your inbox.');
		} catch (error) {
			console.error('Error sending password reset email:', error);
			alert('Failed to send password reset email. Please try again.');
		}
	};

	const classes = useStyles();
	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgRoundedCircle,
		classes.imgFluid
	);

	const downloadFile = async (product) => {
		try {
			const url = await getFile(product.fileUrl);
			window.open(url, '_blank');
		} catch (error) {
			window.open(product.fileUrl, '_blank');
		}
	};
	const formattedDate = (createdAt) => {
		const date = new Date(createdAt);
		return date.toLocaleDateString('en-US', {
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		});
	};

	const imageMap = {
		mge: mge,
		wsrm: wsrm,
		ldlc: ldlc,
	};

	return (
		<div>
			<Header
				image={require('assets/img/logo.png')}
				brand={
					<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem' }} />
				}
				links={<HeaderLinks dropdownHoverColor="rose" />}
				fixed
				color="transparent"
				changeColorOnScroll={{
					height: 100,
				}}
			/>
			<Parallax
				image={require('assets/img/examples/city.jpg')}
				filter="dark"
				className={classes.parallax}
			/>

			<div
				className={classNames(
					classes.main,
					classes.mainRaised,
					classes.mainContainer
				)}
			>
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem xs={12} sm={12} md={6}>
							<div className={classes.profile}>
								<ImageUploadWrapper>
									<input
										type="file"
										id="profile-photo-upload"
										accept="image/*"
										style={{ display: 'none' }}
										onChange={handleImageUpload}
									/>
									<label htmlFor="profile-photo-upload" style={{ width: '100%', height: '100%', display: 'block' }}>
										<ImageContainer>
											<ProfileImage src={profileImage} alt="Profile" />
											<UploadOverlay className="upload-overlay">
												{uploading ? (
													<CircularProgress size={40} style={{ color: 'white' }} />
												) : (
													<PhotoCamera style={{ color: 'white', fontSize: '40px' }} />
												)}
											</UploadOverlay>
										</ImageContainer>
									</label>
								</ImageUploadWrapper>
								<div style={{ 
									textAlign: 'center',
									padding: '20px 0',
									color: '#3C4858'
								}}>
									<h3 style={{
										fontSize: '1.5625rem',
										fontWeight: '700',
										marginBottom: '10px'
									}}>
										{user?.displayName || 'Welcome!'}
									</h3>
									<h6 style={{
										fontSize: '1rem',
										fontWeight: '400',
										color: '#999',
										marginBottom: '20px'
									}}>
										{user?.email}
									</h6>
									<Button
										style={{
											background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
											color: 'white',
											padding: '12px 30px',
											borderRadius: '25px',
											boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
											transition: 'all 0.2s ease',
											'&:hover': {
												opacity: 0.9,
												boxShadow: '0 4px 8px rgba(0,0,0,0.3)'
											}
										}}
										onClick={handlePasswordReset}
									>
										Reset Password
									</Button>
								</div>
							</div>
						</GridItem>
					</GridContainer>
					<div className={classes.profileTabs}>
						<NavPills
							alignCenter
							color="primary"
							tabs={[
								{
									tabButton: 'Products',
									tabIcon: Camera,
									tabContent: (
										<div style={{ 
											// background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
											padding: '20px',
											borderRadius: '15px',
											marginTop: '20px'
										}}>
											<GridContainer justify="center">
												{user?.productsOwned &&
													user?.productsOwned
														.filter((item) => !item.isCoaching)
														?.map((product, i) => (
															<GridItem xs={12} sm={12} md={6} key={i}>
																<Card
																	profile
																	plain
																	className={classes.card}
																	onClick={() => downloadFile(product)}
																>
																	<GridContainer>
																		<GridItem xs={12} sm={12} md={5}>
																			<CardHeader image plain>
																				<a href="#pablo">
																					{
																						<img
																							src={imageMap[product.id]}
																							alt="Product"
																							className={classes.smallerImage}
																						/>
																					}
																				</a>
																				<div
																					className={classes.coloredShadow}
																					style={{
																						backgroundImage: `url(${imageMap[product.id]})`,
																						opacity: '1',
																					}}
																				/>
																			</CardHeader>
																		</GridItem>
																		<GridItem xs={12} sm={12} md={7}>
																			<CardBody plain>
																				<h4 className={classes.cardTitle}>{product.name}</h4>
																				<Muted>
																					<h6>{product.model}</h6>
																				</Muted>
																				<p className={classes.description}>{product.description}</p>
																			</CardBody>
																		</GridItem>
																	</GridContainer>
																</Card>
															</GridItem>
														))}
											</GridContainer>
										</div>
									),
								},
							]}
						/>
					</div>

					<Clearfix />
				</div>
			</div>
			<Footer
				className={classes.footer}
				content={
					<div>
	
						<div className={classes.right}>
							&copy; {1900 + new Date().getYear()} , made with{' '}
							<Favorite className={classes.icon} /> by{' '}
							<a href="https://www.dsignable.com" target="_blank" rel="noopener noreferrer">
								Dsignable
							</a>{' '}
							for a better web
						</div>
					</div>
				}
			/>
		</div>
	);
}
