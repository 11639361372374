import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import { toast, Toaster } from 'react-hot-toast';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import simp from 'assets/img/simp.jpg';

const useStyles = makeStyles((theme) => ({
	section: {
		padding: '90px 0',
		textAlign: 'center',
		minHeight: '100vh',
		marginTop: '0px',
		backgroundImage: `url(${simp})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundAttachment: 'fixed',
		position: 'relative',
		'&:before': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			zIndex: 1
		}
	},
	content: {
		position: 'relative',
		zIndex: 2,
		color: '#fff'
	},
	title: {
		marginBottom: '1rem',
		marginTop: '30px',
		minHeight: '32px',
		textDecoration: 'none',
		fontSize: '2.25rem',
		fontFamily: '"Roboto Slab", "Times New Roman", serif',
		color: '#fff'
	},
	description: {
		color: 'rgba(255, 255, 255, 0.8)',
		marginBottom: '3rem',
		textAlign: 'center',
	},
	textArea: {
		marginRight: '15px',
		marginLeft: '15px',
	},
	customButtonWidth: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	mlAuto: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	formWrapper: {
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		borderRadius: '8px',
		padding: '30px',
		marginTop: '20px'
	}
}));

export default function SupportPage() {
	const classes = useStyles();
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			message: '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Please enter your name'),
			email: Yup.string()
				.email('Invalid email address')
				.required('Please enter your email'),
			message: Yup.string().required('Please enter your message'),
		}),
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				const payload = {
					childKey: {
						name: values.name,
						email: values.email,
						message: values.message,
					},
				};

				window.grecaptcha.ready(async () => {
					const token = await window.grecaptcha.execute(
						process.env.REACT_APP_RECAPTCHA_SITE_KEY,
						{
							action: 'submit',
						}
					);

					const res = await axios
						.post(`${process.env.REACT_APP_API_URL}/sendContactEmail`, {
							token,
							name: values.name,
							email: values.email,
							message: values.message,
							isSupport: true
						})
						.catch(() => {
							toast.error('Message failed to send');
							return false;
						});
					if (res) toast.success('Message sent successfully!');
				});

				setShowSuccessMessage(true);
				resetForm();
			} catch (error) {
				console.error(error);
			} finally {
				setSubmitting(false);
			}
		},
	});

	return (
		<div>
			<Header
				brand="Hector Castillo"
				links={<HeaderLinks dropdownHoverColor="info" />}
				fixed
				color="transparent"
				changeColorOnScroll={{
					height: 0,
					color: "white"
				}}
			/>
			<div className={classes.section}>
				<div className={classes.content}>
					<Toaster position='bottom-center' />
					<GridContainer justify='center'>
						<GridItem cs={12} sm={8} md={8}>
							<h2 className={classes.title}>Need Help?</h2>
							<h4 className={classes.description}>
								If you're having any issues with the website or need to contact me for anything, 
								feel free to use the submission form below to get in contact with me and I'll get 
								back to you as soon as possible.
							</h4>
							<div className={classes.formWrapper}>
								{showSuccessMessage ? (
									<div style={{
										padding: '20px',
										backgroundColor: 'rgba(76, 175, 80, 0.1)',
										border: '1px solid #4CAF50',
										borderRadius: '10px',
										marginBottom: '20px'
									}}>
										<h4 style={{ color: '#4CAF50', marginBottom: '10px', fontWeight: 'bold' }}>Success! Your message has been sent.</h4>
										<p style={{ fontSize: '16px', lineHeight: '1.5', color: 'black' }}>
											Thank you for contacting support! We have received your message and will get back to you as soon as possible.
											Our team is dedicated to providing you with the best assistance for any questions or concerns you may have.
										</p>
									</div>
								) : (
									<form
										onSubmit={formik.handleSubmit}
										id='support-form'
										action='?'
										method='POST'
									>
										<GridContainer>
											<GridItem xs={12} sm={6} md={6}>
												<CustomInput
													labelText='Your Name'
													id='name'
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														value: formik.values.name,
														onChange: formik.handleChange,
														onBlur: formik.handleBlur,
													}}
													error={Boolean(formik.touched.name) && Boolean(formik.errors.name)}
													helperText={formik.touched.name && formik.errors.name}
												/>
											</GridItem>
											<GridItem xs={12} sm={6} md={6}>
												<CustomInput
													labelText='Your Email'
													id='email'
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														value: formik.values.email,
														onChange: formik.handleChange,
														onBlur: formik.handleBlur,
													}}
													error={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
													helperText={formik.touched.email && formik.errors.email}
												/>
											</GridItem>
											<CustomInput
												labelText='Your Message'
												id='message'
												formControlProps={{
													fullWidth: true,
													className: classes.textArea,
												}}
												inputProps={{
													multiline: true,
													rows: 5,
													value: formik.values.message,
													onChange: formik.handleChange,
													onBlur: formik.handleBlur,
												}}
												error={
													Boolean(formik.touched.message) && Boolean(formik.errors.message)
												}
												helperText={formik.touched.message && formik.errors.message}
											/>
											<GridItem
												xs={12}
												sm={12}
												md={12}
												className={classes.customButtonWidth + ' ' + classes.mlAuto}
											>
												<br></br>
												<Button
													data-sitekey='6Ler840mAAAAAE9EagyASQizl47aumA-LmuR5QUa'
													data-callback='onSubmit'
													position='center'
													fullWidth
													color='youtube'
													type='submit'
													disabled={formik.isSubmitting}
												>
													Send Message
												</Button>
											</GridItem>
										</GridContainer>
									</form>
								)}
							</div>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
} 