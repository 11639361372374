import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { ChapterCard } from "./ChapterCard";
import { DownloadableCard } from "./DownloadableCard";
import logo from 'assets/img/logo.png';
import { Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    backgroundColor: 'rgba(175, 175, 175, 1)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: '100vh',
    width: '100%',
    padding: '6.25rem 0 1.5625rem',
    '@media (max-width: 1440px)': {
      padding: '5rem 0 1.5625rem'
    },
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    }
  },
  backgroundImage: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  overlay: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1
  },
  courseTitle: {
    position: 'relative',
    alignSelf: 'center',
    display: 'flex',
    marginTop: '4rem',
    width: '100%',
    maxWidth: '56.25rem',
    alignItems: 'center',
    gap: '0.625rem',
    fontFamily: 'Roboto Slab, sans-serif',
    color: 'rgba(255, 255, 255, 1)',
    textAlign: 'center',
    flexWrap: 'wrap',
    zIndex: 2,
    '@media (max-width: 1440px)': {
      marginTop: '3rem'
    },
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      padding: '0 1.25rem'
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      padding: '1rem 0',
      gap: '1rem',
    }
  },
  navigationArrow: {
    width: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'transform 0.2s ease',
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    borderRadius: '50%',
    padding: '0.5rem',
    margin: '0 1rem',
    '&:hover': {
      transform: 'scale(1.1)',
      background: 'linear-gradient(180deg, #d00 0%, #700 100%)'
    },
    '& svg': {
      width: '100%',
      height: '100%',
      color: '#fff'
    },
    '@media (max-width: 768px)': {
      width: '40px',
      height: '40px',
    }
  },
  titleContent: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    },
    '@media (max-width: 768px)': {
      order: -1,
    }
  },
  mainTitle: {
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: '3.125rem',
    letterSpacing: '0.1em',
    margin: 0,
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    paddingBottom: '1.5rem',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      fontSize: '2.5rem'
    },
    '@media (max-width: 768px)': {
      fontSize: '1.8rem',
    },
    '@media (max-width: 480px)': {
      fontSize: '1.5rem',
    }
  },
  subTitle: {
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: '1.5625rem',
    letterSpacing: '0.1em',
    alignSelf: 'center',
    margin: '0.3125rem 0 0',
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 500,
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    },
    '@media (max-width: 768px)': {
      fontSize: '1.2rem',
    },
    '@media (max-width: 480px)': {
      fontSize: '1rem',
    }
  },
  focusedDescription: {
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: '1.125rem',
    letterSpacing: '0.1em',
    alignSelf: 'center',
    margin: '.5rem 0 0',
    maxWidth: '40rem',
    lineHeight: 1.6,
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    color: 'rgba(255, 255, 255, 0.9)',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '4.5em',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      padding: '0 1.25rem',
      fontSize: '1rem',
      height: '4.2em'
    }
  },
  carousel: {
    position: 'relative',
    width: '100%',
    height: '600px',
    margin: '2rem 0',
    zIndex: 2,
    '@media (max-width: 1440px)': {
      height: '500px',
      margin: '1.5rem 0'
    },
    '@media (max-width: 991px)': {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '0 1rem'
    }
  },
  carouselTrack: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 991px)': {
      flexDirection: 'column',
      gap: '1rem'
    }
  },
  chapterPosition: {
    position: 'absolute',
    left: '50%',
    transition: 'all 0.3s ease',
    '@media (max-width: 991px)': {
      position: 'relative',
      left: 'auto',
      transform: 'none !important',
      opacity: '1 !important',
      width: '100%'
    }
  },
  leftPosition: {
    transform: 'translateX(-150%)',
    opacity: 1,
    zIndex: 2
  },
  centerPosition: {
    transform: 'translateX(-50%)',
    opacity: 1,
    zIndex: 3
  },
  rightPosition: {
    transform: 'translateX(50%)',
    opacity: 1,
    zIndex: 2
  },
  singleChapterWrapper: {
    width: '900px',
    flexShrink: 0,
    aspectRatio: '16/9',
    maxHeight: '600px',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
    transition: 'all 0.3s ease',
    '@media (min-width: 1920px)': {
      width: '1000px'
    },
    '@media (max-width: 1440px)': {
      width: '800px'
    },
    '@media (max-width: 1200px)': {
      width: '700px'
    },
    '@media (max-width: 991px)': {
      width: '600px'
    },
    '@media (max-width: 768px)': {
      width: '100%',
    }
  },
  adjacentChapterWrapper: {
    width: '500px',
    flexShrink: 0,
    aspectRatio: '16/9',
    maxHeight: '400px',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
    transition: 'all 0.3s ease',
    opacity: 1,
    transform: 'scale(0.9)',
    '&:hover': {
      opacity: 1,
      transform: 'scale(0.92)'
    },
    '@media (min-width: 1920px)': {
      width: '600px'
    },
    '@media (max-width: 1440px)': {
      width: '450px'
    },
    '@media (max-width: 1200px)': {
      width: '400px'
    },
    '@media (max-width: 991px)': {
      width: '350px'
    },
    '@media (max-width: 768px)': {
      transform: 'scale(0.95)',
      opacity: 0.9,
      width: '100%',
    }
  },
  tableOfContentsButton: {
    position: 'fixed',
    bottom: '2.5rem',
    left: '2.5rem',
    zIndex: 3,
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.625rem',
    cursor: 'pointer',
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    fontSize: '1rem',
    letterSpacing: '0.1em',
    border: 'none',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 0.9
    },
    '@media (max-width: 768px)': {
      bottom: '1.5rem',
      left: '1.5rem',
      padding: '0.5rem 1rem',
      fontSize: '0.875rem',
    }
  },
  downloadableContentButton: {
    position: 'fixed',
    bottom: '2.5rem',
    right: '2.5rem',
    zIndex: 3,
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.625rem',
    cursor: 'pointer',
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    fontSize: '1rem',
    letterSpacing: '0.1em',
    border: 'none',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 0.9
    },
    '@media (max-width: 768px)': {
      bottom: '1.5rem',
      right: '1.5rem',
      padding: '0.5rem 1rem',
      fontSize: '0.875rem',
    }
  },
  chapterCard: {
    cursor: 'pointer',
    width: '100%',
    borderRadius: '15px',
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.02)'
    }
  },
  chapterHeader: {
    padding: '20px',
    textAlign: 'center',
    color: 'white'
  },
  chapterNumber: {
    fontSize: '1.5rem',
    fontWeight: 700,
    marginBottom: '0.5rem',
    fontFamily: 'Roboto Slab, sans-serif',
    color: 'white'
  },
  chapterTitle: {
    fontSize: '1.2rem',
    fontWeight: 500,
    fontFamily: 'Roboto Slab, sans-serif',
    color: 'white'
  },
  main: {
    background: '#f8f9fa',
    position: 'relative',
    zIndex: '3',
    minHeight: '100vh',
  },
  mainRaised: {
    margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    '@media (max-width: 768px)': {
      margin: '-60px 10px 0px',
    },
    '@media (max-width: 480px)': {
      margin: '-40px 5px 0px',
    }
  },
  container: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    '@media (max-width: 768px)': {
      width: '100%',
      padding: '0 10px',
    }
  },
  courseTitle: {
    position: 'relative',
    alignSelf: 'center',
    display: 'flex',
    marginTop: '4rem',
    width: '100%',
    maxWidth: '56.25rem',
    alignItems: 'center',
    gap: '0.625rem',
    fontFamily: 'Roboto Slab, sans-serif',
    color: 'rgba(255, 255, 255, 1)',
    textAlign: 'center',
    flexWrap: 'wrap',
    zIndex: 2,
    '@media (max-width: 1440px)': {
      marginTop: '3rem'
    },
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      padding: '0 1.25rem'
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      padding: '1rem 0',
      gap: '1rem',
    }
  },
  titleContent: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    },
    '@media (max-width: 768px)': {
      order: -1,
    }
  },
  mainTitle: {
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: '3.125rem',
    letterSpacing: '0.1em',
    margin: 0,
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    paddingBottom: '1.5rem',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      fontSize: '2.5rem'
    },
    '@media (max-width: 768px)': {
      fontSize: '1.8rem',
    },
    '@media (max-width: 480px)': {
      fontSize: '1.5rem',
    }
  },
  subTitle: {
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: '1.5625rem',
    letterSpacing: '0.1em',
    alignSelf: 'center',
    margin: '0.3125rem 0 0',
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 500,
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    },
    '@media (max-width: 768px)': {
      fontSize: '1.2rem',
    },
    '@media (max-width: 480px)': {
      fontSize: '1rem',
    }
  },
  navigationArrow: {
    width: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'transform 0.2s ease',
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    borderRadius: '50%',
    padding: '0.5rem',
    margin: '0 1rem',
    '&:hover': {
      transform: 'scale(1.1)',
      background: 'linear-gradient(180deg, #d00 0%, #700 100%)'
    },
    '& svg': {
      width: '100%',
      height: '100%',
      color: '#fff'
    },
    '@media (max-width: 768px)': {
      width: '40px',
      height: '40px',
    }
  },
  carousel: {
    position: 'relative',
    width: '100%',
    height: '600px',
    margin: '2rem 0',
    zIndex: 2,
    '@media (max-width: 1440px)': {
      height: '500px',
      margin: '1.5rem 0'
    },
    '@media (max-width: 991px)': {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '0 1rem'
    }
  },
  carouselTrack: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 991px)': {
      flexDirection: 'column',
      gap: '1rem'
    }
  },
  chapterPosition: {
    position: 'absolute',
    left: '50%',
    transition: 'all 0.3s ease',
    '@media (max-width: 991px)': {
      position: 'relative',
      left: 'auto',
      transform: 'none !important',
      opacity: '1 !important',
      width: '100%'
    }
  },
  leftPosition: {
    transform: 'translateX(-150%)',
    opacity: 1,
    zIndex: 2
  },
  centerPosition: {
    transform: 'translateX(-50%)',
    opacity: 1,
    zIndex: 3
  },
  rightPosition: {
    transform: 'translateX(50%)',
    opacity: 1,
    zIndex: 2
  },
  singleChapterWrapper: {
    width: '900px',
    flexShrink: 0,
    aspectRatio: '16/9',
    maxHeight: '600px',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
    transition: 'all 0.3s ease',
    '@media (min-width: 1920px)': {
      width: '1000px'
    },
    '@media (max-width: 1440px)': {
      width: '800px'
    },
    '@media (max-width: 1200px)': {
      width: '700px'
    },
    '@media (max-width: 991px)': {
      width: '600px'
    },
    '@media (max-width: 768px)': {
      width: '100%',
    }
  },
  adjacentChapterWrapper: {
    width: '500px',
    flexShrink: 0,
    aspectRatio: '16/9',
    maxHeight: '400px',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
    transition: 'all 0.3s ease',
    opacity: 1,
    transform: 'scale(0.9)',
    '&:hover': {
      opacity: 1,
      transform: 'scale(0.92)'
    },
    '@media (min-width: 1920px)': {
      width: '600px'
    },
    '@media (max-width: 1440px)': {
      width: '450px'
    },
    '@media (max-width: 1200px)': {
      width: '400px'
    },
    '@media (max-width: 991px)': {
      width: '350px'
    },
    '@media (max-width: 768px)': {
      transform: 'scale(0.95)',
      opacity: 0.9,
      width: '100%',
    }
  },
  tableOfContentsButton: {
    position: 'fixed',
    bottom: '2.5rem',
    left: '2.5rem',
    zIndex: 3,
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.625rem',
    cursor: 'pointer',
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    fontSize: '1rem',
    letterSpacing: '0.1em',
    border: 'none',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 0.9
    },
    '@media (max-width: 768px)': {
      bottom: '1.5rem',
      left: '1.5rem',
      padding: '0.5rem 1rem',
      fontSize: '0.875rem',
    }
  },
  downloadableContentButton: {
    position: 'fixed',
    bottom: '2.5rem',
    right: '2.5rem',
    zIndex: 3,
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.625rem',
    cursor: 'pointer',
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    fontSize: '1rem',
    letterSpacing: '0.1em',
    border: 'none',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 0.9
    },
    '@media (max-width: 768px)': {
      bottom: '1.5rem',
      right: '1.5rem',
      padding: '0.5rem 1rem',
      fontSize: '0.875rem',
    }
  },
  chapterCard: {
    cursor: 'pointer',
    width: '100%',
    borderRadius: '15px',
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.02)'
    }
  },
  chapterHeader: {
    padding: '20px',
    textAlign: 'center',
    color: 'white'
  },
  chapterNumber: {
    fontSize: '1.5rem',
    fontWeight: 700,
    marginBottom: '0.5rem',
    fontFamily: 'Roboto Slab, sans-serif',
    color: 'white'
  },
  chapterTitle: {
    fontSize: '1.2rem',
    fontWeight: 500,
    fontFamily: 'Roboto Slab, sans-serif',
    color: 'white'
  }
}));

export default function CoursesPage() {
  const classes = useStyles();
  const history = useHistory();
  const [courses, setCourses] = useState([]);
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [showingDownloadable, setShowingDownloadable] = useState(false);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const db = getFirestore();
        const coursesCollection = collection(db, 'courses');
        const coursesSnapshot = await getDocs(coursesCollection);
        const coursesList = coursesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCourses(coursesList);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const handlePrevChapter = () => {
    const currentCourse = courses[0] || {};
    const chaptersLength = currentCourse.chapters?.length || 0;
    setCurrentChapterIndex(prev => prev > 0 ? prev - 1 : chaptersLength - 1);
  };

  const handleNextChapter = () => {
    const currentCourse = courses[0] || {};
    const chaptersLength = currentCourse.chapters?.length || 0;
    setCurrentChapterIndex(prev => prev < chaptersLength - 1 ? prev + 1 : 0);
  };

  const currentCourse = courses[0] || {};
  const chapters = currentCourse.chapters || [];
  const currentChapter = chapters[currentChapterIndex];

  const handleChapterClick = (index) => {
    if (index === currentChapterIndex) {
      history.push(`/course/${currentCourse.id}/lesson?chapter=${index}`);
    } else {
      setCurrentChapterIndex(index);
    }
  };

  const getVisibleChapters = () => {
    if (chapters.length <= 1) return [currentChapterIndex];
    
    let visibleIndexes = [];
    
    if (currentChapterIndex > 0) {
      visibleIndexes.push(currentChapterIndex - 1);
    } else if (chapters.length > 1) {
      visibleIndexes.push(chapters.length - 1);
    }
    
    visibleIndexes.push(currentChapterIndex);
    
    if (currentChapterIndex < chapters.length - 1) {
      visibleIndexes.push(currentChapterIndex + 1);
    } else if (chapters.length > 1) {
      visibleIndexes.push(0);
    }
    
    return visibleIndexes;
  };

  const getPositionClass = (index) => {
    const visibleIndexes = getVisibleChapters();
    const position = visibleIndexes.indexOf(index);
    
    if (position === 0) return classes.leftPosition;
    if (position === 1) return classes.centerPosition;
    if (position === 2) return classes.rightPosition;
    return '';
  };

  return (
    <main className={classes.pageWrapper}>
      <div className={classes.contentContainer}>
        <img
          className={classes.backgroundImage}
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/e6ab85e93ad9451db00e45c8874777d7/d4f0624a8ef4791795b99e857631fd1d9c1ed3c7b65014f3c39f6c431b46374d?apiKey=e6ab85e93ad9451db00e45c8874777d7&"
          alt=""
        />
        {/* <div className={classes.overlay} /> */}
        <Header
          color="transparent"
          brand={<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem' }} />}
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
        />
        <section className={classes.courseTitle}>
          <div
            className={classes.navigationArrow}
            onClick={handlePrevChapter}
          >
            <ChevronLeft />
          </div>
          <div className={classes.titleContent}>
            <h1 className={classes.mainTitle}>{currentCourse.title || 'Loading...'}</h1>
            {currentChapter && (
              <h2 className={classes.subTitle}>
                {`${currentChapter?.chapterHeader || `Chapter ${currentChapterIndex + 1}`} - ${currentChapter?.title}`}
              </h2>
            )}
          </div>
          <div
            className={classes.navigationArrow}
            onClick={handleNextChapter}
          >
            <ChevronRight />
          </div>
        </section>
        <div className={classes.carousel}>
          <div className={classes.carouselTrack}>
            {chapters.map((chapter, index) => (
              <div 
                key={index}
                className={`${classes.chapterPosition} ${getPositionClass(index)}`}
              >
                <div className={index === currentChapterIndex ? 
                  classes.singleChapterWrapper : 
                  classes.adjacentChapterWrapper}
                >
                  <ChapterCard 
                    title={chapter.title}
                    image={chapter.imageUrl}
                    description={chapter.description}
                    moduleCount={chapter.modules?.length || 0}
                    isFocused={index === currentChapterIndex}
                    onClick={() => handleChapterClick(index)}
                    chapter={chapter}
                    index={index}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <button 
          className={classes.tableOfContentsButton}
          onClick={() => history.push(`/course/${currentCourse.id}/overview`)}
        >
          Table of Contents
        </button>
        <button 
          className={classes.downloadableContentButton}
          onClick={() => {
            history.push(`/course/${currentCourse.id}/overview`);
            setTimeout(() => {
              const element = document.getElementById('downloadable-content');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: 'smooth'
                });
              } else {
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: 'smooth'
                });
              }
            }, 500);
          }}
        >
          Downloadable Content
        </button>
      </div>
    </main>
  );
} 