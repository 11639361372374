/*eslint-disable*/ import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Favorite from '@material-ui/icons/Favorite';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import logo from 'assets/img/logo.png';
import landingPageStyle from 'assets/jss/material-kit-pro-react/views/landingPageStyle.js';
import SectionProduct from './Sections/SectionProduct.js';
import SectionTeam from './Sections/SectionTeam.js';
import SectionTest from './Sections/SectionTest.js';
import AuthContext from 'contexts/AuthContext';
import { useProductAccess } from 'hooks/useProductAccess';
import { getFirestore, getDoc, doc } from 'firebase/firestore';
import { Typography } from '@material-ui/core';

const POPUP_EXPIRY_DAYS = 7; // Number of days before showing popup again

const useStyles = makeStyles(theme => ({
	...landingPageStyle,
	viewCoursesButton: {
		alignSelf: 'stretch',
		border: 'none',
		borderRadius: '10px',
		background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'center',
		width: '80%',
		padding: '15px 8px',
		font: '700 16px Roboto, sans-serif',
		cursor: 'pointer',
		transition: 'opacity 0.2s ease',
		marginTop: '20px',
		'&:hover': {
			opacity: 0.9
		},
		'&:focus': {
			outline: '2px solid #fff',
			outlineOffset: '2px'
		}
	},
	helpText: {
		color: 'black',
		textAlign: 'center',
		maxWidth: '800px',
		margin: '0 auto',
		marginBottom: '2rem'
	},
	adminButton: {
		background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
		color: 'white',
		marginTop: '1rem',
		padding: '12px 30px',
		borderRadius: '25px',
		'&:hover': {
			background: 'linear-gradient(180deg, #f00 0%, #800 100%)'
		}
	}
}));

export default function LandingPage({ ...rest }) {
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const { hasAccess } = useProductAccess('all-courses');
	const classes = useStyles();
	const [showPopup, setShowPopup] = React.useState(false);
	const [isAdmin, setIsAdmin] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;

		// Check if user is logged in - don't show popup to logged-in users
		if (user) {
			setShowPopup(false);
			return;
		}

		// Check local storage for last popup time
		const lastPopupTime = localStorage.getItem('lastPopupTime');
		const currentTime = new Date().getTime();

		if (!lastPopupTime ||
			currentTime - parseInt(lastPopupTime) > POPUP_EXPIRY_DAYS * 24 * 60 * 60 * 1000) {
			setShowPopup(true);
			localStorage.setItem('lastPopupTime', currentTime.toString());
		}
	}, [user]);

	useEffect(() => {
		const checkAdminStatus = async () => {
			if (!user) return;

			try {
				const db = getFirestore();
				const userDoc = await getDoc(doc(db, 'users', user.uid));
				setIsAdmin(userDoc.exists() && userDoc.data().userType === 'admin');
			} catch (error) {
				console.error('Error checking admin status:', error);
			}
		};

		checkAdminStatus();
	}, [user]);

	const handleViewCoursesClick = (e) => {
		e.preventDefault();
		// Check if on mobile
		const isMobile = window.innerWidth <= 768;
		
		if (!user) {
			history.push('/all-courses');
		} else {
			const db = getFirestore();
			getDoc(doc(db, 'users', user.uid)).then(userDoc => {
				const productsOwned = userDoc.data()?.productsOwned || [];
				if (productsOwned.includes('all-courses')) {
					if (isMobile) {
						// On mobile, go directly to course overview with the correct course ID
						history.push('/course/mxZeDmL5Ab0WmUCBAGAb/overview');
					} else {
						// On desktop, go to courses page
						history.push('/courses');
					}
				} else {
					history.push('/all-courses');
				}
			}).catch(error => {
				console.error('Error checking course access:', error);
				history.push('/all-courses');
			});
		}
	};

	return (
		<div>
			<Header
				color='transparent'
				image={require('assets/img/logoW.png')}
				brand={
					<img src={logo} alt='logo' style={{ width: 'auto', height: '4rem' }} />
				}
				links={<HeaderLinks dropdownHoverColor='info' />}
				fixed
				changeColorOnScroll={{
					height: 300,
					color: 'white',
				}}
				{...rest}
			/>
			<Parallax image={require('assets/img/Bloodborne2.jpg')} filter='dark'>
				<div className={classes.container}>
					<GridContainer>
						<GridItem xs={12} sm={6} md={6}>
							<h1 className={classes.title}>Transform Your Dating Life</h1>
							<h4>
								Hey there! My name is Hector Castillo. I'm a social skills and relationship
								coach. I'm here to help you become the best version of yourself and also find your
								dream girl. And if you really pay attention to what I have to say, you'll soon
								discover that the skills I teach will change your entire life.
							</h4>
							<br />
							<Button
								className={classes.viewCoursesButton}
								onClick={handleViewCoursesClick}
							>
								View Courses
							</Button>
							{isAdmin && (
								<Button
									className={classes.viewCoursesButton}
									onClick={() => history.push('/admin')}
								>
									Admin Panel
								</Button>
							)}
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.container}>
					<SectionTeam />
					<SectionProduct />
					<SectionTest />
				</div>
			</div>
			<Footer
				content={
					<div>
						<div className={classes.left}></div>
						<div className={classes.right}>
							&copy; {1900 + new Date().getYear()} , made with{' '}
							<Favorite className={classes.icon} /> by{' '}
							<a
								href='https://www.dsignable.com'
								target='_blank'
								rel="noopener noreferrer"
							>
								Dsignable
							</a>{' '}
							for a better web.
						</div>
					</div>
				}
			/>
		</div>
	);
}
