import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  buttonWrapper: {
    borderRadius: '25px',
    alignSelf: 'stretch',
    display: 'flex',
    minWidth: '240px',
    flexDirection: 'column',
    width: '250px',
    margin: 'auto 0',
    '@media (max-width: 767px)': {
      minWidth: '180px',
      width: '180px',
    }
  },
  button: {
    borderRadius: '25px',
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: '#fff',
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'center',
    letterSpacing: '2px',
    width: '100%',
    padding: '17px 25px',
    border: 'none',
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      background: 'linear-gradient(180deg, #d00 0%, #700 100%)',
    },
    '@media (max-width: 991px)': {
      padding: '0 20px'
    },
    '@media (max-width: 767px)': {
      fontSize: '16px',
      letterSpacing: '1px',
      padding: '12px 15px',
    }
  }
});

export default function NavigationButton({ direction, text, onClick }) {
  const classes = useStyles();
  
  return (
    <div className={classes.buttonWrapper}>
      <Button 
        className={classes.button}
        onClick={onClick}
      >
        {direction === 'previous' ? 'Previous Chapter' : 'Next Chapter'}
      </Button>
    </div>
  );
} 