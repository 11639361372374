import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  postContainer: {
    display: 'flex',
    padding: '15px',
    marginBottom: '15px',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    fontFamily: 'Roboto Slab, sans-serif'
  },
  avatarSection: {
    marginRight: '15px'
  },
  contentSection: {
    flex: 1
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    justifyContent: 'space-between'
  },
  userName: {
    fontWeight: 600,
    fontSize: '1rem',
    color: '#333',
    fontFamily: 'Roboto Slab, sans-serif'
  },
  timestamp: {
    color: '#666',
    fontSize: '0.875rem',
    fontFamily: 'Roboto Slab, sans-serif'
  },
  message: {
    color: '#444',
    fontSize: '0.9375rem',
    lineHeight: 1.5,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    fontFamily: 'Roboto Slab, sans-serif'
  }
}));

export function UserPost({ username, message, timestamp, avatar }) {
  const classes = useStyles();
  
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const formatUsername = (username) => {
    if (!username) return 'Anonymous';
    return username.split('@')[0];
  };

  const displayName = formatUsername(username);

  return (
    <div className={classes.postContainer}>
      <div className={classes.avatarSection}>
        <Avatar src={avatar} alt={displayName}>
          {displayName ? displayName[0].toUpperCase() : '?'}
        </Avatar>
      </div>
      <div className={classes.contentSection}>
        <div className={classes.userInfo}>
          <Typography className={classes.userName}>
            {displayName}
          </Typography>
          <Typography className={classes.timestamp}>
            {formatDate(timestamp)}
          </Typography>
        </div>
        <Typography className={classes.message}>
          {message}
        </Typography>
      </div>
    </div>
  );
} 