/*eslint-disable*/
import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Icon from '@material-ui/core/Icon';
import Email from '@material-ui/icons/Email';
import Favorite from '@material-ui/icons/Favorite';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import logo from 'assets/img/logo.png';
import { sendResetEmail } from 'api/firebaseApi';
import loginPageStyle from 'assets/jss/material-kit-pro-react/views/loginPageStyle.js';
import { Toaster } from 'react-hot-toast';
import image from 'assets/img/hector_and_student.png';
import AuthContext from 'contexts/AuthContext';

const useStyles = makeStyles(loginPageStyle);

export default function LoginPage({ notify }) {
	const { user, signInUser, signInWithGoogle } = useContext(AuthContext);
	const classes = useStyles();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});

	const googleSignIn = async () => {
		await signInWithGoogle();
		notify('Logged in successfully!');
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const res = await signInUser(email, password);
		if (res?.error) {
			notify(res.error);
			return;
		}
		notify('Logged in successfully!');
		setEmail('');
		setPassword('');
	};

	return (
		<div>
			<Toaster position="bottom-center" />
			<Header
				image={require('assets/img/logo.png')}
				brand={
					<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem' }} />
				}
				links={<HeaderLinks dropdownHoverColor="info" />}
				fixed
				color="transparent"
				changeColorOnScroll={{
					height: 300,
					color: 'info',
				}}
			/>
			<div
				className={classes.pageHeader}
				style={{
					backgroundImage: 'url(' + image + ')',
					backgroundSize: 'cover',
					backgroundPosition: 'top center',
				}}
			>
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem xs={12} sm={12} md={4}>
							<Card>
								<form className={classes.form} onSubmit={handleSubmit}>
									<CardHeader color="primary" signup className={classes.cardHeader}>
										<h4 className={classes.cardTitle}>Login</h4>
										<div className={classes.socialLine}>
											<Button
												justIcon
												color="transparent"
												className={classes.iconButtons}
												onClick={googleSignIn}
											>
												<i className="fab fa-google-plus-g" />
											</Button>
										</div>
									</CardHeader>
									<p className={classes.description + ' ' + classes.textCenter}>
										Or Be Classical
									</p>
									<CardBody signup>
										<CustomInput
											id="email"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: 'Email...',
												type: 'email',
												startAdornment: (
													<InputAdornment position="start">
														<Email className={classes.inputIconsColor} />
													</InputAdornment>
												),
												value: email,
												required: true,
												onChange: (e) => setEmail(e.target.value),
											}}
										/>
										<CustomInput
											id="pass"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: 'Password',
												type: 'password',
												startAdornment: (
													<InputAdornment position="start">
														<Icon className={classes.inputIconsColor}>lock_utline</Icon>
													</InputAdornment>
												),
												autoComplete: 'off',
												value: password,
												required: true,
												onChange: (e) => setPassword(e.target.value),
											}}
										/>
									</CardBody>
									<div className={classes.textCenter}>
										<Button simple color="primary" size="lg" type="submit">
											Get started
										</Button>
									</div>
									<div className={classes.textCenter}>
										<Button
											simple
											color="warning"
											size="lg"
											onClick={() => {
												sendResetEmail(email);
											}}
										>
											Forgot Password
										</Button>
									</div>
								</form>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
				<Footer
					className={classes.footer}
					content={
						<div>
							<div className={classes.left}>
								<List className={classes.list}>
									<ListItem className={classes.inlineBlock}>
										<a href="/" target="_blank" className={classes.block}>
											About
										</a>
									</ListItem>
									<ListItem className={classes.inlineBlock}>
										<a href="/blog-post" className={classes.block}>
											Blog
										</a>
									</ListItem>
								</List>
							</div>
							<div className={classes.right}>
								&copy; {1900 + new Date().getYear()} , made with{' '}
								<Favorite className={classes.icon} /> by{' '}
								<a
									href="https://www.dsignable.com"
									target="_blank"
									rel="noopener noreferrer"
								>
									Dsignable
								</a>{' '}
								for a better web
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
}
