import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';

import workStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js';

const useStyles = makeStyles(workStyle);
const useStylesVideo = makeStyles((theme) => ({
	iframeContainer: {
		position: 'relative',
		width: '100%',
		height: '0',
		paddingBottom: '56.25%', // 16:9 aspect ratio (change this to fit your aspect ratio)
		'& iframe': {
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: '0',
			left: '0',
		},
	},
}));

export default function SectionWork() {
	const classesVideos = useStylesVideo();
	const classes = useStyles();
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			countryCode: '+1',
			phoneNumber: '',
			message: '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Please enter your name'),
			email: Yup.string()
				.email('Invalid email address')
				.required('Please enter your email'),
			countryCode: Yup.string().required('Please select a country code'),
			phoneNumber: Yup.string()
				.matches(/^\d{10}$/, 'Phone number must be 10 digits')
				.required('Please enter your phone number'),
			message: Yup.string().required('Please enter your message'),
		}),
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				const payload = {
					childKey: {
						name: values.name,
						email: values.email,
						phone: values.phoneNumber,
						countryCode: values.countryCode,
						message: values.message,
					},
				};

				window.grecaptcha.ready(async () => {
					const token = await window.grecaptcha.execute(
						process.env.REACT_APP_RECAPTCHA_SITE_KEY,
						{
							action: 'submit',
						}
					);

					const res = await axios
						.post(`${process.env.REACT_APP_API_URL}/sendContactEmail`, {
							token,
							name: values.name,
							email: values.email,
							phone: values.phoneNumber,
							countryCode: values.countryCode,
							message: values.message,
						})
						.catch(() => {
							toast.error('Message failed to send');
							return false;
						});
					if (res) {
						toast.success('Message sent successfully! We will contact you within 48 hours.');
						setShowSuccessMessage(true);
						resetForm();
					}
				});
			} catch (error) {
				console.error(error);
				toast.error('Failed to send message. Please try again.');
			} finally {
				setSubmitting(false);
			}
		},
	});

	const countryCodes = [
		{ code: '+1', label: 'USA/Canada (+1)' },
		{ code: '+44', label: 'UK (+44)' },
		{ code: '+61', label: 'Australia (+61)' },
		{ code: '+64', label: 'New Zealand (+64)' },
		{ code: '+353', label: 'Ireland (+353)' },
		{ code: '+91', label: 'India (+91)' },
		{ code: '+86', label: 'China (+86)' },
		{ code: '+81', label: 'Japan (+81)' },
		{ code: '+82', label: 'South Korea (+82)' },
		{ code: '+49', label: 'Germany (+49)' },
		{ code: '+33', label: 'France (+33)' },
		{ code: '+34', label: 'Spain (+34)' },
		{ code: '+39', label: 'Italy (+39)' },
		{ code: '+31', label: 'Netherlands (+31)' },
		{ code: '+46', label: 'Sweden (+46)' },
		{ code: '+47', label: 'Norway (+47)' },
		{ code: '+45', label: 'Denmark (+45)' },
		{ code: '+358', label: 'Finland (+358)' },
		{ code: '+48', label: 'Poland (+48)' },
		{ code: '+7', label: 'Russia (+7)' },
		{ code: '+55', label: 'Brazil (+55)' },
		{ code: '+52', label: 'Mexico (+52)' },
		{ code: '+54', label: 'Argentina (+54)' },
		{ code: '+27', label: 'South Africa (+27)' },
		{ code: '+971', label: 'UAE (+971)' },
		{ code: '+966', label: 'Saudi Arabia (+966)' },
		{ code: '+65', label: 'Singapore (+65)' },
		{ code: '+60', label: 'Malaysia (+60)' },
		{ code: '+66', label: 'Thailand (+66)' },
		{ code: '+84', label: 'Vietnam (+84)' },
		{ code: '+32', label: 'Belgium (+32)' },
		{ code: '+351', label: 'Portugal (+351)' },
		{ code: '+41', label: 'Switzerland (+41)' },
		{ code: '+43', label: 'Austria (+43)' },
		{ code: '+30', label: 'Greece (+30)' },
		{ code: '+36', label: 'Hungary (+36)' },
		{ code: '+420', label: 'Czech Republic (+420)' },
		{ code: '+421', label: 'Slovakia (+421)' },
		{ code: '+40', label: 'Romania (+40)' },
		{ code: '+359', label: 'Bulgaria (+359)' },
		{ code: '+385', label: 'Croatia (+385)' },
		{ code: '+386', label: 'Slovenia (+386)' },
		{ code: '+372', label: 'Estonia (+372)' },
		{ code: '+371', label: 'Latvia (+371)' },
		{ code: '+370', label: 'Lithuania (+370)' },
		{ code: '+380', label: 'Ukraine (+380)' },
		{ code: '+375', label: 'Belarus (+375)' },
		{ code: '+373', label: 'Moldova (+373)' },
		{ code: '+995', label: 'Georgia (+995)' },
		{ code: '+374', label: 'Armenia (+374)' },
		{ code: '+994', label: 'Azerbaijan (+994)' },
		{ code: '+98', label: 'Iran (+98)' },
		{ code: '+90', label: 'Turkey (+90)' },
		{ code: '+20', label: 'Egypt (+20)' },
		{ code: '+212', label: 'Morocco (+212)' },
		{ code: '+216', label: 'Tunisia (+216)' },
		{ code: '+218', label: 'Libya (+218)' },
		{ code: '+249', label: 'Sudan (+249)' },
		{ code: '+251', label: 'Ethiopia (+251)' },
		{ code: '+254', label: 'Kenya (+254)' },
		{ code: '+256', label: 'Uganda (+256)' },
		{ code: '+255', label: 'Tanzania (+255)' },
		{ code: '+260', label: 'Zambia (+260)' },
		{ code: '+263', label: 'Zimbabwe (+263)' },
		{ code: '+264', label: 'Namibia (+264)' },
		{ code: '+265', label: 'Malawi (+265)' },
		{ code: '+266', label: 'Lesotho (+266)' },
		{ code: '+267', label: 'Botswana (+267)' },
		{ code: '+268', label: 'Swaziland (+268)' },
		{ code: '+269', label: 'Comoros (+269)' },
		{ code: '+290', label: 'Saint Helena (+290)' },
		{ code: '+297', label: 'Aruba (+297)' },
		{ code: '+298', label: 'Faroe Islands (+298)' },
		{ code: '+299', label: 'Greenland (+299)' },
		{ code: '+350', label: 'Gibraltar (+350)' },
		{ code: '+352', label: 'Luxembourg (+352)' },
		{ code: '+354', label: 'Iceland (+354)' },
		{ code: '+356', label: 'Malta (+356)' },
		{ code: '+357', label: 'Cyprus (+357)' },
		{ code: '+358', label: 'Finland (+358)' },
		{ code: '+377', label: 'Monaco (+377)' },
		{ code: '+378', label: 'San Marino (+378)' },
		{ code: '+379', label: 'Vatican City (+379)' },
		{ code: '+381', label: 'Serbia (+381)' },
		{ code: '+382', label: 'Montenegro (+382)' },
		{ code: '+383', label: 'Kosovo (+383)' },
		{ code: '+386', label: 'Slovenia (+386)' },
		{ code: '+387', label: 'Bosnia and Herzegovina (+387)' },
		{ code: '+389', label: 'Macedonia (+389)' },
		{ code: '+423', label: 'Liechtenstein (+423)' },
		{ code: '+500', label: 'Falkland Islands (+500)' },
		{ code: '+501', label: 'Belize (+501)' },
		{ code: '+502', label: 'Guatemala (+502)' },
		{ code: '+503', label: 'El Salvador (+503)' },
		{ code: '+504', label: 'Honduras (+504)' },
		{ code: '+505', label: 'Nicaragua (+505)' },
		{ code: '+506', label: 'Costa Rica (+506)' },
		{ code: '+507', label: 'Panama (+507)' },
		{ code: '+509', label: 'Haiti (+509)' },
		{ code: '+590', label: 'Guadeloupe (+590)' },
		{ code: '+591', label: 'Bolivia (+591)' },
		{ code: '+592', label: 'Guyana (+592)' },
		{ code: '+593', label: 'Ecuador (+593)' },
		{ code: '+595', label: 'Paraguay (+595)' },
		{ code: '+597', label: 'Suriname (+597)' },
		{ code: '+598', label: 'Uruguay (+598)' },
		{ code: '+599', label: 'Netherlands Antilles (+599)' },
		{ code: '+670', label: 'East Timor (+670)' },
		{ code: '+672', label: 'Norfolk Island (+672)' },
		{ code: '+673', label: 'Brunei (+673)' },
		{ code: '+674', label: 'Nauru (+674)' },
		{ code: '+675', label: 'Papua New Guinea (+675)' },
		{ code: '+676', label: 'Tonga (+676)' },
		{ code: '+677', label: 'Solomon Islands (+677)' },
		{ code: '+678', label: 'Vanuatu (+678)' },
		{ code: '+679', label: 'Fiji (+679)' },
		{ code: '+680', label: 'Palau (+680)' },
		{ code: '+681', label: 'Wallis and Futuna (+681)' },
		{ code: '+682', label: 'Cook Islands (+682)' },
		{ code: '+683', label: 'Niue (+683)' },
		{ code: '+685', label: 'Samoa (+685)' },
		{ code: '+686', label: 'Kiribati (+686)' },
		{ code: '+687', label: 'New Caledonia (+687)' },
		{ code: '+688', label: 'Tuvalu (+688)' },
		{ code: '+689', label: 'French Polynesia (+689)' },
		{ code: '+690', label: 'Tokelau (+690)' },
		{ code: '+691', label: 'Micronesia (+691)' },
		{ code: '+692', label: 'Marshall Islands (+692)' },
		{ code: '+850', label: 'North Korea (+850)' },
		{ code: '+852', label: 'Hong Kong (+852)' },
		{ code: '+853', label: 'Macau (+853)' },
		{ code: '+855', label: 'Cambodia (+855)' },
		{ code: '+856', label: 'Laos (+856)' },
		{ code: '+880', label: 'Bangladesh (+880)' },
		{ code: '+886', label: 'Taiwan (+886)' },
		{ code: '+960', label: 'Maldives (+960)' },
		{ code: '+961', label: 'Lebanon (+961)' },
		{ code: '+962', label: 'Jordan (+962)' },
		{ code: '+963', label: 'Syria (+963)' },
		{ code: '+964', label: 'Iraq (+964)' },
		{ code: '+965', label: 'Kuwait (+965)' },
		{ code: '+967', label: 'Yemen (+967)' },
		{ code: '+968', label: 'Oman (+968)' },
		{ code: '+970', label: 'Palestine (+970)' },
		{ code: '+972', label: 'Israel (+972)' },
		{ code: '+973', label: 'Bahrain (+973)' },
		{ code: '+974', label: 'Qatar (+974)' },
		{ code: '+975', label: 'Bhutan (+975)' },
		{ code: '+976', label: 'Mongolia (+976)' },
		{ code: '+977', label: 'Nepal (+977)' },
		{ code: '+992', label: 'Tajikistan (+992)' },
		{ code: '+993', label: 'Turkmenistan (+993)' },
		{ code: '+994', label: 'Azerbaijan (+994)' },
		{ code: '+998', label: 'Uzbekistan (+998)' }
	];

	return (
		<div className={classes.section}>
			<Toaster position='bottom-center' />
			<GridContainer justify='center'>
				<GridItem md={12} sm={12}>
					<Card plain pricing>
						<CardBody pricing>
							<div className={classesVideos.iframeContainer}>
								<iframe
									width='100%'
									height='315'
									src='https://www.youtube.com/embed/GyNBP_5DxvY'
									title='YouTube video player'
									frameBorder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
									allowFullScreen
								></iframe>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem cs={12} sm={8} md={8}>
					<h2 className={classes.title}>Let{"'"}s Talk</h2>
					<h4 className={classes.description}>
						We'll do a consultation call before starting the coaching program. On this call, we'll discuss what your goals are, how we'll achieve them, and pricing. Fill out this form and I'll get back to you within 48 hours to schedule the call.
					</h4>
					{showSuccessMessage ? (
						<div style={{
							padding: '20px',
							backgroundColor: 'rgba(76, 175, 80, 0.1)',
							border: '1px solid #4CAF50',
							borderRadius: '10px',
							marginBottom: '20px'
						}}>
							<h4 style={{ color: '#4CAF50', marginBottom: '10px' }}>Success! Your message has been sent.</h4>
							<p style={{ fontSize: '16px', lineHeight: '1.5' }}>
								Thank you for your interest in coaching! We have received your inquiry and will get back to you within 48 hours to schedule your consultation call. 
								During this call, we'll discuss your goals, how we can achieve them together, and the coaching program details.
							</p>
						</div>
					) : (
						<form
							onSubmit={formik.handleSubmit}
							id='demo-form'
							action='?'
							method='POST'
						>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6}>
									<CustomInput
										labelText='Your Name'
										id='name'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: formik.values.name,
											onChange: formik.handleChange,
											onBlur: formik.handleBlur,
										}}
										error={Boolean(formik.touched.name) && Boolean(formik.errors.name)}
										helperText={formik.touched.name && formik.errors.name}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={6}>
									<CustomInput
										labelText='Your Email'
										id='email'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: formik.values.email,
											onChange: formik.handleChange,
											onBlur: formik.handleBlur,
										}}
										error={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
										helperText={formik.touched.email && formik.errors.email}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={3}>
									<FormControl fullWidth style={{ marginTop: '27px' }}>
										<InputLabel id="country-code-label">Country Code</InputLabel>
										<Select
											labelId="country-code-label"
											id="countryCode"
											name="countryCode"
											value={formik.values.countryCode}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={Boolean(formik.touched.countryCode) && Boolean(formik.errors.countryCode)}
										>
											{countryCodes.map((country) => (
												<MenuItem key={country.code} value={country.code}>
													{country.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={6} md={9}>
									<CustomInput
										labelText='Phone Number'
										id='phoneNumber'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: formik.values.phoneNumber,
											onChange: formik.handleChange,
											onBlur: formik.handleBlur,
											placeholder: '1234567890'
										}}
										error={Boolean(formik.touched.phoneNumber) && Boolean(formik.errors.phoneNumber)}
										helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
									/>
								</GridItem>
								<CustomInput
									labelText='Your Message'
									id='message'
									formControlProps={{
										fullWidth: true,
										className: classes.textArea,
									}}
									inputProps={{
										multiline: true,
										rows: 5,
										value: formik.values.message,
										onChange: formik.handleChange,
										onBlur: formik.handleBlur,
									}}
									error={
										Boolean(formik.touched.message) && Boolean(formik.errors.message)
									}
									helperText={formik.touched.message && formik.errors.message}
								/>
								<GridItem
									xs={12}
									sm={12}
									md={12}
									className={classes.customButtonWidth + ' ' + classes.mlAuto}
								>
									<br></br>
									<Button
										data-sitekey='6Ler840mAAAAAE9EagyASQizl47aumA-LmuR5QUa'
										data-callback='onSubmit'
										position='center'
										fullWidth
										color='youtube'
										type='submit'
										disabled={formik.isSubmitting}
									>
										Send Message
									</Button>
								</GridItem>
							</GridContainer>
						</form>
					)}
				</GridItem>
			</GridContainer>
		</div>
	);
}
