import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import ViewCarousel from '@material-ui/icons/ViewCarousel';
import School from '@material-ui/icons/School';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-kit-pro-react/components/headerLinksStyle.js';
import AuthContext from 'contexts/AuthContext';
import { useProductAccess } from 'hooks/useProductAccess';
import { getFirestore, getDoc, doc } from 'firebase/firestore';

const useStyles = makeStyles((theme) => ({
	...styles,
	coursesButton: {
		borderRadius: '50px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'rgba(255, 255, 255, 1)',
		whiteSpace: 'nowrap',
		textAlign: 'center',
		fontSize: '12px',
		fontWeight: '400',
		border: 'none',
		cursor: 'pointer',
		outline: 'none',
		padding: '0.9375rem 1rem',
		background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
		marginLeft: "0.5rem",
		'&:focus-visible': {
			boxShadow: '0 0 0 2px #fff, 0 0 0 4px #000'
		},
		'@media (max-width: 960px)': {
			width: '100%',
			margin: '10px 0'
		}
	},
	coursesIcon: {
		fontSize: '20px'
	},
	navButton: {
		...styles.navButton,
		fontSize: '12px',
		fontWeight: '400',
		'@media (max-width: 960px)': {
			width: '100%',
			margin: '10px 0'
		}
	},
	list: {
		display: "flex",
		alignItems: "center",
		paddingLeft: "0",
		marginRight: "0",
		listStyle: "none",
		paddingTop: "0",
		paddingBottom: "0",
		color: "inherit",
		width: "100%",
		justifyContent: "flex-end",
		gap: "1rem",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			marginTop: "0.5rem",
			justifyContent: "flex-start",
			gap: "0.5rem"
		}
	},
	listItem: {
		float: "left",
		color: "inherit",
		position: "relative",
		display: "block",
		width: "auto",
		margin: "0",
		padding: "0",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			margin: "0"
		}
	},
	mlAuto: {
		...styles.mlAuto,
		display: 'flex',
		alignItems: 'center',
		marginLeft: "0 !important",
		'@media (max-width: 960px)': {
			flexDirection: 'column',
			width: '100%'
		}
	},
	userButtons: {
		display: 'flex',
		gap: '0.5rem',
		marginLeft: "0.5rem",
		alignItems: 'center',
		'@media (max-width: 960px)': {
			width: '100%',
			flexDirection: 'column',
			marginLeft: 0,
			gap: '0.5rem'
		}
	},
	fullWidthOnMobile: {
		'@media (max-width: 960px)': {
			width: '100%',
			display: 'block'
		}
	},
	navLink: {
		...styles.navLink,
		color: "inherit",
		position: "relative",
		padding: "0.9375rem 1rem",
		fontWeight: "400",
		fontSize: "12px",
		textTransform: "uppercase",
		lineHeight: "20px",
		textDecoration: "none",
		margin: "0",
		display: "inline-flex",
		transition: "all 0.2s ease",
		'&:hover, &:focus': {
			background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
			backgroundClip: 'text',
			WebkitBackgroundClip: 'text',
			WebkitTextFillColor: 'transparent',
			textFillColor: 'transparent'
		},
		[theme.breakpoints.up("md")]: {
			padding: "0.9375rem 1rem"
		}
	},
	navLinkActive: {
		color: "inherit",
		backgroundColor: "rgba(255, 255, 255, 0.1)"
	},
	icons: {
		width: "20px",
		height: "20px",
		marginRight: "3px"
	},
	socialIcons: {
		position: "relative",
		fontSize: "20px !important",
		marginRight: "4px"
	},
	dropdownLink: {
		"&,&:hover,&:focus": {
			color: "inherit",
			textDecoration: "none",
			display: "flex",
			alignItems: "center",
			padding: "0.75rem 1.25rem",
			transition: "all 0.2s ease",
		},
		"&:hover": {
			background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
			backgroundClip: 'text',
			WebkitBackgroundClip: 'text',
			WebkitTextFillColor: 'transparent',
			textFillColor: 'transparent',
			"& $dropdownIcons": {
				background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
				WebkitBackgroundClip: 'text',
				WebkitTextFillColor: 'transparent'
			}
		}
	},
	dropdownIcons: {
		width: "24px",
		height: "24px",
		marginRight: "14px",
		opacity: "0.5",
		marginTop: "-4px",
		top: "1px",
		verticalAlign: "middle",
		fontSize: "24px",
		position: "relative",
		transition: "all 0.2s ease"
	},
	marginRight5: {
		marginRight: "5px"
	},
	inputRoot: {
		color: "inherit",
		padding: "0.9375rem"
	},
	manager: {
		display: "inline-block",
		[theme.breakpoints.up("md")]: {
			marginLeft: "1rem"
		}
	},
	searchButton: {
		[theme.breakpoints.up("md")]: {
			marginRight: "1rem"
		}
	}
}));

export default function HeaderLinks(props) {
	const { dropdownHoverColor } = props;
	const classes = useStyles();
	const history = useHistory();
	const { user, userRoles, signOutUser } = useContext(AuthContext);
	const { hasAccess } = useProductAccess('all-courses');

	const handleCoursesClick = (e) => {
		e.preventDefault();
		// Check if on mobile
		const isMobile = window.innerWidth <= 768;
		
		if (!user) {
			history.push('/all-courses');
		} else {
			const db = getFirestore();
			getDoc(doc(db, 'users', user.uid)).then(userDoc => {
				const productsOwned = userDoc.data()?.productsOwned || [];
				if (productsOwned.includes('all-courses')) {
					if (isMobile) {
						// On mobile, go directly to course overview with the correct course ID
						history.push('/course/mxZeDmL5Ab0WmUCBAGAb/overview');
					} else {
						// On desktop, go to courses page
						history.push('/courses');
					}
				} else {
					history.push('/all-courses');
				}
			}).catch(error => {
				console.error('Error checking course access:', error);
				history.push('/all-courses');
			});
		}
	};

	return (
		<List className={classes.list + ' ' + classes.mlAuto}>
			<ListItem className={classes.listItem}>
				<Link
					to='/landing-page'
					key='/landing-page'
					className={classes.navLink}
				>
					Home
				</Link>
			</ListItem>

			<ListItem className={classes.listItem}>
				<Link
					to='/coaching'
					key='/coaching'
					className={classes.navLink}
				>
					Coaching
				</Link>
			</ListItem>

			<ListItem className={classes.listItem}>
				<CustomDropdown
					noLiPadding
					navDropdown
					hoverColor={dropdownHoverColor}
					buttonText='Products'
					buttonProps={{
						className: classes.navLink,
						color: 'transparent',
					}}
					buttonIcon={ShoppingCart}
					dropdownList={[
						<Link
							to='/what-she-really-means'
							key='/what-she-really-means'
							className={classes.dropdownLink}
						>
							<ShoppingCart className={classes.dropdownIcons} /> What She Really Means
						</Link>,
						<Link
							to='/meet-girls-everywhere'
							key='/meet-girls-everywhere'
							className={classes.dropdownLink}
						>
							<ShoppingCart className={classes.dropdownIcons} /> Meet Girls Everywhere
						</Link>,
					]}
				/>
			</ListItem>

			<ListItem className={classes.listItem}>
				<Link
					to='/support'
					key='/support'
					className={classes.navLink}
				>
					Support
				</Link>
			</ListItem>

			{userRoles?.isAdmin && (
				<ListItem className={classes.listItem}>
					<Link
						to='/admin'
						key='/admin'
						className={classes.navLink}
					>
						Admin
					</Link>
				</ListItem>
			)}

			<ListItem className={classes.listItem}>
				<div className={classes.userButtons}>
					{user?.uid ? (
						<>
							<Button
								color={'white'}
								target='_blank'
								className={classes.navButton}
								round
								onClick={() => signOutUser()}
							>
								logout
							</Button>
							<Link to='/profile-page' className={classes.fullWidthOnMobile}>
								<Button color={'white'} className={classes.navButton} round>
									profile
								</Button>
							</Link>
						</>
					) : (
						<>
							<Link to='/login-page'>
								<Button color={'white'} className={classes.navButton} round>
									login
								</Button>
							</Link>
							<Link to='/signup-page'>
								<Button color={'white'} className={classes.navButton} round>
									Signup
								</Button>
							</Link>
						</>
					)}
					<Button
						onClick={handleCoursesClick}
						className={classes.coursesButton}
					>
						<School className={classes.coursesIcon} /> COURSES
					</Button>
				</div>
			</ListItem>
		</List>
	);
}

HeaderLinks.defaultProps = {
	hoverColor: 'primary',
};

HeaderLinks.propTypes = {
	dropdownHoverColor: PropTypes.oneOf([
		'dark',
		'primary',
		'info',
		'success',
		'warning',
		'danger',
		'rose',
	]),
};
