import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDRqrFqY1o9N-v63C6yW42NQmoRgcyRxXM",
  authDomain: "project-hector-6eaf1.firebaseapp.com",
  projectId: "project-hector-6eaf1",
  storageBucket: "project-hector-6eaf1.appspot.com",
  messagingSenderId: "647720117738",
  appId: "1:647720117738:web:0f3feba82c3e8dba687e93",
  measurementId: "G-1WEND8S3QK",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
// const analytics = getAnalytics(app);

// Uncomment these lines to use emulators for local development
// if (window.location.hostname === "localhost") {
//   connectAuthEmulator(auth, "http://localhost:9099");
//   connectFirestoreEmulator(db, "localhost", 8080);
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export { app, auth, db, functions };
