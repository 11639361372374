import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import NavigationButton from "./NavigationButton";
import DiscussionBoard from "components/DiscussionBoard/DiscussionBoard";

const useStyles = makeStyles({
  moduleWrapper: {
    alignItems: 'center',
    borderRadius: '25px',
    background: 'linear-gradient(180deg, #fff 0%, #dadada 100%)',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(239, 239, 239, 0.25) inset',
    display: 'flex',
    minWidth: '240px',
    flexDirection: 'column',
    color: '#000',
    fontWeight: 400,
    justifyContent: 'start',
    flexGrow: 1,
    width: '1013px',
    padding: '46px 11px',
    fontFamily: 'Roboto Slab, sans-serif',
    '& *': {
      fontFamily: 'Roboto Slab, sans-serif',
    },
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      width: '100%',
      padding: '40px 15px'
    },
    '@media (max-width: 768px)': {
      padding: '30px 12px',
      borderRadius: '15px'
    }
  },
  moduleHeader: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '20px',
    '@media (max-width: 768px)': {
      marginBottom: '15px'
    }
  },
  chapterDescription: {
    textAlign: 'center',
    marginBottom: '30px',
    padding: '0 20px',
    fontSize: '1.1rem',
    lineHeight: '1.6',
    color: '#333',
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      marginBottom: '20px',
      padding: '0 10px'
    }
  },
  moduleInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0'
  },
  moduleNumber: {
    fontWeight: 'bold',
    textAlign: 'center'
  },
  moduleTime: {
    color: '#666',
    textAlign: 'center'
  },
  contentSection: {
    width: '100%',
    padding: '0 20px'
  },
  moduleTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    textAlign: 'center',
    '& h2': {
      margin: 0,
      textAlign: 'center',
      fontSize: '2rem',
      fontWeight: 500,
      '@media (max-width: 768px)': {
        fontSize: '1.5rem'
      }
    },
    '@media (max-width: 768px)': {
      marginBottom: '15px'
    }
  },
  moduleImage: {
    maxWidth: '100%',
    borderRadius: '15px',
    marginTop: '20px',
    display: props => props.hasVideo ? 'none' : 'block'
  },
  moduleVideo: {
    width: '100%',
    borderRadius: '15px',
    marginTop: '20px',
    aspectRatio: '16/9',
    '& iframe': {
      width: '100%',
      height: '100%',
      borderRadius: '15px'
    },
    '@media (max-width: 767px)': {
      aspectRatio: '4/3',
      minHeight: '240px'
    }
  },
  contentText: {
    lineHeight: 1.6,
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: '10px',
      margin: '1rem 0'
    },
    '@media (max-width: 768px)': {
      fontSize: '0.95rem'
    }
  },
  moduleNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '30px',
    padding: '0 20px'
  },
  exercisesSection: {
    width: '100%',
    padding: '20px',
    marginTop: '30px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '15px'
  },
  exerciseItem: {
    marginBottom: '30px',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  },
  exerciseQuestion: {
    fontSize: '1.2rem',
    marginBottom: '15px',
    '@media (max-width: 768px)': {
      fontSize: '1.1rem',
      marginBottom: '10px'
    }
  },
  exerciseOptions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  exerciseResult: {
    marginTop: '15px',
    padding: '10px',
    borderRadius: '5px',
    fontWeight: 500,
    textAlign: 'center',
    '&.correct': {
      backgroundColor: 'rgba(76, 175, 80, 0.1)',
      color: '#2e7d32'
    },
    '&.incorrect': {
      backgroundColor: 'rgba(244, 67, 54, 0.1)',
      color: '#d32f2f'
    }
  },
  discussionSection: {
    width: '100%',
    marginTop: '40px'
  },
  completionDialog: {
    // Add appropriate styles for the completion dialog
  },
  navigationButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '30px',
    padding: '0 20px',
    '@media (max-width: 768px)': {
      padding: '0 10px',
      marginTop: '20px'
    }
  },
  exerciseSection: {
    width: '100%',
    marginTop: '30px',
    padding: '0 20px',
    '@media (max-width: 768px)': {
      padding: '0 10px',
      marginTop: '20px'
    }
  },
  exerciseTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    marginBottom: '15px',
    '@media (max-width: 768px)': {
      fontSize: '1.3rem',
      marginBottom: '10px'
    }
  },
  radioGroup: {
    marginBottom: '20px',
    '@media (max-width: 768px)': {
      marginBottom: '15px'
    }
  },
  formControl: {
    width: '100%',
    '@media (max-width: 768px)': {
      '& .MuiFormControlLabel-root': {
        marginBottom: '5px'
      }
    }
  },
  submitButton: {
    marginTop: '15px',
    '@media (max-width: 768px)': {
      marginTop: '10px',
      fontSize: '0.9rem'
    }
  },
  feedbackMessage: {
    marginTop: '15px',
    padding: '10px',
    borderRadius: '5px',
    '@media (max-width: 768px)': {
      marginTop: '10px',
      padding: '8px',
      fontSize: '0.9rem'
    }
  }
});

export function ModuleContent({ chapter, module, onNavigate, courseId }) {
  const classes = useStyles();
  const [exerciseAnswers, setExerciseAnswers] = useState({});
  const [exerciseResults, setExerciseResults] = useState({});
  
  const handleNavigation = (direction) => {
    onNavigate(direction);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleAnswerChange = (exerciseIndex, value) => {
    setExerciseAnswers(prev => ({
      ...prev,
      [exerciseIndex]: parseInt(value)
    }));
  };

  const checkAnswer = (exerciseIndex, exercise) => {
    const isCorrect = exerciseAnswers[exerciseIndex] === exercise.correctAnswer;
    setExerciseResults(prev => ({
      ...prev,
      [exerciseIndex]: isCorrect
    }));
  };

  const renderFormattedText = (text) => {
    if (!text) return null;

    const formatText = (text) => {
      // Replace bold text
      text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      // Replace italic text
      text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
      return text;
    };

    // Split text into paragraphs and process bullet points
    return text.split('\n').map((paragraph, index) => {
      if (paragraph.trim().startsWith('* ')) {
        // Handle bullet points
        return (
          <li key={index} style={{ marginLeft: '20px', marginBottom: '8px' }}>
            <div dangerouslySetInnerHTML={{ __html: formatText(paragraph.substring(2)) }} />
          </li>
        );
      } else if (paragraph.trim()) {
        // Handle normal paragraphs
        return (
          <p key={index} style={{ marginBottom: '16px' }}>
            <div dangerouslySetInnerHTML={{ __html: formatText(paragraph) }} />
          </p>
        );
      }
      return null;
    });
  };

  const renderContent = (content) => {
    if (!content) return null;
    
    // Replace [img]URL[/img] with actual img tags
    const parts = content.split(/(\[img\].*?\[\/img\])/g);
    return parts.map((part, index) => {
      const imgMatch = part.match(/\[img\](.*?)\[\/img\]/);
      if (imgMatch) {
        return <img key={index} src={imgMatch[1]} alt="Content illustration" style={{ borderRadius: '15px' }} />;
      }
      return <p key={index}>{part}</p>;
    });
  };
  
  return (
    <article className={classes.moduleWrapper}>
      <div className={classes.moduleHeader}>
        <Typography 
          variant="h1" 
          style={{ 
            textAlign: 'center',
            fontFamily: 'Roboto Slab, sans-serif',
            fontSize: '3rem',
            fontWeight: 600,
            marginBottom: '1rem'
          }}
        >
          {chapter?.title}
        </Typography>
        {chapter?.description && (
          <Typography className={classes.chapterDescription}>
            <div style={{ 
              textAlign: 'left',
              '& p': { marginBottom: '16px' },
              '& p:last-child': { marginBottom: 0 },
              '& strong': { fontWeight: 600 },
              '& em': { fontStyle: 'italic' }
            }}>
              {renderFormattedText(chapter.description)}
            </div>
          </Typography>
        )}
      </div>

      <div className={classes.contentSection}>
        <div className={classes.moduleTitle}>
          {module?.title && (
            <Typography variant="h2">
              {module?.number ? `Chapter ${module.number}:` : ''}<br />
              {module.title}
            </Typography>
          )}
          {module?.videoUrl && (
            module.videoUrl.includes('youtube.com') || module.videoUrl.includes('youtu.be') ? (
              <div className={classes.moduleVideo}>
                <iframe
                  src={module.videoUrl.replace('watch?v=', 'embed/')}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : (
              <video 
                className={classes.moduleVideo}
                controls
                src={module.videoUrl}
                poster={module?.imageUrl}
              />
            )
          )}
          {!module?.videoUrl && module?.imageUrl && (
            <img
              className={classes.moduleImage}
              loading="lazy"
              src={module.imageUrl}
              alt="Module illustration"
            />
          )}
        </div>

        <div className={classes.contentText}>
          {renderContent(module?.content)}
        </div>

        {module?.exercises && module.exercises.length > 0 && module.exercises.some(ex => ex.question && ex.options?.some(opt => opt.trim() !== '')) && (
          <div className={classes.exercisesSection}>
            <Typography variant="h6" gutterBottom>
              Practice Exercises
            </Typography>
            {module.exercises.filter(ex => ex.question && ex.options?.some(opt => opt.trim() !== '')).map((exercise, index) => (
              <div key={index} className={classes.exerciseItem}>
                <Typography className={classes.exerciseQuestion}>
                  {exercise.question}
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={exerciseAnswers[index]?.toString() || ''}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                  >
                    {exercise.options.filter(opt => opt.trim() !== '').map((option, optionIndex) => (
                      <FormControlLabel
                        key={optionIndex}
                        value={optionIndex.toString()}
                        control={<Radio />}
                        label={option}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => checkAnswer(index, exercise)}
                  disabled={exerciseAnswers[index] === undefined}
                  style={{ marginTop: '10px' }}
                >
                  Check Answer
                </Button>
                {exerciseResults[index] !== undefined && (
                  <div className={`${classes.exerciseResult} ${exerciseResults[index] ? 'correct' : 'incorrect'}`}>
                    {exerciseResults[index] ? 'Correct!' : 'Try again!'}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={classes.navigationButtons}>
        <NavigationButton 
          direction="previous" 
          text="Previous Module" 
          onClick={() => handleNavigation('prev')}
        />
        <NavigationButton 
          direction="next" 
          text={module?.isLastInChapter ? "Next Chapter" : "Next Module"}
          onClick={() => handleNavigation('next')}
        />
      </div>
    </article>
  );
} 