import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { getFirestore, doc, getDoc, setDoc, collection } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import logo from 'assets/img/logo.png';
import classroom from "assets/img/hectorClassroom.png";
import lockIcon from "assets/img/lock.png";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import { GetApp, PictureAsPdf, Book, Description } from '@material-ui/icons';

const useStyles = makeStyles({
  courseContainer: {
    backgroundColor: 'rgba(175, 175, 175, 1)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    font: '700 14px Roboto Slab, sans-serif'
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: '832px',
    width: '100%',
    padding: '120px 2px 25px',
    '@media (max-width: 991px)': {
      maxWidth: '100%'
    }
  },
  backgroundImage: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  overlay: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1
  },
  pageTitle: {
    color: '#fff',
    fontSize: 'clamp(2.5rem, 4vw, 4rem)',
    fontWeight: 700,
    lineHeight: 1.2,
    textAlign: 'center',
    marginBottom: '2.5rem',
    position: 'relative',
    zIndex: 2,
    fontFamily: 'Roboto Slab, sans-serif',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '@media (max-width: 991px)': {
      fontSize: '2.5rem',
      marginBottom: '1.5rem'
    }
  },
  contentGrid: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    marginTop: '23px',
    minHeight: '636px',
    alignItems: 'start',
    gap: '20px',
    overflow: 'hidden',
    textAlign: 'center',
    letterSpacing: '1.4px',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '10px 50px 52px',
    maxWidth: '1200px',
    margin: '0 auto',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      padding: '0 20px',
      gap: '15px'
    },
    '@media (max-width: 768px)': {
      padding: '0 10px',
      gap: '10px'
    }
  },
  backToLessons: {
    position: 'fixed',
    bottom: '2.5rem',
    left: '2.5rem',
    zIndex: 3,
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.625rem',
    cursor: 'pointer',
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    fontSize: '1rem',
    letterSpacing: '0.1em',
    border: 'none',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 0.9
    },
    '@media (max-width: 768px)': {
      display: 'none'
    }
  },
  cardWrapper: {
    display: 'flex',
    maxWidth: '100%',
    width: '48%',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'rgba(255, 255, 255, 1)',
    justifyContent: 'start',
    cursor: 'pointer',
    position: 'relative',
    minHeight: '400px',
    marginBottom: '30px',
    '@media (max-width: 991px)': {
      width: '47%',
      minHeight: '350px',
      marginBottom: '20px'
    },
    '@media (max-width: 768px)': {
      width: '100%',
      minHeight: '300px',
      marginBottom: '15px'
    }
  },
  lockOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px',
    zIndex: 2,
  },
  lockIcon: {
    width: '50px',
    height: '50px',
    opacity: 0.9,
  },
  chapterImage: {
    aspectRatio: '16/9',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
    borderRadius: '15px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.05)'
    }
  },
  chapterTitle: {
    marginTop: '1rem',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: '1rem',
    lineHeight: 1.4,
    padding: '0 0.625rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.3125rem',
    fontFamily: 'Roboto Slab',
    marginBottom: '1rem',
    height: '120px',
    overflow: 'hidden',
    '@media (max-width: 768px)': {
      height: 'auto',
      marginBottom: '0.5rem'
    }
  },
  chapterNumber: {
    fontWeight: 700,
    fontSize: '1.75rem',
    color: '#fff',
    fontFamily: 'Roboto Slab, sans-serif',
    marginBottom: '0.25rem'
  },
  chapterName: {
    fontSize: '1.5rem',
    color: '#fff',
    fontWeight: 400,
    fontFamily: 'Roboto, sans-serif',
    marginBottom: '0.5rem',
    lineHeight: 1.4,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical'
  },
  chapterDescription: {
    fontSize: '0.875rem',
    color: '#fff',
    fontWeight: 400,
    lineHeight: 1.6,
    opacity: 0.9,
    textAlign: 'left',
    height: '4.8rem',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    fontFamily: 'Roboto, sans-serif',
    padding: '0 0.625rem'
  },
  warningDialog: {
    '& .MuiDialog-paper': {
      borderRadius: '15px',
      padding: '20px',
      maxWidth: '500px',
    },
  },
  warningTitle: {
    color: '#f00',
    fontFamily: 'Roboto Slab, sans-serif',
    textAlign: 'center',
  },
  warningContent: {
    fontFamily: 'Roboto Slab, sans-serif',
    padding: '20px',
    textAlign: 'center',
  },
  warningActions: {
    padding: '20px',
    justifyContent: 'center',
    gap: '10px',
  },
  actionButton: {
    borderRadius: '25px',
    padding: '10px 30px',
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    '&.proceed': {
      background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
      color: 'white',
    },
  },
  downloadableSection: {
    width: '100%',
    marginTop: '2rem',
    padding: '2rem',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '15px',
    position: 'relative',
    zIndex: 2,
    '@media (max-width: 768px)': {
      padding: '1rem',
      marginTop: '1rem'
    }
  },
  downloadableTitle: {
    color: '#fff',
    fontSize: '2rem',
    fontWeight: 700,
    marginBottom: '1.5rem',
    textAlign: 'center',
    fontFamily: 'Roboto Slab, sans-serif',
    '@media (max-width: 768px)': {
      fontSize: '1.5rem',
      marginBottom: '1rem'
    }
  },
  downloadableGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1.5rem',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
      gap: '1rem'
    }
  },
  downloadableItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '1.5rem',
    borderRadius: '10px',
    width: 'calc(33.33% - 1rem)',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
      backgroundColor: 'rgba(255, 255, 255, 0.2)'
    },
    '@media (max-width: 991px)': {
      width: 'calc(50% - 0.75rem)'
    },
    '@media (max-width: 768px)': {
      width: '100%',
      padding: '1rem'
    }
  },
  downloadableCardTitle: {
    color: '#fff',
    fontSize: '1.25rem',
    fontWeight: 700,
    fontFamily: 'Roboto Slab, sans-serif',
  },
  downloadableCardDescription: {
    color: '#fff',
    fontSize: '0.875rem',
    opacity: 0.9,
    fontFamily: 'Roboto, sans-serif',
  },
  downloadButton: {
    background: 'linear-gradient(180deg, #f00 0%, #900 100%)',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '25px',
    fontFamily: 'Roboto Slab, sans-serif',
    fontWeight: 700,
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    transition: 'opacity 0.2s',
    textDecoration: 'none',
    '&:hover': {
      opacity: 0.9,
      color: 'white',
      textDecoration: 'none'
    }
  },
  fileIcon: {
    marginRight: '8px'
  },
});

const ChapterCard = ({ image, title, description, index, onClick, isLocked, isCurrentChapter, chapter }) => {
  const classes = useStyles();
  return (
    <div className={classes.cardWrapper} onClick={onClick}>
      <div className={classes.chapterTitle}>
        <div className={classes.chapterNumber}>{chapter?.chapterHeader || `Chapter ${index + 1}`}</div>
        <div className={classes.chapterName}>{title}</div>
      </div>
      {isLocked && !isCurrentChapter && (
        <div className={classes.lockOverlay}>
          <img src={lockIcon} alt="Locked" className={classes.lockIcon} />
        </div>
      )}
      <img
        className={classes.chapterImage}
        loading="lazy"
        src={image}
        alt={title}
        style={isLocked && !isCurrentChapter ? { filter: 'grayscale(50%)' } : {}}
      />
    </div>
  );
};

export default function CourseOverview() {
  const classes = useStyles();
  const [course, setCourse] = useState(null);
  const [userProgress, setUserProgress] = useState(null);
  const [warningDialog, setWarningDialog] = useState({ open: false, chapterIndex: null });
  const history = useHistory();
  const { courseId } = useParams();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchCourseAndProgress = async () => {
      if (!user) return;

      try {
        const db = getFirestore();
        // Fetch course
        const courseRef = doc(db, 'courses', courseId);
        const courseSnap = await getDoc(courseRef);
        
        if (courseSnap.exists()) {
          setCourse({ id: courseSnap.id, ...courseSnap.data() });
        }

        // Fetch user progress
        const progressRef = doc(db, 'users', user.uid, 'courseProgress', courseId);
        const progressSnap = await getDoc(progressRef);
        
        if (progressSnap.exists()) {
          setUserProgress(progressSnap.data());
        } else {
          // Initialize progress if it doesn't exist
          const initialProgress = {
            currentChapter: 0,
            completedChapters: [],
            lastAccessed: new Date(),
          };
          await setDoc(progressRef, initialProgress);
          setUserProgress(initialProgress);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCourseAndProgress();
  }, [courseId, user]);

  const handleChapterClick = async (index) => {
    if (!user || !userProgress) return;

    const isLocked = index > userProgress.currentChapter && 
                    !userProgress.completedChapters.includes(index - 1);

    if (isLocked) {
      setWarningDialog({ open: true, chapterIndex: index });
    } else {
      history.push(`/course/${courseId}/lesson?chapter=${index}`);
    }
  };

  const handleProceedAnyway = async () => {
    const db = getFirestore();
    const progressRef = doc(db, 'users', user.uid, 'courseProgress', courseId);
    
    // Update progress to unlock the chapter
    await setDoc(progressRef, {
      ...userProgress,
      currentChapter: Math.max(userProgress.currentChapter, warningDialog.chapterIndex),
      lastAccessed: new Date(),
    }, { merge: true });

    // Navigate to the chapter
    history.push(`/course/${courseId}/lesson?chapter=${warningDialog.chapterIndex}`);
    setWarningDialog({ open: false, chapterIndex: null });
  };

  return (
    <div className={classes.courseContainer}>
      <div className={classes.mainWrapper}>
        <img
          className={classes.backgroundImage}
          loading="lazy"
          src={classroom}
          alt="Background"
        />
        <div className={classes.overlay} />
        <Header
          color="transparent"
          brand={<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem' }} />}
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          changeColorOnScroll={{
            height: 300,
            color: 'white',
          }}
        />
        <h1 className={classes.pageTitle}>{course?.title || 'Loading...'}</h1>
        <div className={classes.contentGrid}>
          {course?.chapters?.map((chapter, index) => (
            <ChapterCard
              key={chapter.id || index}
              image={chapter.imageUrl || classroom}
              title={chapter.title || `Untitled Chapter`}
              description={chapter.description}
              index={index}
              chapter={chapter}
              isLocked={userProgress && index > userProgress.currentChapter && 
                       !userProgress.completedChapters.includes(index - 1)}
              isCurrentChapter={userProgress && index === userProgress.currentChapter}
              onClick={() => handleChapterClick(index)}
            />
          ))}
        </div>

        {course?.downloadableContent?.length > 0 && (
          <div className={classes.downloadableSection}>
            <h2 className={classes.downloadableTitle}>Downloadable Content</h2>
            <div className={classes.downloadableGrid}>
              {course.downloadableContent.map((content, index) => (
                <div key={index} className={classes.downloadableItem}>
                  <h3 className={classes.downloadableCardTitle}>{content.title}</h3>
                  <p className={classes.downloadableCardDescription}>{content.description}</p>
                  <a 
                    href={content.fileUrl} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className={classes.downloadButton}
                    download={content.fileName || true}
                  >
                    {(() => {
                      const fileType = content.fileName ? content.fileName.split('.').pop().toLowerCase() : '';
                      if (['pdf'].includes(fileType)) {
                        return <PictureAsPdf className={classes.fileIcon} />;
                      } else if (['epub', 'mobi'].includes(fileType)) {
                        return <Book className={classes.fileIcon} />;
                      } else if (['doc', 'docx', 'txt'].includes(fileType)) {
                        return <Description className={classes.fileIcon} />;
                      }
                      return <GetApp className={classes.fileIcon} />;
                    })()}
                    Download or Read/View {content.fileName || 'File'}
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}

        <button 
          className={classes.backToLessons} 
          onClick={() => history.push('/courses')}
        >
          Back to Lessons
        </button>

        <Dialog 
          open={warningDialog.open} 
          onClose={() => setWarningDialog({ open: false, chapterIndex: null })}
          className={classes.warningDialog}
        >
          <DialogTitle>
            <Typography variant="h5" className={classes.warningTitle}>
              Chapter Out of Order
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.warningContent}>
            <Typography>
              We recommend completing the previous chapters first for the best learning experience. 
              Would you like to proceed anyway?
            </Typography>
          </DialogContent>
          <DialogActions className={classes.warningActions}>
            <Button 
              onClick={() => setWarningDialog({ open: false, chapterIndex: null })}
              className={classes.actionButton}
            >
              Go Back
            </Button>
            <Button 
              onClick={handleProceedAnyway}
              className={`${classes.actionButton} proceed`}
            >
              Proceed Anyway
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
} 